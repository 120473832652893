import React from 'react';
import { PanelHeader } from 'js/components';
import { Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Icon, HeroBadge, IconStack } from 'js/components';
import { faMessageSmile } from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

function AssessmentComplete({ backNavigation }) {
  return (
    <div>
      <PanelHeader size="flex"></PanelHeader>
      <div className="content">
        <div className="assessment survey-assessment-review">
          {backNavigation && (
            <div className="survey-assessment-back-nav">{backNavigation}</div>
          )}
          <Card>
            <CardBody>
              <div className="all-answered mb-5">
                <HeroBadge
                  renderIcon={() => (
                    <IconStack className="complete-icon">
                      <Icon icon={faCircle} />
                      <Icon
                        icon={faMessageSmile}
                        inverse
                        transform="shrink-6"
                      />
                    </IconStack>
                  )}
                  title="Thanks!"
                >
                  <p>
                    Thanks for taking the time to share your thoughts. You can
                    close this window at any time.
                  </p>
                  <div className="action-items">
                    <Link to="./review" data-test="finish-link">
                      Just kidding, I need to make some changes&hellip;
                    </Link>
                  </div>
                </HeroBadge>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default AssessmentComplete;
