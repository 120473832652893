import React from 'react';

import { Icon } from 'js/components';

import SourceCard from './SourceCard';

import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons';

import styles from './AssessUserGroup.module.scss';

function RecentCard() {
  return (
    <SourceCard title="Recent assessment name">
      <span className={styles['recent-content']}>
        <span className={styles['recent-capabilities']}>
          Capability 1, Capability 2, Capability 3, + 15 more...
        </span>
        <span className={styles['recent-last-run']}>
          <label>Last run on</label>
          <Icon
            icon={faCalendarAlt}
            size="lg"
            className="text-info icon-left"
          />
          Jan 15, 2025
        </span>
      </span>
    </SourceCard>
  );
}

export default RecentCard;
