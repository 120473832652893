import React, { useMemo } from 'react';
import { RunComponent } from '../components/Run';
import { PreviewResponseContextProvider } from '../PreviewResponseContext';

function CapabilitiesAssessmentPreviewEngine({ assessment, backNavigation }) {
  const participant = useMemo(
    () => ({
      id: -999,
      completed_welcome: true,
    }),
    []
  );

  return (
    <PreviewResponseContextProvider
      assessment={assessment}
      participant={participant}
    >
      <RunComponent assessment={assessment} backNavigation={backNavigation} />
    </PreviewResponseContextProvider>
  );
}

export default CapabilitiesAssessmentPreviewEngine;
