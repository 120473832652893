import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CapabilityTree from './CapabilityTree';
import CapabilityPicker from './CapabilityPicker';
import { SelectorContext } from './SelectorContext';
import { useEditorContext } from 'js/ydoc/docs/capabilities-assessment/EditorContext';
import { Button } from 'reactstrap';
import SelectedCapabilities from './SelectedCapabilities';
import styles from './CapabilitySelector.module.scss';

export function CapabilitySelector({ page, isOpen, onClose }) {
  return isOpen ? (
    <CapabilitySelectorInternals page={page} onClose={onClose} />
  ) : null;
}

export function CapabilitySelectorInternals({ page, onClose = () => {} }) {
  const [activeItem, setActiveItem] = useState(null);
  // const [capabilitySet, setCapabilitySet] = useState(null);
  const {
    doc,
    assessment,
    capabilitySets,
    updatePageFields,
    updateCacheForCapabilitySet,
    cachedCapabilitySets,
  } = useEditorContext();

  // useEffect(() => {
  //   setCapabilitySet(page.capabilitySet);
  // }, [page]);

  const handleSetCapabilitySet = useCallback(
    (cs) => {
      doc.transact(() => {
        // TODO: Refresh the cache for the capability set if necessary
        const sourceCapabilitySet = capabilitySets.find(
          (sourceSet) => sourceSet.id === cs.id
        );

        if (sourceCapabilitySet) {
          updateCacheForCapabilitySet(sourceCapabilitySet);
        }

        updatePageFields(page.guid, {
          capabilitySetId: cs.id,
        });
      });
    },
    [capabilitySets, doc, page, updateCacheForCapabilitySet, updatePageFields]
  );

  const handleNavigateToItem = useCallback((item) => {
    setActiveItem(item);
  }, []);

  const capabilitySet = useMemo(() => {
    return cachedCapabilitySets?.[page.capabilitySetId];
  }, [cachedCapabilitySets, page.capabilitySetId]);

  useEffect(() => {
    if (
      (activeItem?.capabilitySet?.id ??
        activeItem?.capability?.capability_set_id) !== capabilitySet?.id &&
      capabilitySet
    ) {
      setActiveItem({ capabilitySet: capabilitySet, path: [] });
    }
  }, [
    capabilitySet,
    activeItem?.capabilitySet?.id,
    activeItem?.capability?.capability_set_id,
  ]);

  // console.log('🚧 capabilitySet', capabilitySet, cachedCapabilitySets, page);
  // console.log('🚧 cachedCapabilitySets', cachedCapabilitySets);

  const selectedCapabilities = useMemo(() => {
    return Object.entries(page.selectedCapabilities).reduce((acc, [id]) => {
      if (
        true ||
        assessment.capabilityCache[id]?.capability_set_id ===
          page.capabilitySetId
      ) {
        acc[id] = assessment.capabilityCache[id];
      }
      return acc;
    }, {});
  }, [
    page.selectedCapabilities,
    page.capabilitySetId,
    assessment.capabilityCache,
  ]);

  const selectorContext = useMemo(() => {
    return {
      page,
      navigateToItem: handleNavigateToItem,
      activeItem,
      selectedCapabilities,
      setCapabilitySet: handleSetCapabilitySet,
      capabilitySet,
    };
  }, [
    handleNavigateToItem,
    activeItem,
    selectedCapabilities,
    capabilitySet,
    handleSetCapabilitySet,
    page,
  ]);

  return (
    <Modal
      size="xl"
      toggle={() => {}}
      className={styles.selector}
      centered
      isOpen
    >
      <ModalHeader>Choose your capabililities</ModalHeader>
      <SelectorContext.Provider value={selectorContext}>
        <ModalBody>
          <div className={styles['main-panels']}>
            <section className={styles.tree}>
              <CapabilityTree />
            </section>
            <section className={styles.details}>
              <CapabilityPicker page={page} />
            </section>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className={styles['selected-capabilities']}>
            <SelectedCapabilities />
          </div>
          <div className={styles['selector-controls']}>
            <Button color="primary" onClick={onClose}>
              OK
            </Button>
          </div>
        </ModalFooter>
      </SelectorContext.Provider>
    </Modal>
  );
}

export default CapabilitySelector;
