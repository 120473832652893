import React, { useRef, useState, useMemo, useEffect } from 'react';

import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import {
  attachClosestEdge,
  extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';

import { faGripVertical } from '@fortawesome/pro-solid-svg-icons';
import { faX } from '@fortawesome/pro-regular-svg-icons';

import { Icon } from 'js/components';
import DropIndicator from './DropIndicator';

import styles from './AnswerSet.module.scss';

function DraggableChoice({
  className,
  choice,
  onDelete = () => {},
  index,
  answerSetId,
  children,
}) {
  const ref = useRef(null);
  const dragHandleRef = useRef(null);
  const [dragging, setDragging] = useState(false);
  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const [closestEdge, setClosestEdge] = useState(null);

  useEffect(() => {
    const el = ref.current;
    const dragHandle = dragHandleRef.current;
    if (!el) {
      throw new Error('No element found');
    }
    if (!dragHandle) {
      throw new Error('No drag handle found');
    }

    return combine(
      draggable({
        element: el,
        dragHandle,
        getInitialData: () => ({ choice, index, answerSetId }),
        onDragStart: () => setDragging(true), // NEW
        onDrop: () => setDragging(false), // NEW
      }),
      dropTargetForElements({
        element: el,
        canDrop: ({ source }) =>
          source.data.choice &&
          (source.data.choice.id ?? source.data.choice.guid) !==
            (choice.id ?? choice.guid) &&
          source.data.answerSetId === answerSetId,
        getData: (args) => {
          return attachClosestEdge(
            { choice, index },
            {
              input: args.input,
              element: args.element,
              allowedEdges: ['top', 'bottom'],
            }
          );
        },
        onDragEnter: () => setIsDraggedOver(true),
        onDragLeave: () => {
          setClosestEdge(null);
          setIsDraggedOver(false);
        },
        onDrag({ self, source }) {
          const isSource = source.element === el;
          if (isSource) {
            setClosestEdge(null);
            return;
          }

          const closestEdge = extractClosestEdge(self.data);

          setClosestEdge(closestEdge);
        },
        onDrop: () => {
          setIsDraggedOver(false);
          setClosestEdge(null);
        },
        getIsSticky: (args) => {
          const elementUnderCursor = document.elementFromPoint(
            args.input.pageX,
            args.input.pageY
          );
          return (
            elementUnderCursor &&
            elementUnderCursor.closest('.answer-set-droppable-area')
          );
        },
      })
    );
  }, [choice, index, answerSetId]);

  const choiceClassNames = useMemo(() => {
    const choiceClasses = [className, styles.choice];
    if (dragging) {
      choiceClasses.push(styles.dragging);
    }
    if (isDraggedOver) {
      choiceClasses.push(styles[`dragged-over-${closestEdge}`]);
    }
    return choiceClasses.filter((x) => x != null).join(' ');
  }, [className, dragging, isDraggedOver, closestEdge]);

  return (
    <tr key={choice.id} className={choiceClassNames} ref={ref}>
      <td className={styles['drag-column']}>
        {isDraggedOver && closestEdge === 'top' && <DropIndicator top />}
        {isDraggedOver && closestEdge === 'bottom' && <DropIndicator bottom />}
        <button className="drag-handle" ref={dragHandleRef}>
          <Icon icon={faGripVertical} className="icon" />
        </button>
      </td>
      {children}
      <td className={styles['answer-actions']}>
        <button
          className="btn-inline-action btn-danger"
          onClick={() => onDelete(choice.id ?? choice.guid)}
        >
          <Icon icon={faX} size="sm" />
        </button>
      </td>
    </tr>
  );
}

export default DraggableChoice;
