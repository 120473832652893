import React from 'react';
import { Button } from 'reactstrap';
import { Icon } from 'js/components';
import Reinitialize from './Reinitialize';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import EditorTool from 'js/components/Assessment/Editors/common/Tools/EditorTool';
import { Link } from 'react-router-dom';
import { useEditorContext } from 'js/ydoc/docs/capabilities-assessment/EditorContext';
import EditorErrors from 'js/components/Assessment/Editors/common/components/EditorErrors';
function Actions() {
  const { errors } = useEditorContext();

  return (
    <EditorTool title="Overview">
      <div style={{ position: 'absolute', top: '2px', right: '10px' }}>
        <EditorErrors errors={errors} />
      </div>
      <Link to={`./customize/launch`} className="btn btn-primary">
        Configure launch
        <Icon icon={faChevronRight} className="icon-right" />
      </Link>
      <Link to={`./preview`}>
        <Button color="link" className="ml-4">
          Preview
        </Button>
      </Link>
      <div>
        <Reinitialize />
      </div>
    </EditorTool>
  );
}

export default Actions;
