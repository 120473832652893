import React, { useCallback, useMemo } from 'react';
import { Icon } from '..';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';

export function ExtraBreadcrumbs({ breadcrumbs }) {
  return (
    <span className="wp-breadcrumb">
      <span className="wp-breadcrumb-label">&hellip;</span>
      <span className="wp-breadcrumb-separator">
        <Icon size="sm" icon={faChevronRight} />
      </span>
    </span>
  );
}

export function ButtonBreadcrumb({
  label,
  to,
  onClick = () => {},
  showDivider = true,
}) {
  const handleClick = useCallback(() => {
    onClick(to);
  }, [onClick, to]);

  return (
    <span className="wp-breadcrumb">
      <span className="wp-breadcrumb-label">
        {to ? (
          <button className="btn-breadcrumb" onClick={handleClick}>
            {label}
          </button>
        ) : (
          label
        )}
      </span>
      <span className="wp-breadcrumb-separator">
        {showDivider && <Icon size="sm" icon={faChevronRight} />}
      </span>
    </span>
  );
}

export function ButtonBreadcrumbs({
  breadcrumbs,
  onClick,
  maximum = 3,
  showFinalDivider = true,
}) {
  const { renderCrumbs, extraCrumbs } = useMemo(() => {
    const reversedBreadcrumbs = [...breadcrumbs].reverse();

    if (breadcrumbs.length <= maximum) {
      return { renderCrumbs: reversedBreadcrumbs, extraCrumbs: [] };
    }

    const renderCrumbs = reversedBreadcrumbs.slice(0, maximum);
    const extraCrumbs = reversedBreadcrumbs.slice(maximum);

    return { renderCrumbs, extraCrumbs };
  }, [breadcrumbs, maximum]);

  return (
    <span className="wp-breadcrumbs-container">
      <span className="wp-breadcrumbs">
        {renderCrumbs.map((b, i) => (
          <ButtonBreadcrumb
            key={i}
            label={b.label}
            to={b.to}
            onClick={onClick}
            showDivider={showFinalDivider || i !== 0}
          />
        ))}
        {extraCrumbs.length > 0 && (
          <ExtraBreadcrumbs breadcrumbs={extraCrumbs} />
        )}
      </span>
    </span>
  );
}
