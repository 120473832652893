import React from 'react';

import { getCategoryPaletteColor } from 'js/utils/color';

import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';

import { Icon } from 'js/components';
import SimpleCapability from './SimpleCapability';

import styles from '../../CapabilitiesAssessmentEditor.module.scss';

function CapabilitySubset({ depth = 0, capability, selectedCapabilities }) {
  const { background } = getCategoryPaletteColor(depth);
  const { background: childBackground } = getCategoryPaletteColor(depth + 1);

  // console.log({ cguid: capability.guid, selectedCapabilities });

  const isSelected = capability.guid in selectedCapabilities;
  // const isSelected = false;

  const { deepChildren, shallowChildren } = capability.children.reduce(
    (acc, child) => {
      if (child.children.length > 0) {
        acc.deepChildren.push(child);
      } else {
        acc.shallowChildren.push(child);
      }
      return acc;
    },
    { deepChildren: [], shallowChildren: [] }
  );

  return (
    <div className={styles['capability-subset']}>
      <div
        className={styles['capability-subset-bar']}
        style={{ backgroundColor: background }}
      ></div>

      <div className={styles['capability-subset-name']}>
        <Icon
          icon={isSelected ? faCheckSquare : faSquare}
          color={background}
          className="icon-left"
        />
        {capability.name}
      </div>
      {deepChildren.length > 0 && (
        <div className={styles['deep-children']}>
          {deepChildren.map((child) => (
            <CapabilitySubset
              key={child.id}
              depth={depth + 1}
              capability={child}
              selectedCapabilities={selectedCapabilities}
            />
          ))}
        </div>
      )}
      {deepChildren.length > 0 && shallowChildren.length > 0 && (
        <label>Also selected under {capability.name}:</label>
      )}
      {shallowChildren.length > 0 && (
        <div className={styles['shallow-children']}>
          {shallowChildren.map((child) => (
            <SimpleCapability
              key={child.id}
              name={child.name}
              color={childBackground}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default CapabilitySubset;
