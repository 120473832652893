import React from 'react';
import { Card, CardBody } from 'reactstrap';
import CapabilityTree from './CapabilityTree';

function CapabilitySetHierarchy({
  capabilitySet,
  onAddCapability,
  onEditCapability,
  onReparentCapability,
}) {
  return (
    <Card>
      <CardBody>
        <CapabilityTree
          capabilitySet={capabilitySet}
          capabilities={capabilitySet.capabilities}
          onAddCapability={onAddCapability}
          onEditCapability={onEditCapability}
          onReparentCapability={onReparentCapability}
        />
      </CardBody>
    </Card>
  );
}

export default CapabilitySetHierarchy;
