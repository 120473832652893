import React from 'react';

import { Link } from 'react-router-dom';
import { HeroBadge, Icon, IconStack } from 'js/components';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';

const AllAnsweredMessage = () => (
  <div className="all-answered mb-5">
    <HeroBadge
      renderIcon={() => (
        <IconStack className="complete-icon">
          <Icon icon={faCircle} />
          <Icon icon={faCheck} inverse transform="shrink-6" />
        </IconStack>
      )}
      title="Looking good!"
    >
      <p>
        Review your responses below. If everything looks correct, you're all
        done. If you want to change anything, click on the question to edit your
        response.
      </p>
      <div className="action-items">
        <Link
          to="complete"
          className="btn btn-primary btn-hero"
          data-test="finish-link"
        >
          <Icon className="btn-icon-left" icon={['far', 'narwhal']} />
          I'm done
        </Link>
      </div>
    </HeroBadge>
  </div>
);

export default AllAnsweredMessage;
