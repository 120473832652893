import React from 'react';
import { format, formatDistanceToNowStrict } from 'date-fns';
import { useSelector } from 'react-redux';

import { Card, CardBody, Button, FormGroup, Input } from 'reactstrap';
import { Icon, IconStack } from 'js/components';
import { DocumentProvider } from 'js/ydoc/doc/component';
import { EditorProvider } from 'js/ydoc/docs/capabilities-assessment/EditorContext';

import editorStyles from 'js/components/Assessment/Editors/common/EditorCommon.module.scss';
import SummaryTool from 'js/components/Assessment/Editors/CapabilitiesAssessmentEditor/Tools/SummaryTool';
import { faRocketLaunch } from '@fortawesome/pro-solid-svg-icons';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { faBan } from '@fortawesome/pro-light-svg-icons';
import { Link, useParams } from 'react-router-dom';

function formatDate(date) {
  return date ? format(date, 'yyyy-MM-dd HH:mm') : '';
}

function DateDistance({ date }) {
  if (!date) {
    return null;
  }

  return formatDistanceToNowStrict(date, { addSuffix: true });
}

function LaunchConfiguration({ userGroupAssessment, onFieldChanges }) {
  const token = useSelector((state) => state.identity.token);

  if (!token) {
    return null;
  }

  return (
    <DocumentProvider
      channelProviderToken={token}
      channelProviderTopic={`crdt:user-group-assessment:${userGroupAssessment.crdt_doc_id}`}
    >
      <EditorProvider userGroupAssessment={userGroupAssessment}>
        <LaunchConfigurationInternals
          userGroupAssessment={userGroupAssessment}
          onFieldChanges={onFieldChanges}
        />
      </EditorProvider>
    </DocumentProvider>
  );
}

function LaunchConfigurationInternals({ userGroupAssessment, onFieldChanges }) {
  const { workspaceId, userGroupId, assessmentId } = useParams();

  const handlePublish = async () => {
    await onFieldChanges({
      status: 'published',
    });

    // onAssessmentCreate(assessment);
  };

  const handleUnpublish = () => {
    onFieldChanges({
      status: 'draft',
    });
  };

  return (
    <div className={editorStyles['editor-grid']}>
      <div>
        <Card>
          <CardBody>
            <section>
              <h5>First, some basics</h5>
              <FormGroup>
                <label>Go live at</label>
                <div className="d-flex align-items-center">
                  <div>
                    {!userGroupAssessment.went_live_at ? (
                      <Input
                        type="datetime-local"
                        style={{ maxWidth: '250px' }}
                        value={formatDate(userGroupAssessment.go_live_at)}
                        onChange={(e) =>
                          onFieldChanges({
                            go_live_at: new Date(e.target.value),
                          })
                        }
                        step={900}
                      />
                    ) : (
                      format(
                        userGroupAssessment.go_live_at,
                        'MMM d, yyyy h:mm a'
                      )
                    )}
                  </div>
                  <div className="ml-2 text-muted">
                    <DateDistance date={userGroupAssessment.go_live_at} />
                  </div>
                </div>
              </FormGroup>
              <FormGroup>
                <label>Close at</label>
                <div className="d-flex align-items-center">
                  <div>
                    <Input
                      type="datetime-local"
                      style={{ maxWidth: '250px' }}
                      value={formatDate(userGroupAssessment.close_at)}
                      onChange={(e) =>
                        onFieldChanges({
                          close_at: new Date(e.target.value),
                        })
                      }
                      step={900}
                    />
                  </div>
                  <div className="ml-2 text-muted">
                    <DateDistance date={userGroupAssessment.close_at} />
                  </div>
                </div>
              </FormGroup>
            </section>

            {/* <section>
              <h5>Choose your audience</h5>
              <p>Who is allowed to participate in this assessment?</p>
            </section> */}
            <section>
              {!userGroupAssessment.went_live_at && (
                <Link
                  to={`/w/${workspaceId}/pm/user-groups/${userGroupId}/assessments/${assessmentId}/customize`}
                  className="btn btn-secondary"
                >
                  <Icon icon={faChevronLeft} className="icon-left" /> Edit
                  questions
                </Link>
              )}
              {/* <Button color="secondary">
                    <Icon icon={faSave} className="icon-left" /> Save draft
                    </Button> */}
              {userGroupAssessment.status === 'draft' && (
                <Button color="primary" onClick={handlePublish}>
                  <Icon icon={faRocketLaunch} className="icon-left" /> Go live!
                </Button>
              )}
              {userGroupAssessment.status === 'published' && (
                <Button color="danger" onClick={handleUnpublish}>
                  <IconStack className="icon-left">
                    <Icon
                      icon={faRocketLaunch}
                      className="icon-left"
                      transform="shrink-5"
                    />
                    <Icon
                      icon={faBan}
                      className="icon-left"
                      transform="grow-5"
                    />
                  </IconStack>
                  Cancel launch
                </Button>
              )}
            </section>
          </CardBody>
        </Card>
      </div>
      <div>
        <SummaryTool />
      </div>
    </div>
  );
}

export default LaunchConfiguration;
