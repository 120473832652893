import React from 'react';
import { PanelHeader } from 'js/components';
import ChooseSourceAssessment from './ChooseSourceAssessment';

function AssessUserGroup({
  onCreateFromScratch,
  onCreateFromTemplate,
  onCreateFromRecent,
}) {
  return (
    <div>
      <PanelHeader size="flex">
        <div className="panel-body-no-label px-4">
          <h1>Assess User Group</h1>
        </div>
      </PanelHeader>
      <div className="content">
        <ChooseSourceAssessment
          onCreateFromScratch={onCreateFromScratch}
          onCreateFromTemplate={onCreateFromTemplate}
          onCreateFromRecent={onCreateFromRecent}
        />
      </div>
    </div>
  );
}

export default AssessUserGroup;
