import { FIELD_DELIMITER } from 'js/utils/string';

function capabilityReducer(selectedCapabilities) {
  function reduceCapability(acc, c) {
    if (c.id in selectedCapabilities) {
      acc.push(c);
    }
    c.children.forEach((child) => {
      reduceCapability(acc, child, selectedCapabilities);
    });
    return acc;
  }

  return reduceCapability;
}

export function getPageCapabilities(capabilitySet, selectedCapabilities) {
  return capabilitySet.capabilities.reduce(
    capabilityReducer(selectedCapabilities),
    []
  );
}

export function getAllCapabilities(document, capabilitySetCache) {
  const { pages = [] } = document;

  return pages.reduce((acc, page) => {
    if (page.type === 'capability-questions') {
      const capabilitySet = capabilitySetCache[page.capabilitySetId];
      return acc.concat(
        getPageCapabilities(capabilitySet, page.selectedCapabilities)
      );
    }
    return acc;
  }, []);
}

export function constructQuestionKey(capability, element) {
  return `${element.key}${FIELD_DELIMITER}${capability.id}`;
}

export function getQuestionId(question, capability, questionMap) {
  return questionMap[constructQuestionKey(capability, question)]?.id;
}
