import { scaleLinear } from '@visx/scale';

export const generateDomain = (suggestedDomain, range) => {
  if (suggestedDomain.length === range.length) {
    return suggestedDomain;
  }

  // TODO: if they aren't the same length, interpolate the difference
  // HACK: for now, the likely difference is a domain of 2 and range of 3, so we'll only handle that interpolation
  const [min, max] = suggestedDomain;
  const incrementCount = range.length - 1;
  const increment = (max - min) / incrementCount;
  return new Array(range.length).fill(0).map((_, i) => min + i * increment);
};

export const standardColor = () => '#28b3d1';

export const scaleColorTraffic = scaleLinear({
  domain: [0, 0.5, 1],
  range: ['#ed2228', '#f0dd2b', '#4c9745'],
});

// NOTE: These colors are defined in _palettes.scss
const categoryPalette = [
  { b: '#9d4c8e', c: '#f6e9f4' },
  { b: '#4c9745', c: '#f2fcf1' },
  { b: '#28b3d1', c: '#f6feff' },
  { b: '#f0dd2b', c: '#3d3700' },
  { b: '#ea9727', c: '#f7f3ed' },
  { b: '#4643c8', c: '#ebeaf7' },
  { b: '#ed2228', c: '#fff2f2' },
  { b: '#712563', c: '#f6e9f4' },
  { b: '#25691E', c: '#f2fcf1' },
  { b: '#02758d', c: '#f6feff' },
  { b: '#bcaa03', c: '#3d3700' },
  { b: '#9c5c07', c: '#f7f3ed' },
  { b: '#17148c', c: '#ebeaf7' },
  { b: '#a00f14', c: '#fff2f2' },
  { b: '#ED87DA', c: '#34032b' },
  { b: '#88DA81', c: '#063901' },
  { b: '#70dbf2', c: '#023541' },
  { b: '#f7eb7e', c: '#3d3700' },
  { b: '#fbb65a', c: '#3b2200' },
  { b: '#7e7be0', c: '#010022' },
  { b: '#fc8a8e', c: '#240001' },
];

export const getCategoryPaletteColor = (index) => {
  const { b, c } = categoryPalette[index % categoryPalette.length];
  return { background: b, color: c };
};

export const COLOR_COUNT = 21;

export function nextColorIndex(usedColors, totalColors = COLOR_COUNT) {
  for (let i = 0; i < totalColors; i++) {
    if (!usedColors.includes(i)) {
      return i;
    }
  }
  return Math.floor(Math.random() * COLOR_COUNT);
}
