import Big from 'big.js';
import { calcPositionBetween } from 'js/utils/big';
import { standardCollator } from 'js/utils/string';

export function updatePageFields(doc, pageGuid, updates) {
  const page = doc.getMap('pages').get(pageGuid);
  if (!page) {
    return;
  }

  doc.transact(() => {
    Object.entries(updates).forEach(([key, value]) => {
      page.set(key, value);
    });
  });
}

export function deleteCapabilityElement(doc, pageGuid, questionGuid) {
  const page = doc.getMap('pages').get(pageGuid);

  if (!page) {
    return;
  }

  page.get('capabilityElementPositions').delete(questionGuid);
}

export function moveCapabilityElementBefore(
  doc,
  pageGuid,
  elementGuid,
  beforeElementGuid
) {
  moveCapabilityElement(
    doc,
    pageGuid,
    elementGuid,
    beforeElementGuid,
    'before'
  );
}

export function moveCapabilityElementAfter(
  doc,
  pageGuid,
  elementGuid,
  afterElementGuid
) {
  moveCapabilityElement(doc, pageGuid, elementGuid, afterElementGuid, 'after');
}

export function addCapabilityElementToPage(doc, elementGuid, pageGuid) {
  console.log('🏴🥕 addCapabilityElementToPage', pageGuid, elementGuid);
  const elementPositions = doc
    .getMap('pages')
    .get(pageGuid)
    .get('capabilityElementPositions');
  const lastPosition = Object.entries(elementPositions.toJSON()).reduce(
    (acc, [key, value]) => (elementGuid !== key && acc < value ? value : acc),
    '0.5'
  );

  elementPositions.set(
    elementGuid,
    calcPositionBetween(Big(lastPosition), Big(1)).toString()
  );
}

function moveCapabilityElement(
  doc,
  pageGuid,
  elementGuid,
  targetGuid,
  direction
) {
  if (elementGuid === targetGuid) {
    return;
  }

  const { sortFn, defaultMin, defaultMax } =
    direction === 'before'
      ? {
          sortFn: ([, a], [, b]) => -standardCollator.compare(a, b),
          defaultMin: 1,
          defaultMax: 0,
        }
      : {
          sortFn: ([, a], [, b]) => standardCollator.compare(a, b),
          defaultMin: 0,
          defaultMax: 1,
        };

  const capabilityElementPositions = doc
    .getMap('pages')
    .get(pageGuid)
    .get('capabilityElementPositions');
  const positions = capabilityElementPositions.toJSON();

  const sortedPositions = Object.entries(positions).sort(sortFn);
  let targetElementPosition = null,
    followingElementPosition = null;

  for (const [elementId, elementPosition] of sortedPositions) {
    if (elementId === targetGuid) {
      targetElementPosition = [elementId, elementPosition];
    } else if (targetElementPosition != null) {
      followingElementPosition = [elementId, elementPosition];
      break;
    }
  }

  // it's already in the correct position
  if (elementGuid === followingElementPosition?.[0]) {
    return;
  }

  const newPosition = calcPositionBetween(
    Big(targetElementPosition?.[1] ?? defaultMin),
    Big(followingElementPosition?.[1] ?? defaultMax)
  );

  capabilityElementPositions.set(elementGuid, newPosition.toString());
}

export function chooseCapabilitySet(doc, pageGuid, capabilitySet) {
  const page = doc.getMap('pages').get(pageGuid);
  page.set('capabilitySetId', capabilitySet.id);
}

export function togglePageCapabilitySelection(
  doc,
  pageGuid,
  capabilityGuid,
  newState
) {
  const page = doc.getMap('pages').get(pageGuid);
  const selectedCapabilities = page.get('selectedCapabilities');
  newState = newState ?? !selectedCapabilities.has(capabilityGuid);

  if (newState) {
    const capabilitySetId = doc
      .getMap('capabilityCache')
      .get(capabilityGuid)
      .get('capability_set_id');
    if (capabilitySetId) {
      selectedCapabilities.set(capabilityGuid, capabilitySetId);
    }
  } else {
    selectedCapabilities.delete(capabilityGuid);
  }
}
