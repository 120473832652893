import React, { useMemo } from 'react';
import ParticipantResponses from './ParticipantResponses';
import { useAssessmentReviewContext } from '../AssessmentReviewContext';

function ParticipantResults({ assessment }) {
  const { questionMap } = useAssessmentReviewContext();

  const participantData = useMemo(() => {
    return assessment.question_sets.reduce(
      (qsAcc, qs) =>
        qs.questions.reduce(
          (qAcc, q) =>
            q.responses.reduce((rAcc, r) => {
              const participantResponses = (rAcc[r.participant_id] =
                rAcc[r.participant_id] ?? {});
              participantResponses[q.id] = r;
              return rAcc;
            }, qAcc),
          qsAcc
        ),
      {}
    );
  }, [assessment]);

  // const participantData = _.orderBy(
  //   assessment.participants.map((p) => {
  //     const responses = _.flatten(
  //       assessment.question_sets.map((qs) =>
  //         qs.questions.map((q) => {
  //           const response = _.find(q.responses, (r) => {
  //             return r.participant_id === p.id;
  //           });
  //           return response && !response.is_na
  //             ? parseInt(response.value, 10)
  //             : null;
  //         })
  //       )
  //     );

  //     const filteredResponses = _.filter(responses, (x) => x !== null);

  //     return {
  //       responses,
  //       overall:
  //         filteredResponses.length > 0
  //           ? _.sum(filteredResponses) / filteredResponses.length
  //           : null,
  //       id: p.id,
  //     };
  //   }),
  //   [(o) => (o.overall === null ? -1 : o.overall)],
  //   ['desc']
  // );

  const { participantsWithResponses } = useMemo(() => {
    return assessment.participants.reduce(
      (acc, participant) => {
        if (participant.id in participantData) {
          acc.participantsWithResponses.push(participant);
        } else {
          acc.participantsWithoutResponses.push(participant);
        }
        return acc;
      },
      { participantsWithResponses: [], participantsWithoutResponses: [] }
    );
  }, [assessment, participantData]);

  return (
    <div>
      {participantsWithResponses.map((p) => (
        <ParticipantResponses
          key={p.id}
          participant={p}
          questionMap={questionMap}
          responses={participantData[p.id] ?? {}}
          assessmentDocument={assessment.document}
        />
      ))}
    </div>
  );
}

export default ParticipantResults;
