import React, { useCallback } from 'react';

import { faSquareCheck } from '@fortawesome/pro-solid-svg-icons';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';

import { useEditorContext } from 'js/ydoc/docs/capabilities-assessment/EditorContext';
import { useSelectorContext } from './SelectorContext';

import { Icon } from 'js/components';

import styles from './CapabilitySelector.module.scss';

function CapabilityPickerItem({ capability, checked, color, path }) {
  const { togglePageCapabilitySelection } = useEditorContext();
  const { page, navigateToItem } = useSelectorContext();

  const handleCapabilityChange = useCallback(() => {
    togglePageCapabilitySelection(page.guid, capability.guid, !checked);
  }, [checked, capability.guid, page.guid, togglePageCapabilitySelection]);

  const handleSetActiveItem = useCallback(() => {
    navigateToItem({ capability, path });
  }, [capability, path, navigateToItem]);

  const icon = checked ? faSquareCheck : faSquare;

  return (
    <div className={styles['picker-child']} key={capability.id}>
      <span>
        <button
          className={styles['picker-child-button']}
          role="switch"
          aria-checked={checked}
          onClick={handleCapabilityChange}
          style={{ color }}
        >
          <Icon icon={icon} size="lg" />
        </button>
      </span>
      <span>
        {capability.children.length > 0 ? (
          <button
            className={styles['btn-node-name']}
            onClick={handleSetActiveItem}
          >
            {capability.name}
          </button>
        ) : (
          capability.name
        )}
      </span>
    </div>
  );
}

export default CapabilityPickerItem;
