import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { Icon } from 'js/components';

function NoUserGroupsCard({ type }) {
  const { workspaceId } = useParams();

  return (
    <Card>
      <CardBody>
        {type === 'archived' ? (
          <div className="media text-muted mt-4 mb-4">
            <Icon
              icon={['fal', 'spider-web']}
              size="4x"
              className="mr-3"
              fixedWidth
            />
            <div className="media-body">
              <p>You don't have any archived user groups.</p>
              <p>
                Archiving a user group suspends it from your account while
                preserving its data for historical purposes. We recommend
                archiving user groups you no longer use to keep your account
                tidy.
              </p>
            </div>
          </div>
        ) : (
          <div className="media text-muted mt-4 mb-4">
            <Icon
              icon={['far', 'star-exclamation']}
              size="4x"
              className="mr-3"
              fixedWidth
            />
            <div className="media-body">
              <p>You don't have any active user groups.</p>

              <div>
                <Link
                  data-test="create-team-link"
                  className="btn btn-primary create-link"
                  to={`/w/${workspaceId}/pm/user-groups/new`}
                >
                  <Icon icon="plus" className="btn-icon-left" /> Create a new
                  user group
                </Link>
              </div>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
}

export default NoUserGroupsCard;
