import React, { useCallback, useMemo } from 'react';

import EditorInput from '../../EditorInput/EditorInput';
import { Icon } from 'js/components';

import { faX } from '@fortawesome/pro-regular-svg-icons';

import styles from '../AnswerSet.module.scss';
import DraggableChoice from './DraggableChoice';

function ScoredChoice({
  choice,
  index,
  answerSetId,
  questionId,
  onUpdate = () => {},
  onDelete = () => {},
  color,
}) {
  const choiceId = choice.id ?? choice.guid;

  const handleUpdateChoiceText = useCallback(
    (e) => {
      if (choice.text === e.target.value.trim()) return;
      onUpdate(choiceId, { text: e.target.value.trim() });
    },
    [choiceId, choice.text, onUpdate]
  );

  const handleUpdateChoiceValue = useCallback(
    (e) => {
      if (choice.value === e.target.value.trim()) return;
      onUpdate(choiceId, { value: e.target.value.trim() });
    },
    [choiceId, choice.value, onUpdate]
  );

  const Actions = useMemo(
    () => () => {
      return (
        <button
          className="btn-inline-action btn-danger"
          onClick={() => onDelete(choiceId)}
        >
          <Icon icon={faX} size="sm" />
        </button>
      );
    },
    [onDelete, choiceId]
  );

  return (
    <DraggableChoice
      choice={choice}
      index={index}
      answerSetId={answerSetId}
      questionId={questionId}
      actions={Actions}
    >
      <div className={styles['choice-score']}>
        <EditorInput
          autoselect
          convertToNumber
          defaultNumber={0}
          value={choice.value}
          inputMode="numeric"
          buttonStyle={{ borderColor: color(choice.value) }}
          inputStyle={{ borderColor: color(choice.value) }}
          onBlur={handleUpdateChoiceValue}
        />
      </div>
      <div className={styles['choice-text']}>
        <EditorInput
          autoselect
          forceCommitOnEnter
          multiLine
          rows={2}
          value={choice.text}
          onBlur={handleUpdateChoiceText}
        />
      </div>
    </DraggableChoice>
  );
}

export default ScoredChoice;
