import React from 'react';
import moment from 'moment';

import { Card, CardBody } from 'reactstrap';
import { PanelHeader } from 'js/components';

// import { FIELD_DELIMITER } from 'js/utils/string';

export const MultiSelectQuestionDetail = ({ assessment, question, team }) => {
  // const { answers, responses } = question;
  // NOTE: implement this later
  // const { intersections, sets } = useMemo(() => {
  //   const answerMasks = [...answers].reduce(
  //     (acc, { value }, i) => acc.set(value, 1n << BigInt(i)),
  //     new Map()
  //   );

  //   const { intersections, sets } = responses.reduce(
  //     (responseAcc, r) => {
  //       const { intersections, sets } = responseAcc;
  //       const chosenAnswers = r.value.split(FIELD_DELIMITER);
  //       const mask = chosenAnswers.reduce(
  //         (maskAcc, a) => maskAcc | answerMasks.get(a),
  //         0n
  //       );
  //       intersections.set(mask, (answerMasks.get(mask) ?? 0) + 1);
  //       chosenAnswers.forEach((answerValue) => {
  //         const set = sets.get(answerValue);
  //         set.count += 1;
  //       });

  //       return responseAcc;
  //     },
  //     {
  //       intersections: new Map(),
  //       sets: new Map(
  //         question.answers.map((a) => [
  //           a.value,
  //           { key: a.value, text: a.text, count: 0 },
  //         ])
  //       ),
  //     }
  //   );

  //   return { intersections, sets };
  // }, [answers, responses]);

  return (
    <div className="survey-assessment review">
      <PanelHeader size="flex">
        <div className="panel-content assessment-review-header">
          <section className="details">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <label>Assessment</label>
                  <h1>{assessment.name}</h1>
                  <div className="timestamp">
                    {moment(assessment.inserted_at).format(
                      'MMM D, YYYY h:mm a'
                    )}
                  </div>
                  <label>Team</label>
                  <h2>{team?.name}</h2>
                  <label>Question</label>
                  <div className="lead">{question.text}</div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </PanelHeader>
      <div className="content survey-review-detail">
        <Card>
          <CardBody>
            <div>
              <svg viewBox="0 0 100 100">
                <g></g>
              </svg>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default MultiSelectQuestionDetail;
