import React from 'react';
import classNames from 'classnames';
import styles from './CustomizeAssessment.module.scss';

function StatusBadge({ status }) {
  return (
    <div className={classNames(styles['status-badge'], styles[status])}>
      {status}
    </div>
  );
}

export default StatusBadge;
