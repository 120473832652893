import React, { useState, useMemo } from 'react';
import classNames from 'classnames';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { Icon } from 'js/components';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';

import styles from './EditorTool.module.scss';

export function EditorTool({
  className,
  title,
  children,
  collapsible = false,
  defaultOpen = true,
  actions,
}) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const toolClassName = useMemo(
    () =>
      classNames(styles.tool, className, {
        [styles['is-open']]: !collapsible || isOpen,
      }),
    [className, isOpen, collapsible]
  );

  return (
    <Card className={toolClassName}>
      <div className={styles['tool-header']}>
        <CardTitle>
          {collapsible ? (
            <button
              aria-pressed={isOpen}
              onClick={() => setIsOpen(!isOpen)}
              className={styles['tool-collapse-toggle']}
            >
              <span className="card-title">{title}</span>
              <Icon icon={faChevronRight} rotation={isOpen ? 90 : 0} />
            </button>
          ) : (
            title
          )}
        </CardTitle>
        {/* {collapsible && (
          <div
            className={`${styles['tool-collapse-toggle']} ${
              isOpen ? styles['is-open'] : ''
            }`}
          >
            <button className="btn-inline-action btn-primary"></button>
          </div>
        )} */}
      </div>
      {(!collapsible || isOpen) && <CardBody>{children}</CardBody>}
    </Card>
  );
}
export default EditorTool;
