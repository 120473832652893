import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useSelector, useStore } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import { denormalize } from 'normalizr';
import { listUserGroups, updateUserGroup } from 'js/actions/user-group-actions';
import UserGroupsDashboardComponent from '../components/UserGroupsDashboard/UserGroupsDashboardComponent';
import { selectActiveWorkspace } from 'js/store/reducers/workspaces';
import { useNotify } from 'js/hooks/useNotify';
import { SCHEMAS } from 'js/api/schemas';
function userGroupsQueryId(workspaceId) {
  return `${workspaceId}-user-groups`;
}

function UserGroupsDashboard({ listUserGroups, updateUserGroup, workspace }) {
  const { workspaceId } = useParams();
  const notify = useNotify();
  const store = useStore();

  const groupsQueryId = userGroupsQueryId(workspaceId);
  const groupsQuery = useSelector((state) => state.queries[groupsQueryId]);

  const refreshUserGroups = useCallback(
    () => listUserGroups(workspaceId, groupsQueryId),
    [listUserGroups, workspaceId, groupsQueryId]
  );

  const [lastGroupsQueryResult, setLastGroupsQueryResult] = useState(null);
  const result = groupsQuery?.result;
  useEffect(() => {
    if (result) {
      setLastGroupsQueryResult(result);
    }
  }, [result]);

  const userGroups = denormalize(
    lastGroupsQueryResult || [],
    SCHEMAS.USER_GROUP_ARRAY,
    store.getState().entities
  );

  useEffect(() => {
    refreshUserGroups();
  }, [refreshUserGroups]);

  const modifyUserGroupState = useMemo(
    () => (newState) => (userGroup) => {
      updateUserGroup(userGroup.id, {
        archived_state: newState,
        archived_at: newState === 'archived' ? new Date() : null,
      })
        .then(() => {
          const op = newState === 'archived' ? 'archived' : 'restored';
          notify({
            type: 'success',
            message: `The "${userGroup.name}" group was successfully ${op}.`,
          });
        })
        .catch(() => {
          const op = newState === 'archived' ? 'archiving' : 'restoring';
          notify({
            type: 'danger',
            message: `Sorry, there was a problem ${op} the group, "${userGroup.name}".`,
          });
        });
    },
    [updateUserGroup, notify]
  );

  const handleArchiveUserGroup = useMemo(
    () => modifyUserGroupState('archived'),
    [modifyUserGroupState]
  );
  const handleRestoreUserGroup = useMemo(
    () => modifyUserGroupState(null),
    [modifyUserGroupState]
  );

  return (
    <UserGroupsDashboardComponent
      userGroups={userGroups}
      groupsQuery={groupsQuery}
      workspace={workspace}
      onArchiveUserGroup={handleArchiveUserGroup}
      onRestoreUserGroup={handleRestoreUserGroup}
    />
  );
}

export default withRouter(
  connect(
    (state) => {
      const workspace = selectActiveWorkspace(state);
      return {
        workspace,
      };
    },
    { listUserGroups, updateUserGroup }
  )(UserGroupsDashboard)
);
