import React, {
  useMemo,
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import CapabilityAnswerSet from './AnswerSets/CapabilityAnswerSet';
import { EditorInput } from '../../common/components/EditorInput';
import { FormGroup } from 'reactstrap';
import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import {
  attachClosestEdge,
  extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';

import { faGripVertical } from '@fortawesome/pro-solid-svg-icons';
import { Icon } from 'js/components';
import CapabilityQuestionActions from './CapabilityQuestionActions';

import styles from '../CapabilitiesAssessmentEditor.module.scss';
import commonStyles from '../../common/EditorCommon.module.scss';
import { useEditorContext } from 'js/ydoc/docs/capabilities-assessment/EditorContext';

import DropIndicator from 'js/components/Assessment/Editors/common/components/DropIndicator';
import { isNullOrWhitespace } from 'js/utils/string';

const questionTextPlaceholder =
  'Question text: e.g. Rate your current level of satisfaction with the following capabilities:';

function DragPreview({ question }) {
  return (
    <div className={`${styles['capability-question']} drag-preview`}>
      <div className={styles['question-header']}>
        <div className={styles['drag-handle']}>
          <span className="drag-handle">
            <Icon icon={faGripVertical} className="icon" />
          </span>
        </div>
        <div>
          <label>{question.name}</label>
        </div>
      </div>
      <div className={commonStyles['question-body']}>
        {!isNullOrWhitespace(question.text)
          ? question.text
          : questionTextPlaceholder}
      </div>
    </div>
  );
}

function CapabilityQuestion({ question, pageGuid }) {
  const {
    deleteCapabilityElement,
    moveCapabilityElementAfter,
    moveCapabilityElementBefore,
    updateCapabilityElement,
  } = useEditorContext();
  const [showSettings, setShowSettings] = useState(false);

  const handleDelete = () => {
    deleteCapabilityElement(pageGuid, question.guid);
  };

  const handleFieldChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      updateCapabilityElement(question.guid, { [name]: value });
    },
    [question.guid, updateCapabilityElement]
  );

  const rootClassName = useMemo(() => {
    return classNames(styles['capability-question'], {
      'hide-settings': true && !showSettings,
    });
  }, [showSettings]);

  const ref = useRef(null);
  const dragHandleRef = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const [closestEdge, setClosestEdge] = useState(null);

  useEffect(() => {
    const el = ref.current;
    const dragHandle = dragHandleRef.current;

    if (!el) throw new Error('No element found');
    if (!dragHandle) throw new Error('No element found');

    return combine(
      draggable({
        element: el,
        dragHandle,
        getInitialData: () => ({ capabilityElement: question, pageGuid }),
        onGenerateDragPreview: ({ nativeSetDragImage }) => {
          setCustomNativeDragPreview({
            render({ container }) {
              ReactDOM.render(<DragPreview question={question} />, container);
              return function cleanup() {
                ReactDOM.unmountComponentAtNode(container);
              };
            },
            getOffset: () => ({ x: 20, y: 16 }),
            nativeSetDragImage,
          });
        },
      }),
      dropTargetForElements({
        element: el,
        canDrop: ({ source }) =>
          source.data.capabilityElement &&
          (source.data?.pageGuid === pageGuid ||
            source.data.pageGuid === undefined) &&
          source.data.capabilityElement.guid !== question.guid,
        getData: (args) => {
          return attachClosestEdge(
            { capabilityElement: question },
            {
              input: args.input,
              element: args.element,
              allowedEdges: ['top', 'bottom'],
            }
          );
        },
        onDragEnter: () => setIsDraggedOver(true),
        onDragLeave: () => {
          setClosestEdge(null);
          setIsDraggedOver(false);
        },
        onDrag({ self, source }) {
          const isSource = source.element === el;
          if (
            isSource ||
            (source.data?.pageGuid !== pageGuid &&
              source.data?.pageGuid !== undefined)
          ) {
            setClosestEdge(null);
            return;
          }

          const closestEdge = extractClosestEdge(self.data);

          setClosestEdge(closestEdge);
        },
        onDrop: ({ source, location }) => {
          setIsDraggedOver(false);

          const dropTarget = location.current.dropTargets[0];
          const closestEdge = extractClosestEdge(dropTarget.data);

          setClosestEdge(null);

          (closestEdge === 'bottom'
            ? moveCapabilityElementAfter
            : moveCapabilityElementBefore)(
            pageGuid,
            source.data.capabilityElement.guid,
            question.guid
          );
        },
        getIsSticky: (args) => {
          const elementUnderCursor = document.elementFromPoint(
            args.input.pageX,
            args.input.pageY
          );
          return (
            elementUnderCursor &&
            elementUnderCursor.closest('.question-set-droppable-area')
          );
        },
      })
    );
  }, [
    question,
    pageGuid,
    moveCapabilityElementAfter,
    moveCapabilityElementBefore,
  ]);

  return (
    <div className={rootClassName} ref={ref}>
      {isDraggedOver && (
        <DropIndicator
          top={closestEdge === 'top'}
          bottom={closestEdge === 'bottom'}
        />
      )}
      <div className={styles['question-header']}>
        <div className={styles['drag-handle']}>
          <button className="drag-handle" ref={dragHandleRef}>
            <Icon icon={faGripVertical} className="icon" />
          </button>
        </div>
        <div>
          <label>{question.name}</label>
        </div>
      </div>
      <div className={commonStyles['question-body']}>
        <div>
          <EditorInput
            name="text"
            value={question.text}
            onChange={handleFieldChange}
            placeholder={questionTextPlaceholder}
            multiLine
            buttonStyle={{ textAlign: 'left' }}
          />
        </div>
        <div className="drag-preview-hide">
          <div className={styles['answer-sets']}>
            <CapabilityAnswerSet answerSetId={question.answerSetId} />
          </div>
        </div>
      </div>
      <div className={commonStyles['settings-wrapper']}>
        <div className={commonStyles['settings-drawer']}>
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <FormGroup>
                <div>
                  <label>Name</label>
                </div>
                <EditorInput
                  name="name"
                  value={question.name}
                  onChange={handleFieldChange}
                  placeholder="Question name"
                />
              </FormGroup>
            </div>
            <div className="col-md-6 col-lg-3">
              <FormGroup>
                <div>
                  <label>Key</label>
                </div>
                <EditorInput
                  name="key"
                  value={question.key}
                  onChange={handleFieldChange}
                  placeholder="Question key"
                />
              </FormGroup>
            </div>
          </div>
        </div>

        <div className={commonStyles.footer}>
          <div className={commonStyles.type}></div>
          <div className={commonStyles.actions}>
            <CapabilityQuestionActions
              onDelete={handleDelete}
              onSettings={() => setShowSettings(!showSettings)}
              settingsActive={showSettings}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CapabilityQuestion;
