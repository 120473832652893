import keymirror from 'keymirror';
import { request } from 'js/api/axios';
import { callApi } from '../api';

import { SCHEMAS } from 'js/api/schemas';
import { spreadApiKeys, extractApiKeys } from '../utils';

export const types = keymirror({
  ...spreadApiKeys('LOAD_CAPABILITY_SET'),
  ...spreadApiKeys('LOAD_CAPABILITY_SETS'),
  ...spreadApiKeys('CREATE_CAPABILITY_SET'),
  ...spreadApiKeys('UPDATE_CAPABILITY_SET'),
  ...spreadApiKeys('DELETE_CAPABILITY_SET'),
  ...spreadApiKeys('LOAD_CAPABILITY'),
  ...spreadApiKeys('LOAD_CAPABILITIES'),
  ...spreadApiKeys('CREATE_CAPABILITY'),
  ...spreadApiKeys('UPDATE_CAPABILITY'),
  ...spreadApiKeys('DELETE_CAPABILITY'),
  ...spreadApiKeys('LOAD_CAPABILITY_TREE'),
  REPARENT_CAPABILITY: null,
});

// Capability Set Actions
export function listCapabilitySets(workspaceId, queryId = null) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.LOAD_CAPABILITY_SETS, types),
        request: (_data, token) =>
          request.get(`/api/workspaces/${workspaceId}/capability-sets`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        schema: SCHEMAS.CAPABILITY_SET_ARRAY,
        meta: { queryId },
      },
      dispatch
    );
  };
}

export function loadCapabilitySet(id, queryId = null) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.LOAD_CAPABILITY_SET, types),
        request: (_data, token, currentWorkspaceId) =>
          request.get(
            `/api/workspaces/${currentWorkspaceId}/capability-sets/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        schema: SCHEMAS.CAPABILITY_SET,
        meta: { queryId },
      },
      dispatch
    );
  };
}

export function createCapabilitySet(capabilitySet) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.CREATE_CAPABILITY_SET, types),
        request: (data, token, currentWorkspaceId) =>
          request.post(
            `/api/workspaces/${currentWorkspaceId}/capability-sets`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        data: { capability_set: capabilitySet },
        schema: SCHEMAS.CAPABILITY_SET,
      },
      dispatch
    );
  };
}

export function updateCapabilitySet(id, patch) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.UPDATE_CAPABILITY_SET, types),
        request: (data, token, currentWorkspaceId) =>
          request.put(
            `/api/workspaces/${currentWorkspaceId}/capability-sets/${id}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        data: { capability_set: patch },
        meta: { patch: { capabilitySets: { [id]: patch } } },
        schema: SCHEMAS.CAPABILITY_SET,
      },
      dispatch
    );
  };
}

export function deleteCapabilitySet(id) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.DELETE_CAPABILITY_SET, types),
        request: (_data, token, currentWorkspaceId) =>
          request.delete(
            `/api/workspaces/${currentWorkspaceId}/capability-sets/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        meta: { delete: { capabilitySets: id } },
      },
      dispatch
    );
  };
}

// Capability Actions
export function listCapabilities(capabilitySetId, queryId = null) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.LOAD_CAPABILITIES, types),
        request: (_data, token, currentWorkspaceId) =>
          request.get(
            `/api/workspaces/${currentWorkspaceId}/capability-sets/${capabilitySetId}/capabilities`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        schema: SCHEMAS.CAPABILITY_ARRAY,
        meta: { queryId },
      },
      dispatch
    );
  };
}

export function loadCapability(capabilitySetId, id, queryId = null) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.LOAD_CAPABILITY, types),
        request: (_data, token, currentWorkspaceId) =>
          request.get(
            `/api/workspaces/${currentWorkspaceId}/capability-sets/${capabilitySetId}/capabilities/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        schema: SCHEMAS.CAPABILITY,
        meta: { queryId },
      },
      dispatch
    );
  };
}

export function createCapability(capabilitySetId, capability) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.CREATE_CAPABILITY, types),
        request: (data, token, currentWorkspaceId) =>
          request.post(
            `/api/workspaces/${currentWorkspaceId}/capability-sets/${capabilitySetId}/capabilities`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        data: { capability },
        schema: SCHEMAS.CAPABILITY,
      },
      dispatch
    );
  };
}

export function updateCapability(capabilitySetId, id, patch) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.UPDATE_CAPABILITY, types),
        request: (data, token, currentWorkspaceId) =>
          request.put(
            `/api/workspaces/${currentWorkspaceId}/capability-sets/${capabilitySetId}/capabilities/${id}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        data: { capability: patch },
        schema: SCHEMAS.CAPABILITY,
      },
      dispatch
    );
  };
}

export function deleteCapability(capabilitySetId, id) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.DELETE_CAPABILITY, types),
        request: (_data, token, currentWorkspaceId) =>
          request.delete(
            `/api/workspaces/${currentWorkspaceId}/capability-sets/${capabilitySetId}/capabilities/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        meta: { delete: { capabilities: id } },
      },
      dispatch
    );
  };
}

export function loadCapabilityTree(capabilitySetId, queryId = null) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.LOAD_CAPABILITY_TREE, types),
        request: (_data, token, currentWorkspaceId) =>
          request.get(
            `/api/workspaces/${currentWorkspaceId}/capability-sets/${capabilitySetId}/capabilities/tree`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        schema: SCHEMAS.CAPABILITY_ARRAY,
        meta: { queryId },
      },
      dispatch
    );
  };
}
