import React, { useMemo } from 'react';
import classNames from 'classnames';
import styles from '../EditorCommon.module.scss';

export default function DropIndicator({ bottom, top }) {
  const c = useMemo(
    () =>
      classNames(styles['drop-indicator'], 'drop-indicator', {
        [styles.bottom]: bottom,
        'drop-indicator-bottom': bottom,
        [styles.top]: top,
        'drop-indicator-top': top,
      }),
    [bottom, top]
  );

  return <div className={c}></div>;
}
