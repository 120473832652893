import React from 'react';

import { Icon } from 'js/components';

import SourceCard from './SourceCard';

import {
  faFluxCapacitor,
  faCommentQuestion,
} from '@fortawesome/pro-regular-svg-icons';

import styles from './AssessUserGroup.module.scss';

function TemplateCard() {
  return (
    <SourceCard title="Template name">
      <span className={styles['template-content']}>
        <span className={styles['template-description']}>
          <span className={styles['template-description-text']}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vel
            nisi eu eros imperdiet tempus non vitae neque. Suspendisse quam
            velit, maximus a venenatis vitae, vehicula mattis lectus. Aenean leo
            sem, molestie vitae luctus id, posuere ac erat. Pellentesque quis
            dolor quis ante sagittis gravida in sit amet tortor. Ut in congue
            nisi. Ut ac aliquet elit. Nam rutrum dictum egestas. Aliquam erat
            volutpat.
          </span>
        </span>
        <span className={styles['template-stats']}>
          <span className={styles['template-stats-item']}>
            <Icon
              icon={faFluxCapacitor}
              className="text-info icon-left"
              fixedWidth
            />
            3 capability questions
          </span>
          <span className={styles['template-stats-item']}>
            <Icon
              icon={faCommentQuestion}
              className="text-info icon-left"
              fixedWidth
            />
            2 additional questions
          </span>
        </span>
      </span>
    </SourceCard>
  );
}

export default TemplateCard;
