import React from 'react';
import { connect } from 'react-redux';
import { createUserGroup } from 'js/actions/user-group-actions';
import { useNotify } from 'js/hooks/useNotify';
import { useHistory, useParams } from 'react-router-dom';
import CreateUserGroupComponent from '../components/CreateUserGroup';

function CreateUserGroup({ createUserGroup }) {
  const notify = useNotify();
  const history = useHistory();
  const { workspaceId } = useParams();

  const handleSubmit = async (values) => {
    // e.preventDefault();
    try {
      console.log('formData', values);
      const result = await createUserGroup(values);
      notify({
        type: 'success',
        message: 'User group created successfully',
      });
      history.push(`/w/${workspaceId}/pm/user-groups`);
      return result;
    } catch (error) {
      notify({
        type: 'danger',
        message: 'Failed to create user group',
      });
    }
  };

  return <CreateUserGroupComponent onSubmit={handleSubmit} />;
}

export default connect(null, { createUserGroup })(CreateUserGroup);
