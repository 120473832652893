import React, { useMemo } from 'react';
import { useEditorContext } from 'js/ydoc/docs/capabilities-assessment/EditorContext';

import { standardCollator } from 'js/utils/string';

import styles from '../SummaryTool.module.scss';

function sortCapabilities(a, b) {
  return (
    standardCollator.compare(a.name, b.name) ||
    standardCollator.compare(a.guid, b.guid)
  );
}

function getCapability(id, capabilityCache) {
  const c = capabilityCache[id];
  return {
    ...c,
    children: Object.keys(c.children)
      .map((cId) => getCapability(cId, capabilityCache))
      .sort(sortCapabilities),
  };
}

function CapabilityPageSummary({ page, maxCapabilities = 4 }) {
  const { assessment } = useEditorContext();

  const capabilitySet = useMemo(() => {
    const cs = assessment.capabilitySetCache[page.capabilitySetId];

    return {
      ...cs,
      capabilities: Object.keys(cs.capabilities)
        .map((cId) => getCapability(cId, assessment.capabilityCache))
        .sort(sortCapabilities),
    };
  }, [
    page.capabilitySetId,
    assessment.capabilitySetCache,
    assessment.capabilityCache,
  ]);

  const selectedCapabilities = useMemo(() => {
    if (!capabilitySet) {
      return [];
    }

    function pushSelected(capability, acc) {
      if (capability.guid in page.selectedCapabilities) {
        acc.push(capability);
      }

      if (capability.children) {
        capability.children.forEach((childId) => pushSelected(childId, acc));
      }
    }

    return capabilitySet.capabilities.reduce((acc, capability) => {
      pushSelected(capability, acc);
      return acc;
    }, []);
  }, [capabilitySet, page.selectedCapabilities]);

  return (
    <div className={styles['page-summary']}>
      <section>
        <h5 className={styles['capability-name']}>{capabilitySet.name}</h5>
        <div>
          {selectedCapabilities.slice(0, maxCapabilities).map((c, i, arr) => (
            <React.Fragment key={c.id}>
              <span className="text-info">{c.name}</span>
              {i < arr.length - 1 && ', '}
            </React.Fragment>
          ))}
          {selectedCapabilities.length > maxCapabilities && (
            <>
              ,
              <span className="text-muted font-italic">
                {' +'}
                {selectedCapabilities.length - maxCapabilities} more
              </span>
            </>
          )}
        </div>
      </section>
      <section>
        <label>Questions</label>
        <div>{page.capabilityElements.map((cq) => cq.name).join(', ')}</div>
      </section>
    </div>
  );
}

export default CapabilityPageSummary;
