import React, { useRef, useEffect, useState, useContext, useMemo } from 'react';
import classNames from 'classnames';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Icon } from 'js/components';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { TreeContext } from '../TreeContext';
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';

import styles from './CapabilityTree.module.scss';

function CapabilitySetNode({ capabilitySet, onAddChild, className }) {
  const { uniqueContextId } = useContext(TreeContext);

  const [showMenu, setShowMenu] = useState(false);
  const [isDropTarget, setIsDropTarget] = useState(false);
  const dropRef = useRef(null);

  useEffect(() => {
    if (!dropRef.current) return;

    return combine(
      dropTargetForElements({
        element: dropRef.current,
        getData: () => {
          return {
            type: 'capability-set',
            uniqueContextId: uniqueContextId,
            capabilitySet,
          };
        },
        canDrop: ({ source }) => {
          return (
            source.data.type === 'capability' &&
            source.data.uniqueContextId === uniqueContextId
          );
        },
        onDragEnter: () => {
          setIsDropTarget(true);
        },
        onDragLeave: () => {
          setIsDropTarget(false);
        },
        onDrop: () => {
          setIsDropTarget(false);
        },
      })
    );
  }, [dropRef, uniqueContextId, capabilitySet]);

  const nodeClassName = useMemo(
    () =>
      classNames(styles['capability-set'], className, {
        [styles['drop-target']]: isDropTarget,
      }),
    [isDropTarget, className]
  );

  return (
    <>
      <div className={nodeClassName}>
        <div className={styles['capability-set-name']} ref={dropRef}>
          {capabilitySet.name}
        </div>
        <div className={styles['capability-menu']}>
          <Dropdown isOpen={showMenu} toggle={() => setShowMenu(!showMenu)}>
            <DropdownToggle color="tree-menu">
              <Icon icon={faEllipsis} />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={() => onAddChild([])}>
                Add capability
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </>
  );
}

export default CapabilitySetNode;
