import React, { useMemo } from 'react';
import { isNullOrWhitespace } from 'js/utils/string';
import { PanelHeader, Icon, ReplaceLink } from 'js/components';
import { Breadcrumbs } from 'js/components/Breadcrumbs';
import { Card, CardBody, Nav, NavItem, NavLink } from 'reactstrap';
import {
  useParams,
  Link,
  Switch,
  Route,
  useRouteMatch,
  matchPath,
} from 'react-router-dom';
import UserGroupSettings from './UserGroupSettings';
import UserGroupDashboard from './UserGroupDashboard';
import { faPoll } from '@fortawesome/pro-regular-svg-icons';

const TABS = {
  ACTIVITY: 'activity',
  CAPABILITIES: 'capabilities',
  SETTINGS: 'settings',
};

function getActiveTab() {
  if (
    null !=
    matchPath(window.location.pathname, {
      path: '/w/:workspaceId/pm/user-groups/:userGroupId/settings',
    })
  ) {
    return TABS.SETTINGS;
  } else if (
    null !=
    matchPath(window.location.pathname, {
      path: '/w/:workspaceId/pm/user-groups/:userGroupId/capabilities',
    })
  ) {
    return TABS.CAPABILITIES;
  } else {
    return TABS.ACTIVITY;
  }
}

function UserGroupComponent({
  userGroup,
  userGroupAssessments,
  userGroupAssessmentsQuery,
  onUpdate,
  onUpdateAssessment,
}) {
  const { workspaceId, userGroupId } = useParams();
  const match = useRouteMatch();

  const matchedTab = useMemo(getActiveTab, [window.location.pathname]);

  const breadcrumbs = useMemo(
    () => [
      {
        label: 'User groups',
        to: `/w/${workspaceId}/pm/user-groups`,
      },
    ],
    [workspaceId]
  );

  return (
    <div>
      <PanelHeader size="flex">
        <div className="panel-body-no-label px-4">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <div className="content-fluid">
            <div className="row">
              <div className="col-12 col-md-9">
                <h1>{userGroup?.name}</h1>
                {!isNullOrWhitespace(userGroup?.description) && (
                  <p>{userGroup?.description}</p>
                )}
              </div>
              <div className="panel-actions col-12 col-md-3">
                <Link
                  className="btn btn-primary create-link"
                  to={`/w/${workspaceId}/pm/user-groups/${userGroupId}/assessments/new`}
                >
                  <Icon icon={faPoll} size="lg" className="btn-icon-left" />
                  Assess
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <Nav className="tab-nav nav-underline">
            <NavItem>
              <NavLink
                tag={ReplaceLink}
                active={matchedTab === TABS.ACTIVITY}
                data-view="active"
                to={`/w/${workspaceId}/pm/user-groups/${userGroupId}`}
              >
                Activity
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                tag={ReplaceLink}
                data-view="results"
                to={`/w/${workspaceId}/pm/user-groups/${userGroupId}/capabilities`}
                active={matchedTab === TABS.CAPABILITIES}
              >
                Capabilities
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink
                tag={ReplaceLink}
                data-view="results"
                to={`/w/${workspaceId}/pm/user-groups/${userGroupId}/settings`}
                active={matchedTab === TABS.SETTINGS}
              >
                Settings
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </PanelHeader>

      <div className="content">
        {userGroup ? (
          <Switch>
            <Route path={`${match.path}/settings`}>
              <UserGroupSettings
                userGroup={userGroup}
                onUpdate={onUpdate}
                cancelUrl={match.url}
              />
            </Route>
            <Route exact path={`${match.path}`}>
              <UserGroupDashboard
                userGroup={userGroup}
                userGroupAssessments={userGroupAssessments}
                userGroupAssessmentsQuery={userGroupAssessmentsQuery}
                onUpdateAssessment={onUpdateAssessment}
              />
            </Route>
          </Switch>
        ) : (
          <Card>
            <CardBody></CardBody>
          </Card>
        )}
      </div>
    </div>
  );
}

export default UserGroupComponent;
