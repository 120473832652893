import React from 'react';
import CapabilitiesPage from './CapabilitiesPage';

function Page({ page }) {
  switch (page.type) {
    case 'capability-questions':
      return <CapabilitiesPage page={page} />;
    default:
      return null;
  }
}

export default Page;
