import React, { useEffect, useState, useCallback } from 'react';

import {
  load as loadUserGroupAssessment,
  update as updateUserGroupAssessment,
  goLive,
} from 'js/actions/user-group-assessment-actions';
import { loadUserGroup } from 'js/actions/user-group-actions';
import { listCapabilitySets } from 'js/actions/capability-actions';
import { default as CustomizeAssessmentComponent } from '../components/CustomizeAssessment';
import { connect, useSelector, useStore } from 'react-redux';
import { useParams } from 'react-router-dom';
import { denormalize } from 'normalizr';
import { SCHEMAS } from 'js/api/schemas';
import { convertToAssessment } from 'js/ydoc/docs/capabilities-assessment/convert';

function capabilitySetsQueryId(workspaceId) {
  return `${workspaceId}-capability-sets`;
}

function CustomizeAssessment({
  userGroupAssessment,
  userGroup,
  loadUserGroupAssessment,
  listCapabilitySets,
  updateUserGroupAssessment,
  loadUserGroup,
  goLive,
}) {
  const { userGroupId, assessmentId, workspaceId } = useParams();
  const store = useStore();

  const refreshUserGroup = useCallback(
    () => loadUserGroup(userGroupId),
    [loadUserGroup, userGroupId]
  );

  useEffect(() => {
    refreshUserGroup();
  }, [refreshUserGroup]);

  useEffect(() => {
    loadUserGroupAssessment(userGroupId, assessmentId);
  }, [loadUserGroupAssessment, userGroupId, assessmentId]);

  const setsQueryId = capabilitySetsQueryId(workspaceId);
  const setsQuery = useSelector((state) => state.queries[setsQueryId]);

  const refreshCapabilitySets = useCallback(
    () => listCapabilitySets(workspaceId, setsQueryId),
    [listCapabilitySets, workspaceId, setsQueryId]
  );

  const [lastSetsQueryResult, setLastSetsQueryResult] = useState(null);
  const result = setsQuery?.result;
  useEffect(() => {
    if (result) {
      setLastSetsQueryResult(result);
    }
  }, [result]);

  const capabilitySets = denormalize(
    lastSetsQueryResult || [],
    SCHEMAS.CAPABILITY_SET_ARRAY,
    store.getState().entities
  );

  useEffect(() => {
    refreshCapabilitySets();
  }, [refreshCapabilitySets]);

  const handleFieldChanges = useCallback(
    (values) => updateUserGroupAssessment(userGroupId, assessmentId, values),
    [updateUserGroupAssessment, userGroupId, assessmentId]
  );

  const handleCreateAssessment = useCallback(
    async (assessmentDoc) => {
      const assessment = convertToAssessment(assessmentDoc);
      return goLive(userGroupId, assessmentId, assessment);
    },
    [goLive, userGroupId, assessmentId]
  );

  return (
    <CustomizeAssessmentComponent
      userGroupAssessment={userGroupAssessment}
      capabilitySets={capabilitySets}
      onFieldChanges={handleFieldChanges}
      onAssessmentCreate={handleCreateAssessment}
      userGroup={userGroup}
    />
  );
}

export default connect(
  (state, { match }) => {
    const { assessmentId, userGroupId } = match.params;

    const userGroupAssessment =
      state.entities.userGroupAssessments[assessmentId];

    const userGroup = state.entities.userGroups[userGroupId];

    return {
      userGroupAssessment,
      userGroup,
    };
  },
  {
    loadUserGroupAssessment,
    listCapabilitySets,
    updateUserGroupAssessment,
    loadUserGroup,
    goLive,
  }
)(CustomizeAssessment);

/*
const token = useSelector((state) => state.identity.token);
const currentWorkspaceId = useSelector((state) => state.currentWorkspaceId);
const [assessmentDocTemplate, setAssessmentDocTemplate] = useState(null);
const { templateId } = useParams();
const [error, setError] = useState(null);
const [loading, setLoading] = useState(true);

useEffect(() => {
  (async () => {
    if (currentWorkspaceId) {
      try {
        const response = await request.get(
          `/api/workspaces/${currentWorkspaceId}/assessment-doc-templates/${templateId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setError(null);
        setAssessmentDocTemplate(response.data.data);
      } catch (e) {
        console.error(e);
        setError('We are sorry. There was a problem loading the assessment.');
      } finally {
        setLoading(false);
      }
    }
  })();
}, [token, currentWorkspaceId, templateId]);
*/
