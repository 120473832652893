import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import Editor from './Editor';
import Tools from './Tools';

import styles from './CapabilitiesAssessmentEditor.module.scss';
import commonStyles from '../common/EditorCommon.module.scss';
import { EditorProvider } from 'js/ydoc/docs/capabilities-assessment/EditorContext';
import { DocumentProvider } from 'js/ydoc/doc/component';

import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';

export default function LaunchInterface({
  userGroupAssessment,
  capabilitySets,
}) {
  const token = useSelector((state) => state.identity.token);

  const toolsRef = useRef(null);
  useEffect(() => {
    const toolsEl = toolsRef.current;
    if (!toolsEl) throw new Error('No element found');
    return autoScrollForElements({
      element: toolsEl,
    });
  }, []);

  if (token) {
    return (
      <DocumentProvider
        channelProviderToken={token}
        channelProviderTopic={`crdt:user-group-assessment:${userGroupAssessment.crdt_doc_id}`}
      >
        <EditorProvider
          capabilitySets={capabilitySets}
          userGroupAssessment={userGroupAssessment}
        >
          <div
            className={classNames(
              commonStyles['editor-grid'],
              styles['editor-grid']
            )}
          >
            <div className={commonStyles['main-body']}>
              <Editor />
            </div>
            <div className={commonStyles.tools} ref={toolsRef}>
              <Tools />
            </div>
          </div>
        </EditorProvider>
      </DocumentProvider>
    );
  }

  return null;
}
