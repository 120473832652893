import React from 'react';
import PageReview from './PageReview';
import { useAssessmentContext } from '../../../../AssessmentContext';
function CapabilityResponses() {
  const { assessment } = useAssessmentContext();

  return (
    <div>
      {assessment.document.pages.map((p) => (
        <PageReview key={p.guid} page={p} />
      ))}
    </div>
  );
}

export default CapabilityResponses;
