import React from 'react';
import CapabilityQuestionResults from './CapabilityQuestionResults';
import CapabilityOverview from './CapabilityOverview';
import styles from '../Review.module.scss';

function CapabilityResults({ capability, elements }) {
  return (
    <div className={styles['capability-results']}>
      <div className={styles['capability-path']}>
        {capability.path.reverse().map((p) => (
          <div className={styles['path-list-item']} key={p.name}>
            <div className={styles['path-list-item-name']}>{p.name}</div>
            <div className={styles['path-list-item-chevron']}>&gt;</div>
          </div>
        ))}
      </div>
      <h3 className={styles['capability-name']}>{capability.name}</h3>
      <div className={styles['capability-elements']}>
        {elements.length > 1 && (
          <div className={styles['capability-overview']}>
            <CapabilityOverview capability={capability} elements={elements} />
          </div>
        )}
        <div className={styles['capability-questions']}>
          {elements.map((element) => (
            <CapabilityQuestionResults
              key={element.guid}
              capability={capability}
              element={element}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default CapabilityResults;
