import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle, Button } from 'reactstrap';
import { Icon } from 'js/components';

function UserGroupCard({ userGroup, workspace, onArchive, onRestore }) {
  const isArchived = userGroup.archived_state === 'archived';

  const handleArchive = useCallback(async () => {
    await onArchive(userGroup);
  }, [onArchive, userGroup]);

  const handleRestore = useCallback(async () => {
    await onRestore(userGroup);
  }, [onRestore, userGroup]);

  return (
    <Card className="mb-3">
      <CardBody>
        <div className="d-flex justify-content-between align-items-start">
          <div>
            <CardTitle tag="h5">
              <Link
                to={`/w/${workspace.id}/pm/user-groups/${userGroup.id}`}
                className="text-decoration-none"
              >
                {userGroup.name}
              </Link>
            </CardTitle>
            <p className="text-muted mb-0">{userGroup.description}</p>
          </div>
          <div className="actions">
            {isArchived ? (
              <Button
                color="secondary"
                size="sm"
                className="btn-icon"
                onClick={handleRestore}
              >
                <Icon icon="box-open" />
              </Button>
            ) : (
              <Button
                color="secondary"
                size="sm"
                className="btn-icon"
                onClick={handleArchive}
              >
                <Icon icon="archive" className="btn-icon" />
              </Button>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default UserGroupCard;
