// export type TreeItem = {
// 	id: string;
// 	isDraft?: boolean;
// 	children: TreeItem[];
// 	isOpen?: boolean;
// };

export function getInitialTreeState(capabilitySet) {
  return {
    data: capabilitySet ? updateFromCapabilitySet({}, capabilitySet) : {},
    addingCapabilityTo: null,
    lastAction: null,
  };
}

function newNode(capability) {
  return {
    id: capability.id,
    isOpen: true,
  };
}

function reduceCapability(data, source, capability) {
  const node = source[capability.id] ?? newNode(capability);

  data[capability.id] = node;

  capability.children.forEach((child) => {
    data = reduceCapability(data, source, child);
  });

  return data;
}

export function updateFromCapabilitySet(data, capabilitySet) {
  if (!capabilitySet) {
    return data;
  }

  const res = capabilitySet.capabilities.reduce(
    (acc, capability) => reduceCapability(acc, data, capability),
    {}
  );

  return res;
}

function toggleNode(data, itemId) {
  const node = data[itemId];

  if (!node) {
    return data;
  }

  return {
    ...data,
    [itemId]: {
      ...node,
      isOpen: !node.isOpen,
    },
  };
}

function setNodeOpen(data, itemId, isOpen) {
  const node = data[itemId];

  if (!node || node.isOpen === isOpen) {
    return data;
  }

  return {
    ...data,
    [itemId]: {
      ...node,
      isOpen,
    },
  };
}

function collapseNode(data, itemId) {
  return setNodeOpen(data, itemId, false);
}

function expandNode(data, itemId) {
  return setNodeOpen(data, itemId, true);
}

function dataReducer(data, action) {
  switch (action.type) {
    case 'toggle':
      return toggleNode(data, action.itemId);

    case 'expand':
      return expandNode(data, action.itemId);

    case 'collapse':
      return collapseNode(data, action.itemId);

    case 'refresh':
      return updateFromCapabilitySet(data, action.capabilitySet);

    default:
      return data;
  }
}

function addingCapabilityToReducer(state, action) {
  switch (action.type) {
    case 'set-adding-capability':
      return action.context;

    case 'clear-adding-capability':
      return null;

    default:
      return state;
  }
}

export function treeStateReducer(state, action) {
  return {
    data: dataReducer(state.data, action),
    lastAction: action,
    addingCapabilityTo: addingCapabilityToReducer(
      state.addingCapabilityTo,
      action
    ),
  };
}

export function findItem(state, itemId) {
  return state.data[itemId];
}
