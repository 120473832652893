import React, { useMemo } from 'react';
import { useResponseContext } from 'js/engines/CapabilitiesAssessment/ResponseContext';
import { useAssessmentContext } from 'js/engines/CapabilitiesAssessment/AssessmentContext';
import classNames from 'classnames';

import { Link } from 'react-router-dom';
import { getQuestionId } from 'js/engines/CapabilitiesAssessment/utils';

function capabilityReducer(selectedCapabilities, capability) {
  function reduceCapability(acc, c) {
    if (c.id in selectedCapabilities) {
      acc.push(c);
    }
    c.children.forEach((child) => {
      reduceCapability(acc, child, selectedCapabilities);
    });
    return acc;
  }

  return reduceCapability;
}

function CapabilityQuestionsPageIndicator({ className, page, assessment }) {
  const selectedCapabilities = page.selectedCapabilities;
  const { capabilitySets } = useAssessmentContext();
  const { questionMap, responses } = useResponseContext();

  const capabilitySet = useMemo(
    () =>
      capabilitySets[page.capabilitySetId] ?? {
        capabilities: [],
      },
    [capabilitySets, page.capabilitySetId]
  );

  const pageCapabilities = useMemo(() => {
    return capabilitySet.capabilities.reduce(
      capabilityReducer(selectedCapabilities),
      []
    );
  }, [capabilitySet, selectedCapabilities]);

  const questions = useMemo(() => {
    return page.capabilityElements.flatMap((capabilityElement) => {
      return pageCapabilities.map((capability) => {
        const questionId = getQuestionId(
          capabilityElement,
          capability,
          questionMap
        );
        const answered = !!responses[questionId];
        return { id: questionId, answered };
      });
    });
  }, [page, pageCapabilities, questionMap, responses]);

  const classes = useMemo(() => {
    return classNames('page-indicator', 'question-set', className);
  }, [className]);

  return (
    <Link
      key={page.guid}
      to={`./${page.guid}`}
      className={classes}
      style={{ flexGrow: questions.length }}
    >
      {questions.map(({ id, answered }) => {
        return (
          <span
            key={id}
            className={classNames('question', { answered })}
          ></span>
        );
      })}
    </Link>
  );
}

export default CapabilityQuestionsPageIndicator;
