import React, { useMemo, useReducer, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { useEditorContext } from 'js/ydoc/docs/capabilities-assessment/EditorContext';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import CapabilityTreeNode from './CapabilityTreeNode';
import { treeStateReducer, getInitialTreeState, findItem } from './tree';
import { TreeContext } from './TreeContext';
import { useSelectorContext } from './SelectorContext';

import styles from './CapabilitySelector.module.scss';
import { useStandardCollator } from 'js/utils/string';

function CapabilityTree() {
  const { capabilitySets } = useEditorContext();
  const { activeItem, navigateToItem, capabilitySet, setCapabilitySet } =
    useSelectorContext();

  const collator = useStandardCollator();
  const sortedCapabilitySets = useMemo(() => {
    return capabilitySets?.sort(
      (a, b) => collator.compare(a.name, b.name) || collator.compare(a.id, b.id)
    );
  }, [capabilitySets, collator]);

  const handleCapabilitySetChange = (event) => {
    setCapabilitySet(capabilitySets.find((cs) => cs.id === event.target.value));
  };
  const selectedCapabilitySet = capabilitySet;
  const [state, updateState] = useReducer(
    treeStateReducer,
    selectedCapabilitySet,
    getInitialTreeState
  );

  useEffect(() => {
    updateState({ type: 'refresh', capabilitySet: selectedCapabilitySet });
  }, [selectedCapabilitySet]);

  const findTreeItem = useCallback(
    (itemId) => {
      return findItem(state, itemId);
    },
    [state]
  );

  const navigateToSet = useCallback(() => {
    navigateToItem({ capabilitySet: selectedCapabilitySet, path: [] });
  }, [navigateToItem, selectedCapabilitySet]);

  const context = useMemo(
    () => ({
      dispatch: updateState,
      find: findTreeItem,
    }),
    [updateState, findTreeItem]
  );

  const path = useMemo(() => [selectedCapabilitySet], [selectedCapabilitySet]);

  return (
    <TreeContext.Provider value={context}>
      <div>
        <div
          className={classNames(styles['capability-set-selector-wrapper'], {
            [styles['active-item']]:
              activeItem?.capabilitySet?.id === selectedCapabilitySet?.id,
          })}
        >
          <div className={styles['capability-set-selector']}>
            <button
              className={styles['capability-set-name']}
              onClick={navigateToSet}
            >
              {selectedCapabilitySet?.name}
            </button>
            <UncontrolledDropdown className={styles['capability-set-dropdown']}>
              <DropdownToggle caret></DropdownToggle>
              <DropdownMenu container="body" className={styles['escape']}>
                {sortedCapabilitySets?.map((cs) => (
                  <DropdownItem
                    onClick={handleCapabilitySetChange}
                    key={cs.id}
                    value={cs.id}
                  >
                    {cs.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
        {selectedCapabilitySet && (
          <div className={styles['capability-tree']}>
            {selectedCapabilitySet.capabilities.map((c) => (
              <CapabilityTreeNode
                key={c.id}
                capability={c}
                path={path}
                depth={0}
              />
            ))}
          </div>
        )}
      </div>
    </TreeContext.Provider>
  );
}

export default CapabilityTree;
