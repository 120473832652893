import React from 'react';
import classNames from 'classnames';
import styles from './UserGroup.module.scss';

function StatusBadge({ assessment }) {
  const status = assessment.archived_state ?? assessment.status;

  return (
    <div className={classNames(styles['status-badge'], styles[status])}>
      {status}
    </div>
  );
}

export default StatusBadge;
