import React, { useId } from 'js/hooks/useId';
import ArrowMarker from 'js/components/Charts/Differential/ArrowMarker';

function MatrixPlot({ width = 100, height = 100, margin = {}, data = [] }) {
  margin = {
    top: 5,
    right: 5,
    bottom: 5,
    left: 5,
    ...margin,
  };

  const chartWidth = width - margin.left - margin.right;
  const chartHeight = height - margin.top - margin.bottom;
  const isValidData =
    data.length === 2 &&
    data.find((d) => !d.response || d.response.is_na) === undefined;
  const [x, y] = isValidData
    ? [...data].reverse().map((d, i) => (i === 0 ? d.score : 1 - d.score))
    : [0, 0];

  const arrowId = useId();

  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <defs>
        <ArrowMarker id={arrowId} refX={7} />
      </defs>

      <g transform={`translate(${margin.left}, ${margin.top})`}>
        <g>
          <line
            x1={0}
            y1={chartHeight / 2}
            x2={chartWidth}
            y2={chartHeight / 2}
            className="chart-axis chart-grid"
          />
          <line
            x1={chartWidth / 2}
            y1={0}
            x2={chartWidth / 2}
            y2={chartHeight}
            className="chart-axis chart-grid"
          />
          {!isValidData && (
            <rect
              x={0}
              y={0}
              width={chartWidth}
              height={chartHeight}
              className="no-response-fill"
              opacity={0.5}
            />
          )}
          <rect
            width={chartWidth}
            height={chartHeight}
            fill="transparent"
            className="chart-axis"
          />
          <g className="palette-chart-2">
            <line
              x1={0}
              y1={chartHeight}
              x2={0}
              y2={0}
              className="chart-axis palette-stroke palette-fill"
              markerEnd={`url(#${arrowId})`}
            />
          </g>
          <g className="palette-chart-3">
            <line
              x1={0}
              y1={chartHeight}
              x2={chartWidth}
              y2={chartHeight}
              className="chart-axis palette-stroke palette-fill"
              markerEnd={`url(#${arrowId})`}
            />
          </g>
        </g>
        {isValidData && (
          <circle
            cx={x * chartWidth}
            cy={y * chartHeight}
            r={5}
            className="matrix-plot-point"
          />
        )}
      </g>
    </svg>
  );
}

export default MatrixPlot;
