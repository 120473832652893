import React from 'react';
import { useEditorContext } from 'js/ydoc/docs/capabilities-assessment/EditorContext';
import { convertToAssessment } from 'js/ydoc/docs/capabilities-assessment/convert';
import CapabilitiesAssessmentEngine from 'js/engines/CapabilitiesAssessment';
import { useParams, Link } from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { Icon } from 'js/components';
export function PreviewInterface() {
  const { Preview } = CapabilitiesAssessmentEngine;
  const { assessment: assessmentDoc, userGroupAssessment } = useEditorContext();
  const assessment = assessmentDoc ? convertToAssessment(assessmentDoc) : null;

  const { workspaceId, userGroupId, assessmentId } = useParams();
  const backNavigation = (
    <>
      <Icon icon={faChevronLeft} className="icon-left" />
      <Link
        to={`/w/${workspaceId}/pm/user-groups/${userGroupId}/assessments/${assessmentId}/customize`}
      >
        Back to editor
      </Link>
    </>
  );

  if (!assessment) {
    return <div>Loading...</div>;
  }

  return (
    <Preview
      assessment={assessment}
      userGroupAssessment={userGroupAssessment}
      backNavigation={backNavigation}
    />
  );
}

export default PreviewInterface;
