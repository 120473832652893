import React from 'react';
import { PanelHeader } from 'js/components';
import { useParams } from 'react-router-dom';
import { Page } from './Page';
import AnswerSets from './AnswerSets';
import ProgressSlider from './ProgressSlider';
import RunNavigation from './RunNavigation';

function AssessmentRun({ participant, assessment, backNavigation }) {
  const { pageId } = useParams();
  const page = assessment.document.pages.find((p) => p.guid === pageId);
  const currentPageIndex = assessment.document.pages.findIndex(
    (p) => p.guid === pageId
  );

  // console.log('🚛 assessment', assessment);

  return (
    <div>
      <PanelHeader size="sm" />
      <div className="content">
        <div className="assessment survey-assessment-run">
          {backNavigation && (
            <div className="survey-assessment-back-nav">{backNavigation}</div>
          )}
          {assessment.document.pages.length > 1 ? (
            <ProgressSlider
              assessment={assessment}
              participantId={participant?.id}
              currentPageIndex={currentPageIndex}
            />
          ) : null}
          <Page page={page} />
          <AnswerSets answerSets={assessment.document.answerSets} />
          <RunNavigation
            pages={assessment.document.pages}
            pageIndex={currentPageIndex}
          />
        </div>
      </div>
    </div>
  );
}

export default AssessmentRun;
