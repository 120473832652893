import { schema } from 'normalizr';
import { parseDate, parseDateToDate } from 'js/utils/model';

const parseTimestampsProcessStrategy = (value) => {
  return {
    ...value,
    inserted_at: parseDate(value.inserted_at),
    updated_at: parseDate(value.updated_at),
  };
};

const accountUserSchema = new schema.Entity(
  'accountUsers',
  {},
  { idAttribute: (value) => `${value.account_id}-${value.email}` }
);

const userSchema = new schema.Entity('users');
const accountSchema = new schema.Entity('accounts');
const workspaceSchema = new schema.Entity('workspaces', {
  account: accountSchema,
});
const workspaceUserSchema = new schema.Entity(
  'workspaceUsers',
  { user: userSchema },
  {
    idAttribute: (value) => `${value.workspace_id}-${value.email}`,
  }
);
const assessmentInviteSchema = new schema.Entity(
  'assessmentInvites',
  {},
  {
    idAttribute: (value) => `${value.assessment_id}-${value.token}`,
  }
);

const assessmentTemplateSchema = new schema.Entity(
  'assessmentTemplates',
  {},
  {
    idAttribute: (value) => `${value.assessment_template_id}-${value.revision}`,
  }
);

const assessmentDocTemplateSchema = new schema.Entity(
  'assessmentDocTemplates',
  {},
  {
    processStrategy: (value) => {
      return {
        ...value,
        archived_at: parseDateToDate(value.archived_at),
        inserted_at: parseDateToDate(value.inserted_at),
        updated_at: parseDateToDate(value.updated_at),
      };
    },
  }
);
const assessmentDocTemplateRevisionSchema = new schema.Entity(
  'assessmentDocTemplateRevisions',
  {},
  {
    processStrategy: (value) => {
      return {
        ...value,
        made_public_at: parseDateToDate(value.made_public_at),
        published_at: parseDateToDate(value.published_at),
        inserted_at: parseDateToDate(value.inserted_at),
        updated_at: parseDateToDate(value.updated_at),
      };
    },
  }
);

const assessmentDocTemplateSubscriptionSchema = new schema.Entity(
  'assessmentDocTemplateSubscriptions',
  {},
  {
    processStrategy: (value) => {
      return {
        ...value,
        made_public_at: parseDateToDate(value.made_public_at),
        published_at: parseDateToDate(value.published_at),
        inserted_at: parseDateToDate(value.inserted_at),
        updated_at: parseDateToDate(value.updated_at),
      };
    },
  }
);

const assessmentDocTemplateSummarySchema = new schema.Entity(
  'assessmentDocTemplateSummaries',
  {},
  {
    processStrategy: (value) => {
      return {
        ...value,
        doc_updated_at: parseDateToDate(value.doc_updated_at),
        latest_revision_published_at: parseDateToDate(
          value.latest_revision_published_at
        ),
        inserted_at: parseDateToDate(value.inserted_at),
        updated_at: parseDateToDate(value.updated_at),
        archived_at: parseDateToDate(value.archived_at),
      };
    },
  }
);

const meSchema = new schema.Entity('users', {
  accounts: new schema.Array(accountSchema),
  workspaces: new schema.Array(workspaceSchema),
});
const goalSchema = new schema.Entity('goals');
const teamSchema = new schema.Entity('teams', {
  goals: new schema.Array(goalSchema),
});
const favoriteTeamSchema = new schema.Entity(
  'favoriteTeams',
  {},
  { idAttribute: 'team_id' }
);

const organizationSchema = new schema.Entity('organizations');
organizationSchema.define({
  child_orgs: new schema.Array(organizationSchema),
  teams: new schema.Array(teamSchema),
});

const companySchema = new schema.Entity('companies');

const answerSchema = new schema.Entity('answers');
const responseSchema = new schema.Entity(
  'responses',
  {},
  { processStrategy: parseTimestampsProcessStrategy }
);
const questionSchema = new schema.Entity(
  'questions',
  {
    answers: new schema.Array(answerSchema),
    responses: new schema.Array(responseSchema),
  },
  {
    processStrategy: (value) => {
      return {
        ...value,
        reveal_results_on: parseDate(value.reveal_results_on),
      };
    },
  }
);
const questionSetSchema = new schema.Entity('questionSets', {
  questions: new schema.Array(questionSchema),
});
const assessmentSchema = new schema.Entity(
  'assessments',
  {
    question_sets: new schema.Array(questionSetSchema),
    participants: new schema.Array(userSchema),
  },
  { processStrategy: parseTimestampsProcessStrategy }
);
const assessmentMetaSchema = new schema.Entity('assessmentMetas');
const liveAssessmentSchema = new schema.Entity(
  'liveAssessments',
  {},
  {
    idAttribute: 'assessment_id',
  }
);
const passphraseSchema = new schema.Entity(
  'passphrases',
  {},
  {
    idAttribute: 'assessment_id',
  }
);

const productSchema = new schema.Entity('products');

const capabilitySchema = new schema.Entity(
  'capabilities',
  {},
  {
    processStrategy: (value) => {
      return {
        ...value,
        archived_at: parseDateToDate(value.archived_at),
        inserted_at: parseDateToDate(value.inserted_at),
        updated_at: parseDateToDate(value.updated_at),
      };
    },
  }
);

// Self-referential schema for capability tree structure
capabilitySchema.define({
  children: new schema.Array(capabilitySchema),
});

const capabilitySetSchema = new schema.Entity(
  'capabilitySets',
  {
    capabilities: new schema.Array(capabilitySchema),
  },
  {
    processStrategy: (value) => {
      return {
        ...value,
        archived_at: parseDateToDate(value.archived_at),
        inserted_at: parseDateToDate(value.inserted_at),
        updated_at: parseDateToDate(value.updated_at),
      };
    },
  }
);

const userGroupSchema = new schema.Entity(
  'userGroups',
  {},
  {
    processStrategy: (value) => {
      return {
        ...value,
        archived_at: parseDateToDate(value.archived_at),
        inserted_at: parseDateToDate(value.inserted_at),
        updated_at: parseDateToDate(value.updated_at),
      };
    },
  }
);

const userGroupAssessmentSchema = new schema.Entity(
  'userGroupAssessments',
  {
    user_group: userGroupSchema,
    assessment: assessmentSchema,
  },
  {
    processStrategy: (value) => {
      return {
        ...value,
        archived_at: parseDateToDate(value.archived_at),
        published_at: parseDateToDate(value.published_at),
        go_live_at: parseDateToDate(value.go_live_at),
        went_live_at: parseDateToDate(value.went_live_at),
        close_at: parseDateToDate(value.close_at),
        closed_at: parseDateToDate(value.closed_at),
        processed_at: parseDateToDate(value.processed_at),
        retry_processing_after: parseDateToDate(value.retry_processing_after),
        inserted_at: parseDateToDate(value.inserted_at),
        updated_at: parseDateToDate(value.updated_at),
      };
    },
  }
);

function spread(entityKey, entitySchema) {
  return {
    [entityKey]: entitySchema,
    [`${entityKey}_ARRAY`]: new schema.Array(entitySchema),
  };
}

export const SCHEMAS = {
  ...spread('TEAM', teamSchema),
  ...spread('FAVORITE_TEAM', favoriteTeamSchema),
  ...spread('GOAL', goalSchema),
  ...spread('COMPANY', companySchema),
  ...spread('ASSESSMENT', assessmentSchema),
  ...spread('ASSESSMENT_META', assessmentMetaSchema),
  ...spread('ASSESSMENT_TEMPLATE', assessmentTemplateSchema),
  ...spread('ASSESSMENT_DOC_TEMPLATE', assessmentDocTemplateSchema),
  ...spread(
    'ASSESSMENT_DOC_TEMPLATE_SUMMARY',
    assessmentDocTemplateSummarySchema
  ),
  ...spread(
    'ASSESSMENT_DOC_TEMPLATE_SUBSCRIPTION',
    assessmentDocTemplateSubscriptionSchema
  ),
  ...spread(
    'ASSESSMENT_DOC_TEMPLATE_REVISION',
    assessmentDocTemplateRevisionSchema
  ),
  ...spread('LIVE_ASSESSMENT', liveAssessmentSchema),
  ...spread('QUESTION_SET', questionSetSchema),
  ...spread('QUESTION', questionSchema),
  ...spread('ANSWER', answerSchema),
  ...spread('RESPONSE', responseSchema),
  ...spread('USER', userSchema),
  ...spread('WORKSPACE_USER', workspaceUserSchema),
  ...spread('PASSPHRASE', passphraseSchema),
  ...spread('WORKSPACE', workspaceSchema),
  ...spread('ACCOUNT', accountSchema),
  ...spread('ACCOUNT_USER', accountUserSchema),
  ...spread('ASSESSMENT_INVITES', assessmentInviteSchema),
  ...spread('ORGANIZATION', organizationSchema),
  ...spread('PRODUCT', productSchema),
  ...spread('CAPABILITY', capabilitySchema),
  ...spread('CAPABILITY_SET', capabilitySetSchema),
  ...spread('USER_GROUP', userGroupSchema),
  ...spread('USER_GROUP_ASSESSMENT', userGroupAssessmentSchema),
  ME: meSchema,
};
