import { isValidAnswerSetTypeForQuestion } from 'js/components/Assessment/Editor/answer-set-utils';

// Question schemas
const capabilityQuestionSchema = {
  type: 'object',
  required: ['text', 'key', 'type', 'guid', 'name', 'answerSetId'],
  properties: {
    text: { type: 'string' },
    key: { type: 'string' },
    type: { type: 'string' },
    guid: { type: 'string' },
    name: { type: 'string' },
    answerSetId: { type: 'string' },
  },
};

const textQuestionSchema = {
  type: 'object',
  required: ['text', 'key', 'type'],
  properties: {
    text: {
      type: 'string',
      minLength: 1,
      errorMessage: 'Question text is required',
    },
    key: {
      type: 'string',
      minLength: 1,
      errorMessage: 'A unique key is required',
    },
    type: { enum: ['long-text'] },
  },
};

const multipleChoiceQuestionSchema = {
  type: 'object',
  required: ['text', 'key', 'type'],
  hasChoices: true,
  properties: {
    text: {
      type: 'string',
      minLength: 1,
      errorMessage: 'Question text is required',
    },
    key: {
      type: 'string',
      minLength: 1,
      errorMessage: 'A unique key is required',
    },
    type: {
      enum: ['likert', 'unscored-likert', 'multi-select', 'single-select'],
      errorMessage: 'Did not recognize question type ${0}', // eslint-disable-line no-template-curly-in-string
    },
    answerSetId: { type: 'string' },
    // NOTE: disabling for now because the "hasChoices" keyword covers basic answer set validation
    // answerSet: {
    //   type: 'object',
    //   required: ['type', 'choices'],
    //   properties: {
    //     type: { type: 'string' },
    //     choices: { type: 'object' },
    //   },
    // },
  },
  errorMessage: {
    hasChoices: 'At least one option is required',
  },
};

// Element schema with if/then conditions
const elementSchema = {
  type: 'object',
  required: ['guid', 'elementType'],
  properties: {
    guid: { type: 'string' },
    elementType: { type: 'string' },
  },
  allOf: [
    {
      if: { properties: { elementType: { const: 'text' } } },
      then: {
        required: ['content'],
        properties: {
          content: {
            type: 'string',
            minLength: 1,
            errorMessage: 'Content is required',
          },
        },
      },
    },
    {
      if: { properties: { elementType: { const: 'question' } } },
      then: {
        required: ['type'],
        properties: { type: { type: 'string' } },
        allOf: [
          {
            if: { properties: { type: { enum: ['long-text'] } } },
            then: textQuestionSchema,
          },
          {
            if: {
              properties: {
                type: {
                  enum: [
                    'likert',
                    'unscored-likert',
                    'multi-select',
                    'single-select',
                  ],
                },
              },
            },
            then: multipleChoiceQuestionSchema,
          },
        ],
      },
    },
  ],
};

// Page schema with if/then conditions
const pageSchema = {
  type: 'object',
  required: ['guid', 'type'],
  properties: {
    guid: { type: 'string' },
    pageType: { type: 'string' },
  },
  allOf: [
    {
      if: {
        properties: { type: { const: 'info' } },
      },
      then: {
        required: ['title'],
        properties: {
          title: {
            type: 'string',
            minLength: 1,
            errorMessage: 'Title is required',
          },
        },
      },
    },
    {
      if: { properties: { type: { const: 'capability-questions' } } },
      then: {
        required: [
          'guid',
          'title',
          'description',
          'capabilityElements',
          'elements',
          'selectedCapabilities',
        ],
        hasCapabilities: true,
        properties: {
          capabilityElements: {
            type: 'array',
            items: capabilityQuestionSchema,
            minItems: 1,
            errorMessage: {
              minItems: 'At least one capability question must be included',
            },
          },
          elements: {
            type: 'array',
            items: elementSchema,
          },
        },
        errorMessage: {
          hasCapabilities: 'At least one capability must be selected',
        },
      },
    },
    {
      if: { properties: { type: { const: 'question-set' } } },
      then: {
        required: ['name', 'key', 'elements'],
        properties: {
          name: {
            type: 'string',
            minLength: 1,
            errorMessage: 'Name is required',
          },
          key: {
            type: 'string',
            minLength: 1,
            errorMessage: 'A unique key is required',
          },
          elements: {
            type: 'array',
            items: elementSchema,
          },
        },
      },
    },
  ],
};

// Main assessment schema
export const assessmentSchema = {
  type: 'object',
  required: ['pages'],
  properties: {
    // meta: {
    //   type: 'object',
    //   required: ['type', 'key', 'name', 'description'],
    //   properties: {
    //     type: {
    //       type: 'string',
    //       enum: ['survey'],
    //       errorMessage: 'Unrecognized assessment type ${0}', // eslint-disable-line no-template-curly-in-string
    //     },
    //     key: { type: 'string', minLength: 1, errorMessage: 'Key is required' },
    //     name: {
    //       type: 'string',
    //       minLength: 1,
    //       errorMessage: 'Name is required',
    //     },
    //     description: {
    //       type: 'string',
    //       minLength: 1,
    //       errorMessage: 'Description is required',
    //     },
    //   },
    // },
    pages: {
      type: 'array',
      items: pageSchema,
    },
  },
};

// Custom keywords for Ajv
export const customKeywords = {
  hasChoices: {
    keyword: 'hasChoices',
    type: 'object',
    validate(schema, data) {
      // const { data, schema } = ctx;
      const { answerSet, type: questionType } = data;

      let choiceCount = 0;
      if (
        answerSet != null &&
        isValidAnswerSetTypeForQuestion(questionType, answerSet.type)
      ) {
        choiceCount = Object.keys(answerSet?.choices ?? {}).length;
      }

      return choiceCount > 0;

      // ctx.fail(_`${choiceCount} === 0`);

      // let choices = 0;
      // if (answerSet == null ||
      //   !isValidAnswerSetTypeForQuestion(questionType, answerSet.type)
      // ) {
      //   ctx.errors.push({ message: 'At least one option is required' });
      //   return false;
      // } else {
      //   const choices = answerSet.choices ?? {};
      //   if (Object.keys(choices).length === 0) {
      //     ctx.errors.push({ message: 'At least one option is required' });
      //     return false;
      //   }
      // }

      // return true;

      // if (ctx.data.answerSetId && ctx.data.answerSet) {
      //   const validTypes = validAnswerSetTypes[ctx.data.type] || [];
      //   const isValid = validTypes.includes(ctx.data.answerSet.type);
      //   if (!isValid) {
      //     ctx.errors.push({
      //       message: `Invalid answer set type for question type ${ctx.data.type}`,
      //     });
      //   }
      // }
    },
    metaSchema: {
      const: true,
    },
  },
  hasCapabilities: {
    keyword: 'hasCapabilities',
    type: 'object',
    schemaType: 'boolean',
    // $data: true, // to support [$data reference](./guide/combining-schemas.md#data-reference), ...
    // code(cxt) {
    //   // console.log('🏴‍☠️ args', a, b, c, d);
    //   const { data, schema } = cxt;
    //   console.log('🏴‍☠️ cxt', cxt, data, schema);
    //   // const op = schema ? _`!==` : _`===`;
    //   // cxt.fail(_`${data} %2 ${op} 0`); // ... the only code change needed is to use `cxt.fail$data` here
    // },
    validate(schema, data) {
      console.log('🏴‍☠️ schema', schema);
      console.log('🏴‍☠️ data', data);
      const { selectedCapabilities, capabilitySetId } = data;
      if (capabilitySetId) {
        return Object.values(selectedCapabilities).includes(capabilitySetId);
      }
      return false;
    },
  },
  // validateAnswerSetType: {
  //   keyword: 'validateAnswerSetType',
  //   type: 'object',
  //   validate: function validate(schema, data) {
  //     if (data.answerSetId && data.answerSet && data.type) {
  //       const validTypes = validAnswerSetTypes[data.type] || [];
  //       const isValid = validTypes.includes(data.answerSet.type);

  //       if (!isValid) {
  //         validate.errors = [
  //           {
  //             keyword: 'validateAnswerSetType',
  //             message: `Invalid answer set type for question type ${data.type}`,
  //             params: {
  //               questionType: data.type,
  //               answerSetType: data.answerSet.type,
  //             },
  //           },
  //         ];
  //       }
  //       return isValid;
  //     }
  //     return true;
  //   },
  // },
};
