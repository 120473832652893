import {
  SchemaSet,
  SchemaPickedSet,
  SchemaSingletonEntity,
  SchemaExclusiveArray,
  SchemaArray,
  SchemaEntity,
  SchemaItem,
} from './entities';

function buildGraph(jsonSchema) {
  const root = getNode(jsonSchema);

  return root;
}

function getNode(schemaItem, path = [], inStack = undefined) {
  if (inStack?.has(schemaItem)) {
    throw new Error('Cycle detected in dependency graph');
  }

  // Using a new map for each layer is more expensive than other approaches but
  // works fine for small dependency graphs like we expect to have here
  const visited = new Map(inStack);
  visited.set(schemaItem, null);

  let children = [];

  switch (true) {
    case schemaItem instanceof SchemaSingletonEntity:
    case schemaItem instanceof SchemaEntity:
      children =
        Object.entries(schemaItem.definition).map(([key, child]) =>
          getNode(child, [...path, key], visited)
        ) ?? [];
      break;

    case schemaItem instanceof SchemaPickedSet:
    case schemaItem instanceof SchemaSet:
    case schemaItem instanceof SchemaExclusiveArray:
    case schemaItem instanceof SchemaArray:
      // HACK: THIS IS NOT THE RIGHT PATH
      children = [getNode(schemaItem.entity, [...path, null], visited)];
      break;

    default:
      children = [];
      break;
  }

  return { entity: schemaItem, children, path };
}

function DFS(node, stack = [], visited = new Map()) {
  visited.set(node.entity, true);
  node.children.forEach((childNode) => {
    if (!visited.has(childNode.entity)) {
      DFS(childNode, stack, visited);
    }
  });
  stack.push(node);

  return stack;
}

export class Schema {
  constructor(jsonSchema, ydoc) {
    this.rootEntity = jsonSchema;
    this.graph = buildGraph(jsonSchema);
    this.updateSequence = DFS(this.graph);
  }

  findItemByKey(key) {
    return this.updateSequence.find((x) => x.entity.key === key)?.entity;
  }
}

export {
  SchemaEntity,
  SchemaExclusiveArray,
  SchemaArray,
  SchemaItem,
  SchemaSet,
  SchemaPickedSet,
  SchemaSingletonEntity,
};
