import React, { useMemo } from 'react';
import { Icon } from '..';
import { Link } from 'react-router-dom';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';

export function Breadcrumb({ label, to }) {
  return (
    <span className="wp-breadcrumb">
      <span className="wp-breadcrumb-label">
        {to ? <Link to={to}>{label}</Link> : label}
      </span>
      <span className="wp-breadcrumb-separator">
        <Icon size="sm" icon={faChevronRight} />
      </span>
    </span>
  );
}

export function Breadcrumbs({ breadcrumbs }) {
  const reversedBreadcrumbs = useMemo(
    () => [...breadcrumbs].reverse(),
    [breadcrumbs]
  );

  return (
    <span className="wp-breadcrumbs-container">
      <span className="wp-breadcrumbs">
        {reversedBreadcrumbs.map((b, i) => (
          <Breadcrumb key={i} label={b.label} to={b.to} />
        ))}
      </span>
    </span>
  );
}
