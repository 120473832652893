import React, { useEffect } from 'react';
import engine from 'js/engines/CapabilitiesAssessment';
import { useParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { denormalize } from 'normalizr';
import { SCHEMAS } from 'js/api/schemas';
import { load as loadUserGroupAssessment } from 'js/actions/user-group-assessment-actions';

const { Review } = engine;

function AssessmentReview({ loadUserGroupAssessment }) {
  const { userGroupId, assessmentId } = useParams();

  const userGroupAssessment = useSelector((state) => {
    const normalizedUserGroupAssessment =
      state.entities.userGroupAssessments[assessmentId];

    return normalizedUserGroupAssessment
      ? denormalize(
          normalizedUserGroupAssessment,
          SCHEMAS.USER_GROUP_ASSESSMENT,
          state.entities
        )
      : undefined;
  });

  useEffect(() => {
    loadUserGroupAssessment(userGroupId, assessmentId);
  }, [assessmentId, loadUserGroupAssessment, userGroupId]);

  return (
    <Review
      userGroupAssessment={userGroupAssessment}
      assessment={userGroupAssessment?.assessment}
    />
  );
}

export default connect(null, { loadUserGroupAssessment })(AssessmentReview);
