import { createContext, useContext, useMemo } from 'react';
import { standardCollator } from 'js/utils/string';
import { scaleLinear } from '@visx/scale';
import { getAnswerDomain } from 'js/utils/scoring';

function denormalizeCapability(capability, capabilityCache, path = []) {
  const denormalizedCapability = {
    ...capability,
    path,
  };

  denormalizedCapability.children = Object.keys(capability.children)
    .map((childId) =>
      denormalizeCapability(capabilityCache[childId], capabilityCache, [
        ...path,
        denormalizedCapability,
      ])
    )
    .sort(
      (a, b) =>
        standardCollator.compare(a.name, b.name) ||
        standardCollator.compare(a.id, b.id)
    );

  return denormalizedCapability;
}

function buildCapabilitySetsFromCache(capabilitySetCache, capabilityCache) {
  return Object.fromEntries(
    Object.entries(capabilitySetCache).map(([id, cs]) => [
      id,
      {
        ...cs,
        capabilities: Object.keys(cs.capabilities)
          .map((cKey) =>
            denormalizeCapability(capabilityCache[cKey], capabilityCache, [])
          )
          .sort(
            (a, b) =>
              standardCollator.compare(a.name, b.name) ||
              standardCollator.compare(a.id, b.id)
          ),
      },
    ])
  );
}

export const AssessmentContext = createContext({
  capabilitySets: {},
  capabilities: {},
  assessment: null,
  answerSets: {},
});

export function AssessmentProvider({ assessment, children }) {
  const capabilitySets = useMemo(() => {
    if (assessment?.document?.capabilitySetCache) {
      return buildCapabilitySetsFromCache(
        assessment.document.capabilitySetCache,
        assessment.document.capabilityCache
      );
    }
  }, [
    assessment?.document?.capabilitySetCache,
    assessment?.document?.capabilityCache,
  ]);

  const answerSets = useMemo(() => {
    if (assessment?.document?.answerSets) {
      return Object.fromEntries(
        Object.entries(assessment.document.answerSets).map(([id, as]) => {
          const choices = Object.values(as.choices).sort(
            (a, b) =>
              standardCollator.compare(a.label, b.label) ||
              standardCollator.compare(a.guid, b.guid)
          );

          return [
            id,
            {
              ...as,
              choices,
              scale: scaleLinear({
                domain: getAnswerDomain(choices),
                range: [0, 1],
              }),
            },
          ];
        })
      );
    }
  }, [assessment?.document?.answerSets]);

  const context = useMemo(() => {
    return {
      capabilitySets,
      capabilities: assessment.document.capabilityCache,
      assessment,
      answerSets,
    };
  }, [capabilitySets, assessment, answerSets]);

  return (
    <AssessmentContext.Provider value={context}>
      {children}
    </AssessmentContext.Provider>
  );
}

export function useAssessmentContext() {
  return useContext(AssessmentContext);
}
