import React, { useMemo } from 'react';
import { faEmptySet } from '@fortawesome/pro-regular-svg-icons';

function DeviationPlot({
  height = 100,
  barWidth = 20,
  gap = 10,
  minimumBarHeight = 4,
  margin = {},
  data = [],
  leftGutter = 15,
}) {
  margin = {
    top: 5,
    right: 5,
    bottom: 5,
    left: 5,
    ...margin,
  };
  const iconSize = 9;
  const chartWidth = data.length * (barWidth + gap) + gap + leftGutter;
  const width = chartWidth + margin.left + margin.right;
  const chartHeight = height - margin.top - margin.bottom;

  const naIconPosition = useMemo(() => {
    const [defaultIconWidth, defaultIconHeight, , , svgPathData] =
      faEmptySet.icon;
    const svgHeight = iconSize;
    const svgWidth = (svgHeight / defaultIconHeight) * defaultIconWidth;

    return {
      svgWidth,
      svgHeight,
      defaultIconWidth,
      defaultIconHeight,
      svgPathData,
    };
  }, [iconSize]);

  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <g transform={`translate(${margin.left}, ${margin.top})`}>
        <g></g>
        <g transform={`translate(${leftGutter}, 0)`}>
          {data.map((d, index) => {
            const itemX = index * (barWidth + gap) + gap;

            if (!d.response) {
              return (
                <g
                  key={index}
                  transform={`translate(${
                    itemX + (barWidth - naIconPosition.svgWidth) / 2
                  }, ${chartHeight / 2 - naIconPosition.svgHeight - 3.5})`}
                >
                  <svg
                    width={naIconPosition.svgWidth}
                    height={naIconPosition.svgHeight}
                    viewBox={`0 0 ${naIconPosition.defaultIconWidth} ${naIconPosition.defaultIconHeight}`}
                    className="no-response-text"
                  >
                    <path d={naIconPosition.svgPathData} />
                  </svg>
                </g>
              );
            } else if (d.response.is_na) {
              return (
                <g
                  key={index}
                  transform={`translate(${itemX + barWidth / 2}, ${
                    chartHeight / 2 - 4
                  })`}
                >
                  <text
                    x={0}
                    y={0}
                    className="na-response-text"
                    textAnchor="middle"
                  >
                    N/A
                  </text>
                </g>
              );
            }

            const relativeValue = d.score - 0.5;
            // const relativeValue = 0.1;
            const itemHeight = Math.abs(relativeValue) * chartHeight;
            if (itemHeight < minimumBarHeight) {
              const alternateY =
                (1 - d.score) * chartHeight - minimumBarHeight / 2;
              return (
                <g key={index} className={`palette-chart-${index + 2}`}>
                  <rect
                    key={index}
                    width={barWidth}
                    height={minimumBarHeight}
                    y={alternateY}
                    x={itemX}
                    className={`palette-fill`}
                  />
                </g>
              );
            }
            const itemY =
              relativeValue > 0
                ? chartHeight / 2 - itemHeight
                : chartHeight / 2;
            // const itemY = height / 2;

            return (
              <g key={index} className={`palette-chart-${index + 2}`}>
                <rect
                  key={index}
                  width={barWidth}
                  height={itemHeight}
                  y={itemY}
                  x={itemX}
                  className={`palette-fill`}
                />
              </g>
            );
          })}
        </g>
        <g>
          <line
            x1={0}
            y1={chartHeight / 2}
            x2={chartWidth}
            y2={chartHeight / 2}
            className="chart-median chart-light"
          />
          <line
            x1={0}
            y1={chartHeight}
            x2={chartWidth}
            y2={chartHeight}
            className="chart-axis chart-light"
          />
          <line
            x1={0}
            y1={0}
            x2={chartWidth}
            y2={0}
            className="chart-axis chart-light"
          />
          <text x={0} y={chartHeight - 2} className="chart-axis-label">
            Min
          </text>
          <text x={0} y={2} className="chart-axis-label hanging">
            Max
          </text>
        </g>
      </g>
    </svg>
  );
}

export default DeviationPlot;
