import React, { useCallback } from 'react';
import { Card, CardBody, Button, CardHeader, CardTitle } from 'reactstrap';
import { Icon } from 'js/components';
import { Link, useParams } from 'react-router-dom';

function CapabilitySetCard({
  capabilitySet,
  onArchive = () => {},
  onRestore = () => {},
}) {
  const { workspaceId } = useParams();

  const handleArchive = useCallback(() => {
    onArchive(capabilitySet);
  }, [onArchive, capabilitySet]);

  const handleRestore = useCallback(() => {
    onRestore(capabilitySet);
  }, [onRestore, capabilitySet]);

  return (
    <Card key={capabilitySet.id}>
      <CardHeader className="d-flex justify-content-between">
        <CardTitle tag="h5">
          <Link to={`/w/${workspaceId}/pm/capability-sets/${capabilitySet.id}`}>
            {capabilitySet.name}
          </Link>
        </CardTitle>
        <div className="actions">
          {capabilitySet.archived_at > 0 && (
            <Button
              color="secondary"
              size="sm"
              className="btn-icon"
              onClick={handleRestore}
            >
              <Icon icon="box-open" />
            </Button>
          )}
          {!capabilitySet.archived_at && (
            <Button
              color="secondary"
              size="sm"
              className="btn-icon"
              onClick={handleArchive}
            >
              <Icon icon="archive" />
            </Button>
          )}
        </div>
      </CardHeader>
      <CardBody>
        <p>{capabilitySet.description}</p>
      </CardBody>
    </Card>
  );
}

export default CapabilitySetCard;
