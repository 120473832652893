import React, { useCallback } from 'react';
import StatusBadge from '../StatusBadge';
import { Link, useParams } from 'react-router-dom';
import { Icon } from 'js/components';
import classNames from 'classnames';
import { format } from 'date-fns';
import copy from 'copy-to-clipboard';
import { useNotify } from 'js/hooks/useNotify';

import styles from '../UserGroup.module.scss';
import {
  faArchive,
  faCopy,
  faEdit,
  faBoxOpen,
} from '@fortawesome/pro-solid-svg-icons';

function AssessmentSummary({ assessment, onUpdateAssessment }) {
  const { workspaceId, userGroupId } = useParams();
  const runUrl = `${window.location.origin}/assessments/${assessment.assessment_id}/run`;
  const notify = useNotify();

  const handleCopyLink = useCallback(() => {
    copy(runUrl);

    notify({ type: 'success', message: 'Link copied to clipboard' });
  }, [runUrl, notify]);

  const handleArchiveAssessment = useCallback(() => {
    onUpdateAssessment(assessment.id, {
      archived_state: 'archived',
    });
  }, [onUpdateAssessment, assessment.id]);

  const handleRestoreAssessment = useCallback(() => {
    onUpdateAssessment(assessment.id, {
      archived_state: null,
    });
  }, [onUpdateAssessment, assessment.id]);

  const assessmentDate = format(
    new Date(
      assessment.went_live_at ??
        assessment.published_at ??
        assessment.inserted_at
    ),
    'MMM d, yyyy'
  );

  return (
    <div
      className={classNames(
        'mb-3',
        styles['assessment-summary'],
        styles[assessment.archived_state ?? assessment.status]
      )}
    >
      <div className={styles['assessment-summary-status-bar']}></div>
      <div className={styles['assessment-summary-content']}>
        <div className={styles['assessment-summary-header']}>
          <div className={styles['assessment-summary-status']}>
            <StatusBadge assessment={assessment} />
          </div>
          <div className={styles['assessment-summary-date']}>
            {assessment.archived_state === null && assessment.went_live_at ? (
              <Link
                to={`/w/${workspaceId}/pm/user-groups/${userGroupId}/assessments/${assessment.id}/review`}
              >
                {assessmentDate}
              </Link>
            ) : (
              assessmentDate
            )}
          </div>
          <div className={styles['assessment-user-group-name']}>
            {assessment.user_group?.name}
          </div>
        </div>
        <div className={styles['assessment-summary-details']}></div>
        {/* {JSON.stringify(assessment)} */}
        <div className={styles['assessment-summary-actions']}>
          {assessment.archived_state === null && (
            <Link
              className="btn btn-inline btn-outline-info"
              to={`/w/${workspaceId}/pm/user-groups/${userGroupId}/assessments/${assessment.id}/customize`}
            >
              <Icon icon={faEdit} className="icon-left" /> Edit
            </Link>
          )}
          {assessment.archived_state === null && assessment.status === 'live' && (
            <button
              className="btn btn-inline btn-outline-info"
              onClick={handleCopyLink}
            >
              <Icon icon={faCopy} className="icon-left" /> Copy link
            </button>
          )}
          {assessment.archived_state === null && (
            <button
              className="btn btn-inline btn-outline-info"
              onClick={handleArchiveAssessment}
            >
              <Icon icon={faArchive} className="icon-left" /> Archive
            </button>
          )}
          {assessment.archived_state === 'archived' && (
            <button
              className="btn btn-inline btn-outline-info"
              onClick={handleRestoreAssessment}
            >
              <Icon icon={faBoxOpen} className="icon-left" /> Restore
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default AssessmentSummary;
