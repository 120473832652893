import React from 'react';
import { Card, CardBody } from 'reactstrap';
import UserGroupForm from '../UserGroupForm';

function UserGroupSettings({ userGroup, onUpdate, cancelUrl }) {
  return (
    <Card>
      <CardBody>
        <UserGroupForm
          submitLabel="Update"
          userGroup={userGroup}
          onSubmit={onUpdate}
          cancelUrl={cancelUrl}
        />
      </CardBody>
    </Card>
  );
}

export default UserGroupSettings;
