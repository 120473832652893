import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useSelector, useStore } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import { denormalize } from 'normalizr';
import {
  listCapabilitySets,
  updateCapabilitySet,
} from 'js/actions/capability-actions';
import CapabilitySetsDashboardComponent from '../components/CapabilitySetsDashboard/CapabilitySetsDashboardComponent';
import { selectActiveWorkspace } from 'js/store/reducers/workspaces';
import { useNotify } from 'js/hooks/useNotify';
import { SCHEMAS } from 'js/api/schemas';
import { useStandardCollator } from 'js/utils/string';

function capabilitySetsQueryId(workspaceId) {
  return `${workspaceId}-capability-sets`;
}

function CapabilitySetsDashboard({
  listCapabilitySets,
  updateCapabilitySet,
  workspace,
}) {
  const { workspaceId } = useParams();
  const notify = useNotify();
  const store = useStore();
  const standardCollator = useStandardCollator();

  const setsQueryId = capabilitySetsQueryId(workspaceId);
  const setsQuery = useSelector((state) => state.queries[setsQueryId]);

  const refreshCapabilitySets = useCallback(
    () => listCapabilitySets(workspaceId, setsQueryId),
    [listCapabilitySets, workspaceId, setsQueryId]
  );

  const [lastSetsQueryResult, setLastSetsQueryResult] = useState(null);
  const result = setsQuery?.result;
  useEffect(() => {
    if (result) {
      setLastSetsQueryResult(result);
    }
  }, [result]);

  const entities = store.getState().entities;

  const capabilitySets = useMemo(
    () =>
      denormalize(
        lastSetsQueryResult || [],
        SCHEMAS.CAPABILITY_SET_ARRAY,
        store.getState().entities
      ).sort(
        (a, b) =>
          standardCollator.compare(a.name, b.name) ||
          standardCollator.compare(a.id, b.id)
      ),
    [lastSetsQueryResult, standardCollator, entities]
  );

  useEffect(() => {
    refreshCapabilitySets();
  }, [refreshCapabilitySets]);

  const modifyCapabilitySetState = useMemo(
    () => (newState) => (capabilitySet) => {
      updateCapabilitySet(capabilitySet.id, {
        archived_state: newState,
        archived_at: newState === 'archived' ? new Date() : null,
      })
        .then(() => {
          const op = newState === 'archived' ? 'archived' : 'restored';
          notify({
            type: 'success',
            message: `The "${capabilitySet.name}" set was successfully ${op}.`,
          });
        })
        .catch(() => {
          const op = newState === 'archived' ? 'archiving' : 'restoring';
          notify({
            type: 'danger',
            message: `Sorry, there was a problem ${op} the set, "${capabilitySet.name}".`,
          });
        });
    },
    [updateCapabilitySet, notify]
  );

  const handleArchiveCapabilitySet = useMemo(
    () => modifyCapabilitySetState('archived'),
    [modifyCapabilitySetState]
  );
  const handleRestoreCapabilitySet = useMemo(
    () => modifyCapabilitySetState(null),
    [modifyCapabilitySetState]
  );

  return (
    <CapabilitySetsDashboardComponent
      capabilitySets={capabilitySets}
      setsQuery={setsQuery}
      workspace={workspace}
      onArchiveCapabilitySet={handleArchiveCapabilitySet}
      onRestoreCapabilitySet={handleRestoreCapabilitySet}
    />
  );
}

export default withRouter(
  connect(
    (state) => {
      const workspace = selectActiveWorkspace(state);
      return {
        workspace,
      };
    },
    { listCapabilitySets, updateCapabilitySet }
  )(CapabilitySetsDashboard)
);
