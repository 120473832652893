import React, { useCallback } from 'react';

import { getComponents } from './Types';
import { isValidAnswerSetTypeForQuestion } from '../../answer-set-utils';

import ErrorMessage from 'js/components/Assessment/Editors/common/components/EditorErrors/ErrorMessage';

export function AnswerSet({
  answerSet,
  question,
  onChoiceAdd = () => {},
  onChoiceDelete = () => {},
  onChoiceUpdate = () => {},
  onAnswerSetChange = () => {},
}) {
  const { ChoiceEditor } = getComponents(question?.type);
  const isValidAnswerSet = isValidAnswerSetTypeForQuestion(
    question.type,
    answerSet?.type
  );
  const choiceCount = Object.keys(answerSet?.choices ?? {}).length;
  const hasChoices = isValidAnswerSet && choiceCount > 0;

  const handleAddChoice = useCallback(
    (choiceProps) => {
      const answerSetId = isValidAnswerSet ? answerSet.id : null;
      onChoiceAdd(question.guid, answerSetId, choiceProps);
    },
    [isValidAnswerSet, answerSet, question, onChoiceAdd]
  );

  return (
    <div>
      <div className="setting-only">Choices</div>
      <div>
        <ChoiceEditor
          answerSet={answerSet}
          questionId={question.guid}
          questionType={question.type}
          onAddChoice={handleAddChoice}
          onDeleteChoice={onChoiceDelete}
          onChoiceUpdate={onChoiceUpdate}
          onAnswerSetChange={onAnswerSetChange}
        />
      </div>
      {!hasChoices && (
        <div className="invalid-feedback force-show">
          <ErrorMessage>At least one option is required</ErrorMessage>
        </div>
      )}
    </div>
  );
}

export default AnswerSet;
