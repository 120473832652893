import React, { useMemo } from 'react';
// import ArrowMarker from 'js/components/Charts/Differential/ArrowMarker';
import standardCohortKeys from 'js/utils/cohort-keys';

function CapabilityMatrixPlot({
  width = 240,
  height = 200,
  margin = {},
  data = [],
  cohortResponses = {},
}) {
  margin = {
    top: 5,
    right: 5,
    bottom: 5,
    left: 5,
    ...margin,
  };

  const chartWidth = width - margin.left - margin.right;
  const chartHeight = height - margin.top - margin.bottom;
  const isValidData =
    data.length === 2 &&
    data.find((d) => !d.response || d.response.is_na) === undefined;
  const [x, y] = isValidData
    ? [...data].reverse().map((d, i) => (i === 0 ? d.score : 1 - d.score))
    : [0, 0];

  // const arrowId = useId();

  // NOTE: For now, we're just doing to display the cumulative data.
  // TODO: Add support for displaying cohort data.
  const { cohortFrequencyData, cohortTotals } = useMemo(() => {
    const rawCohortData = Object.fromEntries(
      Object.entries(cohortResponses).map(([cohort, responses]) => {
        return [
          cohort,
          responses.map((response) => {
            return { x: response[0], y: response[1] };
          }),
        ];
      })
    );

    rawCohortData[standardCohortKeys.CUMULATIVE] = Object.values(
      rawCohortData
    ).reduce((acc, d) => acc.concat(d), []);

    const cohortFrequencyTree = Object.entries(rawCohortData).reduce(
      (cAcc, [cohort, responses]) => {
        cAcc[cohort] = responses.reduce((rAcc, r) => {
          const xNode = (rAcc[r.x] = rAcc[r.x] ?? {});
          xNode[r.y] = (xNode[r.y] ?? 0) + 1;
          return rAcc;
        }, {});
        return cAcc;
      },
      {}
    );

    const cohortFrequencyData = Object.entries(cohortFrequencyTree).reduce(
      (acc, [cohort, xs]) => {
        acc[cohort] = Object.entries(xs).reduce((xAcc, [xVal, ys]) => {
          return Object.entries(ys).reduce((yAcc, [yVal, count]) => {
            yAcc.push({ x: +xVal, y: +yVal, count });
            return yAcc;
          }, xAcc);
        }, []);
        return acc;
      },
      {}
    );

    const cohortTotals = Object.entries(cohortFrequencyData).reduce(
      (acc, [cohort, dataPoints]) => {
        const cohortStats = dataPoints.reduce(
          (dAcc, d) =>
            d.x != null && d.y != null
              ? {
                  sumX: dAcc.sumX + d.x,
                  sumY: dAcc.sumY + d.y,
                  count: dAcc.count + d.count,
                }
              : dAcc,
          { sumX: 0, sumY: 0, count: 0 }
        );

        acc[cohort] = {
          meanX: cohortStats.sumX / cohortStats.count,
          meanY: cohortStats.sumY / cohortStats.count,
          total: cohortStats.count,
        };
        return acc;
      },
      {}
    );

    // console.log('👿 cohortFrequencyTree', cohortFrequencyTree);

    return { rawCohortData, cohortFrequencyData, cohortTotals };
  }, [cohortResponses]);

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      className="capability-matrix"
    >
      {/* <defs>
        <ArrowMarker id={arrowId} refX={7} />
      </defs> */}

      <g transform={`translate(${margin.left}, ${margin.top})`}>
        <g>
          <line
            x1={0}
            y1={chartHeight / 2}
            x2={chartWidth}
            y2={chartHeight / 2}
            className="chart-axis chart-grid"
          />
          <line
            x1={chartWidth / 2}
            y1={0}
            x2={chartWidth / 2}
            y2={chartHeight}
            className="chart-axis chart-grid"
          />
          {/* {!isValidData && (
            <rect
              x={0}
              y={0}
              width={chartWidth}
              height={chartHeight}
              className="no-response-fill"
              opacity={0.5}
            />
          )} */}
          <rect
            width={chartWidth}
            height={chartHeight}
            fill="transparent"
            className="chart-axis"
          />
          {/* <g className="palette-chart-2">
            <line
              x1={0}
              y1={chartHeight}
              x2={0}
              y2={0}
              className="chart-axis palette-stroke palette-fill"
              markerEnd={`url(#${arrowId})`}
            />
          </g>
          <g className="palette-chart-3">
            <line
              x1={0}
              y1={chartHeight}
              x2={chartWidth}
              y2={chartHeight}
              className="chart-axis palette-stroke palette-fill"
              markerEnd={`url(#${arrowId})`}
            />
          </g> */}
        </g>
        <g className="capability-matrix-detail-points">
          {cohortFrequencyData[standardCohortKeys.CUMULATIVE].map((d) => {
            const totalCount =
              cohortTotals[standardCohortKeys.CUMULATIVE].total;

            return (
              <g className="palette-chart-1 palette-soft-muted">
                <circle
                  cx={d.x * chartWidth}
                  cy={(1 - d.y) * chartHeight}
                  r={Math.max((d.count / totalCount) * 4, 1)}
                  className="palette-fill"
                />
              </g>
            );
          })}
        </g>
        <g>
          {(() => {
            const cumulativeTotals =
              cohortTotals[standardCohortKeys.CUMULATIVE];
            if (cumulativeTotals.count === 0) {
              return null;
            }
            const { meanX: x, meanY: y } = cumulativeTotals;

            return (
              <g className="palette-chart-1">
                <circle
                  cx={x * chartWidth}
                  cy={(1 - y) * chartHeight}
                  r={5}
                  className="palette-fill"
                />
              </g>
            );
          })()}
        </g>
        {isValidData && (
          <circle
            cx={x * chartWidth}
            cy={y * chartHeight}
            r={5}
            className="matrix-plot-point"
          />
        )}
      </g>
    </svg>
  );
}

export default CapabilityMatrixPlot;
