import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import {
  attachClosestEdge,
  extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';

import { faGripHorizontal } from '@fortawesome/pro-solid-svg-icons';

import { Icon } from 'js/components';
import DropIndicator from './DropIndicator';
import { useEditorContext } from 'js/ydoc/docs/capabilities-assessment/EditorContext';
import styles from '../AnswerSet.module.scss';

function DraggableChoice({
  choice,
  index,
  answerSetId,
  questionId,
  children,
  actions: Actions,
}) {
  const { moveAnswerSetChoiceBefore, moveAnswerSetChoiceAfter } =
    useEditorContext();

  const ref = useRef(null);
  const dragHandleRef = useRef(null);
  // const [_dragging, setDragging] = useState(false);
  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const [closestEdge, setClosestEdge] = useState(null);

  useEffect(() => {
    const el = ref.current;
    const dragHandle = dragHandleRef.current;
    if (!el) {
      throw new Error('No element found');
    }
    if (!dragHandle) {
      throw new Error('No drag handle found');
    }

    return combine(
      draggable({
        element: el,
        dragHandle,
        getInitialData: () => ({ choice, index, answerSetId, questionId }),
        onDragStart: ({ source }) => {
          source.element.classList.remove('drag-preview');
        },
        onGenerateDragPreview: ({ source }) => {
          source.element.classList.add('drag-preview');
        },
      }),
      dropTargetForElements({
        element: el,
        canDrop: ({ source }) =>
          source.data.choice &&
          source.data.choice.guid !== choice.guid &&
          source.data.answerSetId === answerSetId,
        getData: (args) => {
          return attachClosestEdge(
            { choice, index },
            {
              input: args.input,
              element: args.element,
              allowedEdges: ['left', 'right'],
            }
          );
        },
        onDragEnter: () => setIsDraggedOver(true),
        onDragLeave: () => {
          setClosestEdge(null);
          setIsDraggedOver(false);
        },
        onDrag({ self, source }) {
          const isSource = source.element === el;
          if (isSource) {
            setClosestEdge(null);
            return;
          }

          const closestEdge = extractClosestEdge(self.data);

          setClosestEdge(closestEdge);
        },
        onDrop: ({ source, location }) => {
          setIsDraggedOver(false);
          setClosestEdge(null);

          const dropTarget = location.current.dropTargets[0];
          const closestEdge = extractClosestEdge(dropTarget.data);

          (closestEdge === 'right'
            ? moveAnswerSetChoiceAfter
            : moveAnswerSetChoiceBefore)(
            answerSetId,
            source.data.choice.guid,
            choice.guid
          );
        },
        getIsSticky: (args) => {
          const elementUnderCursor = document.elementFromPoint(
            args.input.pageX,
            args.input.pageY
          );
          return (
            elementUnderCursor &&
            elementUnderCursor.closest('.answer-set-droppable-area')
          );
        },
      })
    );
  }, [
    choice,
    index,
    questionId,
    answerSetId,
    moveAnswerSetChoiceBefore,
    moveAnswerSetChoiceAfter,
  ]);

  return (
    <div
      className={classNames(styles.choice, { 'dragged-over': isDraggedOver })}
    >
      {isDraggedOver && (
        <DropIndicator
          vertical
          left={closestEdge === 'left'}
          right={closestEdge === 'right'}
        />
      )}
      <div className={styles['choice-wrapper']} ref={ref}>
        <div ref={dragHandleRef} className={styles['drag-handle']}>
          <Icon icon={faGripHorizontal} />
        </div>
        {Actions && (
          <div className={styles['choice-actions']}>
            <Actions />
          </div>
        )}
        {children}
      </div>
    </div>
  );
}

export default DraggableChoice;
