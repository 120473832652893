import React, { useCallback, useMemo } from 'react';

import { Button } from 'reactstrap';
import { useSelectorContext } from './SelectorContext';
import { ButtonBreadcrumbs } from 'js/components/Breadcrumbs';
import CapabilityPickerItem from './CapabilityPickerItem';

import { useEditorContext } from 'js/ydoc/docs/capabilities-assessment/EditorContext';
import { getCategoryPaletteColor } from 'js/utils/color';

import styles from './CapabilitySelector.module.scss';

function CapabilityPicker({ page }) {
  const { activeItem, navigateToItem, selectedCapabilities } =
    useSelectorContext();
  const { doc, togglePageCapabilitySelection } = useEditorContext();
  const childCapabilities = useMemo(
    () =>
      activeItem?.capability?.children ??
      activeItem?.capabilitySet?.capabilities ??
      [],
    [activeItem]
  );

  // const pathToItem = getPathToItem(capability.id);
  // const childrenOfItem = getChildrenOfItem(capability.id);

  const path = useMemo(() => activeItem?.path ?? [], [activeItem?.path]);
  const name = activeItem?.capabilitySet?.name ?? activeItem?.capability?.name;
  const breadcrumbs = useMemo(
    () => [
      ...path.map((c, i) => {
        const pathSubset = path.slice(0, i);

        return {
          label: c.name,
          to:
            i === 0
              ? { capabilitySet: c, path: pathSubset }
              : { capability: c, path: pathSubset },
        };
      }),
      { label: name },
    ],
    [path, name]
  );
  const { background: color } = useMemo(() => {
    return getCategoryPaletteColor(path?.length);
  }, [path?.length]);

  const handleBreadcrumbClick = useCallback(
    (to) => {
      navigateToItem(to);
    },
    [navigateToItem]
  );

  const handleSelectAll = useCallback(() => {
    doc.transact(() => {
      childCapabilities.forEach((c) => {
        if (!(c.id in selectedCapabilities)) {
          togglePageCapabilitySelection(page.guid, c.id, true);
        }
      });
    });
  }, [
    childCapabilities,
    page.guid,
    selectedCapabilities,
    togglePageCapabilitySelection,
    doc,
  ]);

  const handleClearAll = useCallback(() => {
    doc.transact(() => {
      childCapabilities.forEach((c) => {
        if (c.id in selectedCapabilities) {
          togglePageCapabilitySelection(page.guid, c.id, false);
        }
      });
    });
  }, [
    childCapabilities,
    page.guid,
    selectedCapabilities,
    togglePageCapabilitySelection,
    doc,
  ]);

  const childPath = useMemo(() => {
    return path.concat(activeItem?.capability ?? activeItem?.capabilitySet);
  }, [path, activeItem]);

  return (
    <div className={styles.picker}>
      <div className={styles['picker-header']}>
        <div className={styles['picker-breadcrumbs']}>
          <ButtonBreadcrumbs
            breadcrumbs={breadcrumbs}
            onClick={handleBreadcrumbClick}
            showFinalDivider={false}
          />
        </div>
        <div className={styles['picker-actions']}>
          <Button onClick={handleSelectAll}>Select all</Button>
          <Button onClick={handleClearAll}>Clear all</Button>
        </div>
      </div>
      <div className={styles['picker-body']}>
        <div className={styles['picker-children']}>
          {childCapabilities.map((c) => (
            <CapabilityPickerItem
              key={c.id}
              capability={c}
              checked={c.id in selectedCapabilities}
              color={color}
              path={childPath}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default CapabilityPicker;
