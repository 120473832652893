import React, { useMemo } from 'react';
import CapabilityMatrixPlot from './CapabilityMatrixPlot';
import { useAssessmentReviewContext } from '../AssessmentReviewContext';
import { constructQuestionKey } from '../../../utils';
import { getAnswerDomain } from 'js/utils/scoring';
import { scaleLinear } from '@visx/scale';

function CapabilityOverview({ capability, elements }) {
  const { questionMap, cohorts, participantCohortMap } =
    useAssessmentReviewContext();
  console.log('🎀cohorts', cohorts, participantCohortMap);

  const questions = useMemo(() => {
    return elements
      .map((element) => {
        const question = questionMap[constructQuestionKey(capability, element)];
        return question
          ? {
              ...question,
              scale: scaleLinear({
                domain: getAnswerDomain(question.answers),
                range: [0, 1],
              }),
            }
          : undefined;
      })
      .filter((q) => q);
  }, [questionMap, capability, elements]);

  const { cohortResponses } = useMemo(() => {
    const participantResponses = questions.reduce(
      (qAcc, question, questionIndex) => {
        question.responses.reduce((rAcc, response) => {
          const pResponse = (rAcc[response.participant_id] =
            rAcc[response.participant_id] ?? []);

          console.log(question.scale.domain());
          pResponse[questionIndex] = response.is_na
            ? null
            : question.scale(+response.value);
          return rAcc;
        }, qAcc);
        return qAcc;
      },
      {}
    );

    const cohortResponses = Object.entries(participantResponses).reduce(
      (cAcc, [participantId, participantResponses]) => {
        const participantCohort = participantCohortMap[participantId];
        const cohortResponses = (cAcc[participantCohort] =
          cAcc[participantCohort] ?? []);

        cohortResponses.push(participantResponses);

        return cAcc;
      },
      {}
    );

    return { participantResponses, cohortResponses };

    // return questions.map((question) => {
    //   return question.responses.map((response) => {
    //     return response.participant_id;
    //   });
    // });
  }, [questions, participantCohortMap]);

  return (
    <div>
      <div>
        <label>Overview</label>
      </div>
      <CapabilityMatrixPlot
        width={250}
        height={200}
        cohortResponses={cohortResponses}
      />
    </div>
  );
}

export default CapabilityOverview;
