import { getPageCapabilities } from '../../../utils';
import { useAssessmentContext } from '../../../AssessmentContext';
import React, { useMemo } from 'react';
import { Card, CardBody } from 'reactstrap';
import CapabilityResults from './CapabilityResults';

function CapabilitiesPageResults({ page }) {
  const { capabilitySets } = useAssessmentContext();

  const capabilitySet = useMemo(() => {
    return capabilitySets[page.capabilitySetId];
  }, [capabilitySets, page.capabilitySetId]);

  const pageCapabilities = useMemo(() => {
    return getPageCapabilities(capabilitySet, page.selectedCapabilities);
  }, [capabilitySet, page.selectedCapabilities]);

  return (
    <Card>
      <CardBody>
        <h3>{capabilitySet.name}</h3>
        {pageCapabilities.map((capability) => (
          <CapabilityResults
            key={capability.id}
            capability={capability}
            elements={page.capabilityElements}
          />
        ))}
      </CardBody>
    </Card>
  );
}

export default CapabilitiesPageResults;
