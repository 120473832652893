import React from 'react';
import { Icon } from 'js/components';
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons';
// import { faCheckSquare } from '@fortawesome/pro-regular-svg-icons';

import styles from '../../CapabilitiesAssessmentEditor.module.scss';

function SimpleCapability({ name, color }) {
  return (
    <div className={styles['simple-capability']}>
      <Icon
        icon={faCheckSquare}
        style={{ color: color }}
        className="icon-left"
      />
      {name}
    </div>
  );
}

export default SimpleCapability;
