import React from 'react';
import { Link } from 'react-router-dom';

import { Icon } from 'js/components';

const RunNavigation = ({ pages, pageIndex }) => {
  const nextPageId =
    pages.length > pageIndex + 1 ? pages[pageIndex + 1].guid : null;

  const prevPageId = pageIndex > 0 ? pages[pageIndex - 1].guid : null;

  return (
    <div className="survey-run-navigation">
      {prevPageId !== null ? (
        <Link className="btn btn-secondary" to={'' + prevPageId}>
          <span>
            <Icon className="btn-icon-left" icon="arrow-left" size="lg" />
            Back
          </span>
        </Link>
      ) : null}
      {nextPageId !== null ? (
        <Link
          data-test="next-question-set-link"
          className="btn btn-primary"
          to={'' + nextPageId}
        >
          <span>
            Next
            <Icon className="btn-icon-right" icon="arrow-right" size="lg" />
          </span>
        </Link>
      ) : (
        <Link
          data-test="review-assessment-link"
          className="btn btn-primary"
          to="review"
        >
          Review
        </Link>
      )}
    </div>
  );
};

export default RunNavigation;
