import React from 'react';

import {
  clear,
  initializeFromScratch,
} from 'js/ydoc/docs/capabilities-assessment';
import { useDoc } from 'js/ydoc/doc';

function Reinitialize() {
  const doc = useDoc();

  const handleClick = () => {
    clear(doc);
    initializeFromScratch(doc);
  };

  return <button onClick={handleClick}>Reinitialize</button>;
}

export default Reinitialize;
