import React, { useMemo, useEffect, useState } from 'react';
import { trimEnd } from 'lodash';
import { Link } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { useRouteMatch } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import NoUserGroupsCard from './NoUserGroupsCard';
import {
  Loading as LoadingIndicator,
  PanelHeader,
  Icon,
  ReplaceLink,
} from 'js/components';
import UserGroupCard from './UserGroupCard';
import { useStandardCollator } from 'js/utils/string';
function UserGroupsDashboardComponent({
  userGroups,
  groupsQuery,
  workspace,
  onArchiveUserGroup,
  onRestoreUserGroup,
}) {
  const searchParams = new URLSearchParams(window.location.search);
  const match = useRouteMatch();
  const showArchived = searchParams.has('archived');
  const [hasLoaded, setHasLoaded] = useState(groupsQuery?.finishedAt > 0);
  const collator = useStandardCollator();

  useEffect(() => {
    if (groupsQuery?.finishedAt) {
      setHasLoaded(true);
    }
  }, [groupsQuery?.finishedAt]);

  const filteredUserGroups = useMemo(() => {
    if (!userGroups?.length) return [];

    const filter = showArchived
      ? (userGroup) => userGroup.archived_state === 'archived'
      : (userGroup) => userGroup.archived_state !== 'archived';
    return userGroups
      .filter(filter)
      .sort(
        (a, b) =>
          collator.compare(a.name, b.name) || collator.compare(a.id, b.id)
      );
  }, [userGroups, showArchived, collator]);

  return (
    <div>
      <PanelHeader size="flex">
        <div className="panel-body-no-label px-4">
          <div className="content-fluid">
            <div className="row">
              <div className="col-12 col-md-9">
                <h1>User Groups</h1>
                <p>
                  Collect feedback from similar stakeholders on related
                  capabilities
                </p>
              </div>
              <div className="panel-actions col-12 col-md-3">
                <Link
                  className="btn btn-primary create-link"
                  to={`${match.url}/new`}
                >
                  <Icon icon="plus" className="btn-icon-left" /> Create
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <Nav className="tab-nav nav-underline">
            <NavItem>
              <NavLink
                tag={ReplaceLink}
                active={!searchParams.has('archived')}
                data-view="active"
                to={`${trimEnd(match.url, '/')}`}
              >
                Active
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={ReplaceLink}
                active={searchParams.has('archived')}
                data-view="archived"
                to={`${trimEnd(match.url, '/')}?archived`}
              >
                Archived
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </PanelHeader>
      <div className="content" style={{ position: 'relative' }}>
        {!hasLoaded && !filteredUserGroups?.length && (
          <Card>
            <CardBody>
              <p></p>
            </CardBody>
          </Card>
        )}

        {hasLoaded && !filteredUserGroups?.length && (
          <NoUserGroupsCard type={showArchived ? 'archived' : 'default'} />
        )}

        {filteredUserGroups.map((userGroup) => (
          <UserGroupCard
            key={userGroup.id}
            userGroup={userGroup}
            workspace={workspace}
            onArchive={onArchiveUserGroup}
            onRestore={onRestoreUserGroup}
          />
        ))}

        {groupsQuery?.isLoading && (
          <LoadingIndicator
            style={{ position: 'absolute', right: '25px', top: '10px' }}
          />
        )}
      </div>
    </div>
  );
}

export default UserGroupsDashboardComponent;
