import {
  Binding,
  singletonUpdateHandler,
  entityUpdateHandler,
} from '../common/binding';
import { schemaStructure } from './schema';

export function createBinding(doc) {
  // NOTE: CREATE THE SCHEMA STRUCTURE HERE
  // const answerSetsEntity = new SchemaEntity('answerSets', {});
  // const answerSetsSet = new SchemaSet(answerSetsEntity);

  // const elementsEntity = new SchemaEntity(
  //   'elements',
  //   { answerSet: answerSetsEntity },
  //   { idAttribute: 'guid' }
  // );
  // const elementsArray = new SchemaExclusiveArray(
  //   'pageElements',
  //   elementsEntity
  // );
  // const pagesEntity = new SchemaEntity(
  //   'pages',
  //   { elements: elementsArray },
  //   { idAttribute: 'guid' }
  // );
  // const pagesArray = new SchemaExclusiveArray('assessmentPages', pagesEntity);
  // const metaSingleton = new SchemaSingletonEntity('meta');
  // const configSingleton = new SchemaSingletonEntity('config', {});
  // const assessmentSingleton = new SchemaSingletonEntity('assessment');

  // const assessmentTemplateSingleton = new SchemaSingletonEntity(
  //   'assessmentTemplate',
  //   {
  //     assessment: assessmentSingleton,
  //     meta: metaSingleton,
  //     config: configSingleton,
  //     pages: pagesArray,
  //     answerSets: answerSetsSet,
  //   }
  // );

  return new CapabilitiesAssessmentBinding(doc, schemaStructure);
}

class CapabilitiesAssessmentBinding extends Binding {
  _initializeTypes() {
    return {
      meta: this.doc.getMap('meta'),
      pages: this.doc.getMap('pages'),
      elements: this.doc.getMap('elements'),
      capabilityElements: this.doc.getMap('capabilityElements'),
      answerSets: this.doc.getMap('answerSets'),
      capabilitySetCache: this.doc.getMap('capabilitySetCache'),
      capabilityCache: this.doc.getMap('capabilityCache'),
    };
  }

  _initializeObservers() {
    this._types.meta.observeDeep(singletonUpdateHandler('meta', this));
    this._types.elements.observeDeep(entityUpdateHandler('elements', this));
    this._types.pages.observeDeep(entityUpdateHandler('pages', this));
    this._types.capabilityElements.observeDeep(
      entityUpdateHandler('capabilityElements', this)
    );
    this._types.answerSets.observeDeep(entityUpdateHandler('answerSets', this));
    this._types.capabilityCache.observeDeep(
      entityUpdateHandler('capabilityCache', this)
    );
    this._types.capabilitySetCache.observeDeep(
      entityUpdateHandler('capabilitySetCache', this)
    );
  }
}
