import React from 'react';
import styles from '../Editor.module.scss';

export default function LongTextQuestionDetail() {
  return (
    <div className={styles['long-text-detail']}>
      <textarea disabled rows={2}></textarea>
    </div>
  );
}
