import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import UserGroupsDashboard from './containers/UserGroupsDashboard';
import UserGroupComponent from './containers/UserGroup';
import CreateUserGroup from './containers/CreateUserGroup';
import AssessUserGroup from './containers/AssessUserGroup';
import CustomizeAssessment from './containers/CustomizeAssessment';
import AssessmentReview from './containers/AssessmentReview';
// import UserGroupForm from './components/UserGroupForm';

function UserGroupsRoutes() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.path}/new`} component={CreateUserGroup} />
      <Route
        path={`${match.path}/:userGroupId/assessments/:assessmentId/review`}
        component={AssessmentReview}
      />
      <Route
        path={`${match.path}/:userGroupId/assessments/new`}
        component={AssessUserGroup}
      />
      <Route
        path={`${match.path}/:userGroupId/assessments/:assessmentId/customize`}
        component={CustomizeAssessment}
      />
      <Route
        path={`${match.path}/:userGroupId`}
        component={UserGroupComponent}
      />
      <Route exact path={match.path} component={UserGroupsDashboard} />
    </Switch>
  );
}

export default UserGroupsRoutes;
