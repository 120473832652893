import { Map as YMap } from 'yjs';
import { pick } from 'lodash';

function reconcileProperties(yMap, properties) {
  Object.entries(properties).forEach(([key, value]) => {
    // TODO: figure out if this needs to do an equality check first
    yMap.set(key, value);
  });
}

export function updateCacheForCapabilitySet(doc, capabilitySet) {
  if (!capabilitySet) {
    throw new Error('Capability set is required');
  }

  const capabilitySetCache = doc.getMap('capabilitySetCache');

  doc.transact(() => {
    let cachedCapabilitySet = capabilitySetCache.get(capabilitySet.id);
    if (!cachedCapabilitySet) {
      cachedCapabilitySet = new YMap();
      capabilitySetCache.set(capabilitySet.id, cachedCapabilitySet);
    }

    const cachedProperties = {
      ...pick(capabilitySet, ['id', 'name', 'description']),
      guid: capabilitySet.id,
    };
    reconcileProperties(cachedCapabilitySet, cachedProperties);

    // compare the set of children in the cachedCapability with the set of children in the capability
    let cachedChildren = cachedCapabilitySet.get('capabilities');
    if (!cachedChildren) {
      cachedChildren = new YMap();
      cachedCapabilitySet.set('capabilities', cachedChildren);
    }

    const childrenToCheck = new Set(cachedChildren.keys());

    capabilitySet.capabilities.forEach((child) => {
      updateCacheForCapability(doc, child);
      if (!cachedChildren.has(child.id)) {
        cachedChildren.set(child.id, true);
      }
      childrenToCheck.delete(child.id);
    });

    childrenToCheck.forEach((childId) => {
      const child = doc.getMap('capabilities').get(childId);
      if (!child || child.get('id') === null) {
        cachedChildren.delete(childId);
      }
    });
  });

  // const page = doc.getMap('pages').get(pageGuid);
  // page.set('capabilitySetId', capabilitySet.id);
}

export function updateCacheForCapability(doc, capability) {
  if (!capability) {
    throw new Error('Capability is required');
  }

  doc.transact(() => {
    const capabilityCache = doc.getMap('capabilityCache');
    let cachedCapability = capabilityCache.get(capability.id);
    if (!cachedCapability) {
      cachedCapability = new YMap();
      capabilityCache.set(capability.id, cachedCapability);
    }

    reconcileProperties(cachedCapability, {
      ...pick(capability, ['id', 'name', 'description', 'capability_set_id']),
      guid: capability.id,
    });

    // compare the set of children in the cachedCapability with the set of children in the capability
    let cachedChildren = cachedCapability.get('children');
    if (!cachedChildren) {
      cachedChildren = new YMap();
      cachedCapability.set('children', cachedChildren);
    }

    const childrenToCheck = new Set(cachedChildren.keys());

    capability.children.forEach((child) => {
      updateCacheForCapability(doc, child);
      if (!cachedChildren.has(child.id)) {
        cachedChildren.set(child.id, true);
      }
      childrenToCheck.delete(child.id);
    });

    childrenToCheck.forEach((childId) => {
      const child = doc.getMap('capabilities').get(childId);
      if (!child || child.get('id') === null) {
        cachedChildren.delete(childId);
      }
    });
  });
}
