import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import {
  faCaretRight,
  faSquareCheck as faCircleCheck,
  faCircleSmall,
} from '@fortawesome/pro-solid-svg-icons';
import { faSquare as faCircle } from '@fortawesome/pro-regular-svg-icons';
import { Icon } from 'js/components';
import { useSelectorContext } from './SelectorContext';
import { useTreeContext } from './TreeContext';
import { getCategoryPaletteColor } from 'js/utils/color';
import { useEditorContext } from 'js/ydoc/docs/capabilities-assessment/EditorContext';
import styles from './CapabilitySelector.module.scss';

function CapabilityTreeNode({ capability, path = [], depth = 0 }) {
  const childCount = capability.children.length;
  const { background } = getCategoryPaletteColor(depth);

  const { page, activeItem, navigateToItem, selectedCapabilities } =
    useSelectorContext();
  const { togglePageCapabilitySelection } = useEditorContext();
  const { dispatch, find } = useTreeContext();

  const nodeState = find(capability.id);
  const showChildren = !!nodeState?.isOpen;

  const toggleButtonProps = useMemo(() => {
    if (childCount === 0) {
      return { icon: faCircleSmall, transform: 'shrink-9' };
    }

    return { icon: faCaretRight, transform: showChildren ? 'rotate-90' : '' };
  }, [showChildren, childCount]);

  const nextDepth = depth + 1;
  const nextPath = useMemo(() => [...path, capability], [path, capability]);

  const handleNavigateToCapability = () => {
    navigateToItem({ capability, path });
  };

  const isSelected = capability.id in selectedCapabilities;
  const handleToggleSelected = useCallback(() => {
    togglePageCapabilitySelection(page.guid, capability.guid);
  }, [togglePageCapabilitySelection, page.guid, capability.guid]);

  const isActiveItem = activeItem?.capability?.id === capability.id;

  const toggleChildren = useCallback(() => {
    if (childCount > 0) {
      dispatch({ type: 'toggle', itemId: capability.id });
    }
  }, [dispatch, capability.id, childCount]);

  return (
    <div className={styles.node}>
      {showChildren && childCount > 0 && (
        <div
          className={styles['child-span']}
          // style={{ backgroundColor: background }}
        ></div>
      )}

      <div
        className={classNames(styles['node-body'], {
          [styles['active-item']]: isActiveItem,
        })}
      >
        <div className={styles['node-toggle']}>
          <button
            className={styles['btn-node-toggle']}
            onClick={toggleChildren}
          >
            <Icon {...toggleButtonProps} fixedWidth />
          </button>
        </div>
        <div className={styles['node-state']}>
          <button
            className={styles['btn-node-toggle']}
            onClick={handleToggleSelected}
          >
            <Icon
              style={{ color: background }}
              icon={isSelected ? faCircleCheck : faCircle}
              fixedWidth
            />
          </button>
        </div>
        <div className={styles['node-name']}>
          {childCount > 0 ? (
            <button
              className={styles['btn-node-name']}
              onClick={handleNavigateToCapability}
            >
              {capability.name}
            </button>
          ) : (
            capability.name
          )}
        </div>
      </div>
      {showChildren && childCount > 0 && (
        <div className={styles['node-children']}>
          {capability.children.map((c) => (
            <CapabilityTreeNode
              key={c.id}
              capability={c}
              path={nextPath}
              depth={nextDepth}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default CapabilityTreeNode;
