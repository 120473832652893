import { useContext } from 'react';

import { DocumentContext } from '../component';

export const useProviders = () => {
  const { providers } = useContext(DocumentContext);

  if (providers !== null) {
    return providers;
  } else {
    throw new Error(
      'Could not retrieve a set of providers. Please wrap in a DocumentProvider.'
    );
  }
};
