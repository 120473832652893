import React, { useEffect } from 'react';
import { load as loadUserGroupAssessment } from 'js/actions/user-group-assessment-actions';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DocumentProvider } from 'js/ydoc/doc/component';
import { EditorProvider } from 'js/ydoc/docs/capabilities-assessment/EditorContext';
import PreviewInterface from '../components/PreviewInterface';
function UserGroupAssessmentPreview({
  userGroupAssessment,
  loadUserGroupAssessment,
}) {
  const { userGroupId, assessmentId } = useParams();
  useEffect(() => {
    loadUserGroupAssessment(userGroupId, assessmentId);
  }, [loadUserGroupAssessment, userGroupId, assessmentId]);

  const token = useSelector((state) => state.identity.token);

  if (!userGroupAssessment) {
    return null;
  }

  return (
    <DocumentProvider
      channelProviderToken={token}
      channelProviderTopic={`crdt:user-group-assessment:${userGroupAssessment.crdt_doc_id}`}
    >
      <EditorProvider userGroupAssessment={userGroupAssessment}>
        <PreviewInterface />
      </EditorProvider>
    </DocumentProvider>
  );
}

export default connect(
  (state, { match }) => {
    const { assessmentId } = match.params;

    const userGroupAssessment =
      state.entities.userGroupAssessments[assessmentId];

    return {
      userGroupAssessment,
    };
  },
  { loadUserGroupAssessment }
)(UserGroupAssessmentPreview);

/*
const token = useSelector((state) => state.identity.token);
const currentWorkspaceId = useSelector((state) => state.currentWorkspaceId);
const [assessmentDocTemplate, setAssessmentDocTemplate] = useState(null);
const { templateId } = useParams();
const [error, setError] = useState(null);
const [loading, setLoading] = useState(true);

useEffect(() => {
  (async () => {
    if (currentWorkspaceId) {
      try {
        const response = await request.get(
          `/api/workspaces/${currentWorkspaceId}/assessment-doc-templates/${templateId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setError(null);
        setAssessmentDocTemplate(response.data.data);
      } catch (e) {
        console.error(e);
        setError('We are sorry. There was a problem loading the assessment.');
      } finally {
        setLoading(false);
      }
    }
  })();
}, [token, currentWorkspaceId, templateId]);
*/
