import React, {
  useCallback,
  useRef,
  useMemo,
  useState,
  useContext,
  useEffect,
} from 'react';
import classNames from 'classnames';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Icon } from 'js/components';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import {
  faCaretRight,
  faCaretDown,
  faCircleSmall,
} from '@fortawesome/pro-solid-svg-icons';
import { TreeContext } from '../TreeContext';
import styles from './CapabilityTree.module.scss';
import CapabilityForm from './CapabilityForm';
import { useStandardCollator } from 'js/utils/string';
import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { delay } from 'js/utils/delay';

const INDENTATION = 20;

function CapabilityNode({ path, capability, onAddChild, onEdit, className }) {
  const depth = path.length;
  const paddingLeft = depth * INDENTATION;
  const childPath = [...path, capability];

  const [showAddChild, setShowAddChild] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const nameRef = useRef(null);

  const { find, dispatch, uniqueContextId } = useContext(TreeContext);
  // console.log('🛎️ context', context);
  const nodeTreeState = find(capability.id);
  const showChildren = nodeTreeState?.isOpen;

  const [isDropTarget, setIsDropTarget] = useState(false);

  const handleAddChild = useCallback(async () => {
    await onAddChild(capability, { name: nameRef.current.value });
    nameRef.current.value = '';
  }, [onAddChild, capability]);

  const childrenCount = capability.children.length;
  const collator = useStandardCollator();
  const sortedChildren = capability.children.sort(
    (a, b) => collator.compare(a.name, b.name) || collator.compare(a.id, b.id)
  );

  const toggleChildren = useCallback(() => {
    if (childrenCount > 0) {
      dispatch({ type: 'toggle', itemId: capability.id });
    }
  }, [dispatch, capability.id, childrenCount]);

  const treeIconProps = useMemo(() => {
    if (childrenCount === 0) {
      return { icon: faCircleSmall, transform: 'shrink-9' };
      // return { icon: faCircleSmall };
    }

    return showChildren ? { icon: faCaretDown } : { icon: faCaretRight };
  }, [showChildren, childrenCount]);

  const dragRef = useRef(null);
  const cancelExpandRef = useRef(null);
  const cancelExpand = useCallback(() => {
    cancelExpandRef.current?.();
    cancelExpandRef.current = null;
  }, []);

  useEffect(() => {
    if (!dragRef.current) return;

    return combine(
      draggable({
        element: dragRef.current,
        getInitialData: () => ({
          type: 'capability',
          uniqueContextId: uniqueContextId,
          isOpenOnDragStart: showChildren,
          capability,
        }),
        onGenerateDragPreview: ({ source }) => {
          source.element.classList.add('drag-preview');
        },
        onDragStart: ({ source }) => {
          source.element.classList.remove('drag-preview');
          if (source.data.isOpenOnDragStart) {
            dispatch({ type: 'collapse', itemId: capability.id });
          }
        },
        onDrop: ({ source }) => {
          if (source.data.isOpenOnDragStart) {
            dispatch({ type: 'expand', itemId: capability.id });
          }
        },
      }),
      dropTargetForElements({
        element: dragRef.current,
        getData: () => {
          return {
            type: 'capability',
            uniqueContextId: uniqueContextId,
            capability,
          };
        },
        canDrop: ({ source }) => {
          return (
            source.data.type === 'capability' &&
            source.data.capability?.id !== capability.id &&
            source.data.uniqueContextId === uniqueContextId
          );
        },
        onDrag: ({ source }) => {
          if (
            source.data.capability?.id !== capability.id &&
            childrenCount &&
            !nodeTreeState?.isOpen &&
            !cancelExpandRef.current
          ) {
            cancelExpandRef.current = delay({
              waitMs: 500,
              fn: () => dispatch({ type: 'expand', itemId: capability.id }),
            });
          }
        },
        onDragEnter: () => {
          setIsDropTarget(true);
        },
        onDragLeave: () => {
          setIsDropTarget(false);
          cancelExpand();
        },
        onDrop: () => {
          setIsDropTarget(false);
          cancelExpand();
        },
      })
    );
  }, [
    dragRef,
    uniqueContextId,
    capability,
    showChildren,
    cancelExpand,
    childrenCount,
    dispatch,
    nodeTreeState?.isOpen,
  ]);

  const nodeClassName = useMemo(
    () =>
      classNames(styles.capability, className, {
        [styles['drop-target']]: isDropTarget,
      }),
    [isDropTarget, className]
  );

  return (
    <>
      <div className={nodeClassName}>
        {showChildren && childrenCount > 0 && (
          <div
            className={styles['partial-gutter']}
            style={{ left: `calc(0.75rem + ${paddingLeft}px)` }}
          />
        )}

        {/* <DepthGutters depth={depth} /> */}
        <button
          style={{ paddingLeft }}
          className={styles['tree-item']}
          onClick={toggleChildren}
          ref={dragRef}
        >
          <span className={styles['capability-body']}>
            <span className={styles['child-toggle']}>
              <Icon size="sm" fixedWidth {...treeIconProps} />
            </span>
            <span className={styles['capability-body-content']}>
              <span className={styles['capability-name']}>
                {capability.name}
              </span>
              <span className={styles['capability-description']}>
                {capability.description}
              </span>
            </span>
          </span>
        </button>
        <div className={styles['capability-menu']}>
          <Dropdown isOpen={showMenu} toggle={() => setShowMenu(!showMenu)}>
            <DropdownToggle color="tree-menu">
              <Icon icon={faEllipsis} />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={() => onAddChild([...path, capability])}>
                Add capability
              </DropdownItem>
              <DropdownItem onClick={() => onEdit(path, capability)}>
                Edit
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      {showAddChild && (
        <div
          style={{ paddingLeft: (depth + 1) * INDENTATION }}
          className={styles['add-capability']}
        >
          <div
            className={styles.gutter}
            style={{ left: `calc(0.75rem + ${paddingLeft}px)` }}
          />
          <CapabilityForm
            capability={capability}
            onSubmit={handleAddChild}
            onCancel={() => setShowAddChild(false)}
          />
        </div>
      )}
      {showChildren && (
        <div className={styles['children']}>
          <div
            className={styles.gutter}
            style={{ left: `calc(0.75rem + ${paddingLeft}px)` }}
          />
          {sortedChildren.map((child) => (
            <CapabilityNode
              key={child.id}
              path={childPath}
              capability={child}
              onAddChild={onAddChild}
              onEdit={onEdit}
            />
          ))}
        </div>
      )}
    </>
  );
}

export default CapabilityNode;
