import keymirror from 'keymirror';
import { request } from 'js/api/axios';
import { callApi } from '../api';
import { SCHEMAS } from 'js/api/schemas';
import { spreadApiKeys, extractApiKeys } from '../utils';

export const types = keymirror({
  ...spreadApiKeys('LOAD_USER_GROUP'),
  ...spreadApiKeys('LOAD_USER_GROUPS'),
  ...spreadApiKeys('CREATE_USER_GROUP'),
  ...spreadApiKeys('UPDATE_USER_GROUP'),
});

export function listUserGroups(workspaceId, queryId = null) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.LOAD_USER_GROUPS, types),
        request: (_data, token) =>
          request.get(`/api/workspaces/${workspaceId}/user-groups`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        schema: SCHEMAS.USER_GROUP_ARRAY,
        meta: { queryId },
      },
      dispatch
    );
  };
}

export function loadUserGroup(id, queryId = null) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.LOAD_USER_GROUP, types),
        request: (_data, token, currentWorkspaceId) =>
          request.get(
            `/api/workspaces/${currentWorkspaceId}/user-groups/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        schema: SCHEMAS.USER_GROUP,
        meta: { queryId },
      },
      dispatch
    );
  };
}

export function createUserGroup(userGroup) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.CREATE_USER_GROUP, types),
        request: (data, token, currentWorkspaceId) =>
          request.post(
            `/api/workspaces/${currentWorkspaceId}/user-groups`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        data: { user_group: userGroup },
        schema: SCHEMAS.USER_GROUP,
      },
      dispatch
    );
  };
}

export function updateUserGroup(id, patch) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.UPDATE_USER_GROUP, types),
        request: (data, token, currentWorkspaceId) =>
          request.put(
            `/api/workspaces/${currentWorkspaceId}/user-groups/${id}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        data: { user_group: patch },
        meta: { patch: { userGroups: { [id]: patch } } },
        schema: SCHEMAS.USER_GROUP,
      },
      dispatch
    );
  };
}
