// TODO: merge with LiveAssessment WelcomeForm and remove the duplicate code
import React, { useMemo } from 'react';
import * as Yup from 'yup';

import { Link, useLocation } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
  Input,
  FormFeedback,
} from 'reactstrap';
import { Icon } from 'js/components';

import { Formik } from 'formik';
import { faSignIn, faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import Logo from 'images/logo-dark.svg';
import * as GemIcons from './icons';

import styles from './WelcomeForm.module.scss';

const gemIcons = Object.values(GemIcons);
const WelcomeSchema = Yup.object().shape({ name: Yup.string().max(50) });

export function WelcomeForm({
  participant,
  // assessment,
  // userGroup,
  onComplete = () => {},
}) {
  const location = useLocation();
  const gemIcon = useMemo(
    () => gemIcons[Math.floor(Math.random() * gemIcons.length)],
    []
  );
  const handleSubmit = async ({ name }, { setSubmitting }) => {
    const trimmedName = name.trim();
    setSubmitting(true);

    try {
      await onComplete({
        id: participant.id,
        name: trimmedName.length > 0 ? name : participant.name,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className={styles['welcome-form']}>
      <Card>
        <CardHeader>
          <CardTitle>
            <center>
              <div>Welcome to a</div>
              <h5>Capabilities Assessment</h5>
              <div className="subtitle">for your user group</div>
            </center>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <div className={styles.gem}>
            <Icon icon={gemIcon} />
          </div>
          <Formik
            initialValues={{ name: '' }}
            validationSchema={WelcomeSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <label>Your name (optional)</label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder={participant.name}
                  value={values.name}
                  id="name"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={errors.name && touched.name}
                />
                {errors.name && touched.name && (
                  <FormFeedback>{errors.name}</FormFeedback>
                )}
                <div className={styles.actions}>
                  <Button
                    color="primary"
                    type="submit"
                    data-test="join-as-guest-link"
                  >
                    {isSubmitting ? (
                      <Icon
                        icon={faCircleNotch}
                        fixedWidth
                        spin
                        className="btn-icon-left"
                      />
                    ) : (
                      <Icon
                        icon={faSignIn}
                        fixedWidth
                        className="btn-icon-left"
                      />
                    )}
                    Join as a visitor
                  </Button>
                </div>
              </form>
            )}
          </Formik>
          <div className={styles['help-text']}>
            <p>
              Already a Waypointr member?{' '}
              <Link
                to={{ pathname: '/pages/login', state: { from: location } }}
              >
                Sign in
              </Link>{' '}
              to your account or stay anonymous. It's up to you!
            </p>
          </div>
          <div className={styles.logo}>
            <img src={Logo} alt="Waypointr" height={20} />
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default WelcomeForm;
