import React from 'react';
import PageResults from './PageResults';

function Results({ userGroup, assessment, userGroupAssessment }) {
  return (
    <div>
      {assessment.document.pages.map((p) => (
        <PageResults key={p.guid} page={p} assessment={assessment} />
      ))}
    </div>
  );
}

export default Results;
