import { createContext, useContext, useMemo, useCallback } from 'react';

import {
  respond as respondToAssessment,
  unrespond as unrespondToAssessment,
  completeParticipantWelcome,
} from 'js/actions/assessment-actions';
import { useDispatch, useStore } from 'react-redux';
export const ResponseContext = createContext({
  questionMap: {},
  responses: {},
  participant: null,
  respond: async () => {},
  unrespond: async () => {},
  clearResponses: async () => {},
});

export function ResponseContextProvider({ children, assessment, participant }) {
  const dispatch = useDispatch();
  const store = useStore();
  const questionMap = useMemo(() => {
    return assessment.question_sets.reduce(
      (qsAcc, qs) =>
        qs.questions.reduce((qAcc, q) => {
          qAcc[q.key] = q;
          return qAcc;
        }, qsAcc),
      {}
    );
  }, [assessment]);

  const responses = useMemo(() => {
    return assessment.question_sets.reduce(
      (qsAcc, qs) =>
        qs.questions.reduce((qAcc, q) => {
          qAcc[q.id] = q.responses.find(
            (r) => r.participant_id === participant.id
          );
          return qAcc;
        }, qsAcc),
      {}
    );
  }, [assessment, participant]);

  const respond = useCallback(
    (questionId, response) => {
      return respondToAssessment(
        assessment.id,
        questionId,
        response
      )(dispatch, store.getState);
    },
    [assessment.id, dispatch, store.getState]
  );

  const unrespond = useCallback(
    (questionId) => {
      return unrespondToAssessment(assessment.id, questionId)(
        dispatch,
        store.getState
      );
    },
    [assessment.id, dispatch, store.getState]
  );

  const completeWelcome = useCallback(
    (vals) => {
      return completeParticipantWelcome(
        assessment.id,
        participant.token,
        vals
      )(dispatch, store.getState);
    },
    [assessment.id, dispatch, store.getState, participant.token]
  );

  // const responses = useMemo(() => {
  //   return Object.fromEntries(
  //     Object.entries(questionMap).map(([key, question]) => [
  //       key,
  //       question.responses.find((r) => r.participant_id === participant.id),
  //     ])
  //   );
  // }, [questionMap, participant]);
  const context = useMemo(() => {
    return {
      questionMap,
      responses,
      respond,
      unrespond,
      completeWelcome,
      participant,
    };
  }, [
    questionMap,
    responses,
    respond,
    unrespond,
    participant,
    completeWelcome,
  ]);

  return (
    <ResponseContext.Provider value={context}>
      {children}
    </ResponseContext.Provider>
  );
}

export function useResponseContext() {
  return useContext(ResponseContext);
}
