import React, { useMemo } from 'react';
import classNames from 'classnames';

import styles from '../../../EditorCommon.module.scss';
import toolStyles from '../AnswerSet.module.scss';

export default function DropIndicator({ left, right, vertical }) {
  const className = useMemo(
    () =>
      classNames(styles['drop-indicator'], toolStyles['drop-indicator'], {
        [toolStyles['drop-indicator-left']]: left,
        [toolStyles['drop-indicator-right']]: right,
        [toolStyles['drop-indicator-vertical']]: vertical,
      }),
    [left, right, vertical]
  );

  return <div className={className}></div>;
}
