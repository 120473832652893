import React from 'react';
import { Icon } from 'js/components';
import { Link, useParams } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';

function NoCapabilitySetsCard({ type = 'default' }) {
  const { workspaceId } = useParams();

  return (
    <Card>
      <CardBody>
        {type === 'archived' ? (
          <div className="media text-muted mt-4 mb-4">
            <Icon
              icon={['fal', 'spider-web']}
              size="4x"
              className="mr-3"
              fixedWidth
            />
            <div className="media-body">
              <p>You don't have any archived capability sets.</p>
              <p>
                Archiving a capability set suspends it from your account while
                preserving its data for historical purposes. We recommend
                archiving capability sets you no longer use to keep your account
                tidy.
              </p>
            </div>
          </div>
        ) : (
          <div className="media text-muted mt-4 mb-4">
            <Icon
              icon={['far', 'star-exclamation']}
              size="4x"
              className="mr-3"
              fixedWidth
            />
            <div className="media-body">
              <p>You don't have any active capability sets.</p>

              <div>
                <Link
                  data-test="create-team-link"
                  className="btn btn-primary create-link"
                  to={`/w/${workspaceId}/pm/capability-sets/new`}
                >
                  <Icon icon="plus" className="btn-icon-left" /> Create a new
                  capability set
                </Link>
              </div>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
}

export default NoCapabilitySetsCard;
