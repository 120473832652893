export function updateCapabilityElement(doc, elementGuid, updates) {
  const capabilityElements = doc.getMap('capabilityElements');
  const element = capabilityElements.get(elementGuid);
  if (!element) {
    return;
  }

  doc.transact(() => {
    Object.entries(updates).forEach(([key, value]) => {
      element.set(key, value);
    });
  });
}
