import React from 'react';
import { connect } from 'react-redux';
import { AssessmentReviewDetail } from '../components/Review';

function CapabilitiesAssessmentReviewDetailEngine({
  userGroup,
  userGroupAssessment,
  assessment,
}) {
  return (
    <AssessmentReviewDetail
      userGroup={userGroup}
      userGroupAssessment={userGroupAssessment}
      assessment={assessment}
    />
  );
}

export default connect(
  (store) => ({
    currentWorkspaceId: store.currentWorkspaceId,
  }),
  {}
)(CapabilitiesAssessmentReviewDetailEngine);
