import {
  SchemaSingletonEntity,
  SchemaExclusiveArray,
  SchemaArray,
  SchemaSet,
  SchemaEntity,
} from '../../entities';

export function getLookups(schemaItem) {
  return new Map(
    Object.entries(schemaItem.definition)
      .map(([key, schemaDefinitionItem]) => {
        switch (true) {
          case schemaDefinitionItem instanceof SchemaSingletonEntity:
            return [key, singletonEntityLookup(schemaDefinitionItem.key)];

          case schemaDefinitionItem instanceof SchemaExclusiveArray:
            return [
              key,
              exclusiveArrayLookup(schemaDefinitionItem, schemaItem),
            ];

          case schemaDefinitionItem instanceof SchemaArray:
            return [key, arrayLookup(schemaDefinitionItem, schemaItem)];

          case schemaDefinitionItem instanceof SchemaEntity:
            return [key, entityLookup(schemaDefinitionItem)];

          case schemaDefinitionItem instanceof SchemaSet:
            return [key, setLookup(schemaDefinitionItem, schemaItem)];

          default:
          // do nothing
        }

        return undefined;
      })
      .filter((x) => x)
  );
}

export function singletonEntityLookup(key) {
  return (entities) => {
    return entities[key] ?? null;
  };
}

export function exclusiveArrayLookup(schemaDefinition, parentSchemaItem) {
  return (entities, parentState, key) => {
    const values = entities[schemaDefinition.key];
    const parentKey = parentSchemaItem.getPositionKey(parentState);
    const results = values[parentKey] ?? [];
    return results;
  };
}

export function arrayLookup(schemaDefinition, parentSchemaItem) {
  return (entities, parentState, _key) => {
    const results = Object.entries(
      parentState[schemaDefinition.positionProperty] ?? {}
    )
      .sort(([, pa], [, pb]) => {
        return pa > pb ? 1 : pa === pb ? 0 : -1;
      })
      .map(([guid]) => {
        return entities[schemaDefinition.entity.key][guid];
      });

    return results;
  };
}
export function setLookup(schemaDefinition) {
  return (entities) => {
    const values = entities[schemaDefinition.key];
    return values;
  };
}

export function entityLookup(schemaDefinition) {
  return (entities, itemState, key) => {
    const id = itemState[schemaDefinition.idField];
    if (id == null) {
      return null;
    }

    const values = entities[schemaDefinition.key]?.[id] ?? null;
    return values;
  };
}
