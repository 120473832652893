import React from 'react';

import LikertChoice from './LikertChoice';
import LikertNaChoice from './LikertNaChoice';

import styles from '../AnswerSet.module.scss';

function LikertChoiceList({
  answerSetId,
  isStandard,
  choices,
  color,
  includeNA,
  naText,
  onChoiceUpdate,
  onChoiceDelete,
}) {
  return (
    <table>
      <thead>
        <tr>
          {!isStandard && <td className={styles['drag-column']}></td>}
          <th className={`${styles.shifted} ${styles['value-column']}`}>
            Score
          </th>
          <th className={styles.shifted}>Text</th>
          {!isStandard && <td></td>}
        </tr>
      </thead>
      <tbody>
        {choices.map((choice, i) => (
          <LikertChoice
            key={choice.id ?? choice.guid}
            choice={choice}
            color={color}
            isStandard={isStandard}
            className={styles['answer']}
            onUpdate={onChoiceUpdate}
            onDelete={onChoiceDelete}
            index={i}
            answerSetId={answerSetId}
          />
        ))}
        {includeNA && <LikertNaChoice text={naText} isStandard={isStandard} />}
      </tbody>
    </table>
  );
}

export default LikertChoiceList;
