import React from 'react';
import MatrixPlot from './MatrixPlot';
import DeviationPlot from './DeviationPlot';
import { Link } from 'react-router-dom';
import styles from './PageReview.module.scss';

function CapabilityReview({
  pageId,
  capability,
  capabilityElements,
  responses,
}) {
  const deviationPlotData = capabilityElements.map(
    (element) => responses[element.guid]
  );

  const matrixPlotData = deviationPlotData.slice(0, 2);
  return (
    <div className={styles['capability']}>
      <h3 className={styles['capability-title']}>
        <Link to={`./${pageId}`}>{capability.name}</Link>
      </h3>
      <div className={styles['capability-charts']}>
        {capabilityElements.length > 1 && (
          <MatrixPlot width={100} height={100} data={matrixPlotData} />
        )}
        <DeviationPlot width={100} height={100} data={deviationPlotData} />
      </div>
    </div>
  );
}

export default CapabilityReview;
