import React, {
  useCallback,
  useMemo,
  useReducer,
  useState,
  useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { PanelHeader, ReplaceLink } from 'js/components';
import { Route, matchPath } from 'react-router-dom';

import CapabilitySetHierarchy from './CapabilitySetHierarchy';
import CapabilitySetSettings from './CapabilitySetSettings';
import CapabilitySetUserGroups from './CapabilitySetUserGroups';
import { isNullOrWhitespace } from 'js/utils/string';
import { treeStateReducer, getInitialTreeState, findItem } from './tree';
import { TreeContext } from './TreeContext';
import NewCapabilityModal from './NewCapabilityModal';
import EditCapabilityModal from './EditCapabilityModal';
import { Breadcrumbs } from 'js/components/Breadcrumbs';

const TABS = {
  HIERARCHY: 'hierarchy',
  USER_GROUPS: 'user-groups',
  SETTINGS: 'settings',
};

function getActiveTab() {
  if (
    null !=
    matchPath(window.location.pathname, {
      path: '/w/:workspaceId/pm/capability-sets/:capabilitySetId/user-groups',
    })
  ) {
    return TABS.USER_GROUPS;
  } else if (
    null !=
    matchPath(window.location.pathname, {
      path: '/w/:workspaceId/pm/capability-sets/:capabilitySetId/settings',
    })
  ) {
    return TABS.SETTINGS;
  } else {
    return TABS.HIERARCHY;
  }
}

export default function CapabilitySet({
  capabilitySet,
  onUpdate,
  onAddCapability,
  onEditCapability,
  onReparentCapability,
}) {
  const { workspaceId, capabilitySetId } = useParams();

  const matchedTab = useMemo(getActiveTab, [window.location.pathname]);

  const [state, updateState] = useReducer(
    treeStateReducer,
    capabilitySet,
    getInitialTreeState
  );

  useEffect(() => {
    updateState({ type: 'refresh', capabilitySet });
  }, [capabilitySet]);

  const findTreeItem = useCallback(
    (itemId) => {
      return findItem(state, itemId);
    },
    [state]
  );

  const uniqueContextId = useMemo(
    () => Symbol(capabilitySetId),
    [capabilitySetId]
  );
  const context = useMemo(
    () => ({
      dispatch: updateState,
      uniqueContextId,
      find: findTreeItem,
    }),
    [updateState, findTreeItem, uniqueContextId]
  );

  const [addCapabilityModalContext, setAddCapabilityModalContext] =
    useState(null);

  const handleAddCapabilityModal = useCallback(
    (path) => {
      setAddCapabilityModalContext({ path, capabilitySet });
    },
    [setAddCapabilityModalContext, capabilitySet]
  );

  const handleCancelAddCapability = useCallback(() => {
    setAddCapabilityModalContext(null);
  }, [setAddCapabilityModalContext]);

  const handleAddCapability = useCallback(
    async (values) => {
      await onAddCapability(values);
      setAddCapabilityModalContext(null);
    },
    [setAddCapabilityModalContext, onAddCapability]
  );

  const [editCapabilityModalContext, setEditCapabilityModalContext] =
    useState(null);

  const handleEditCapabilityModal = useCallback(
    (path, capability) => {
      setEditCapabilityModalContext({ path, capability, capabilitySet });
    },
    [setEditCapabilityModalContext, capabilitySet]
  );

  const handleCancelEditCapability = useCallback(() => {
    setEditCapabilityModalContext(null);
  }, [setEditCapabilityModalContext]);

  const handleEditCapability = useCallback(
    async (values) => {
      await onEditCapability(values);
      setEditCapabilityModalContext(null);
    },
    [onEditCapability, setEditCapabilityModalContext]
  );

  const breadcrumbs = useMemo(
    () => [
      {
        label: 'Capability sets',
        to: `/w/${workspaceId}/pm/capability-sets`,
      },
    ],
    [workspaceId]
  );

  return (
    <div>
      <PanelHeader size="flex">
        <div className="panel-body-no-label px-4">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <div className="content-fluid">
            <div className="row">
              <div className="col-12 col-md-9">
                <h1>{capabilitySet?.name}</h1>
                {!isNullOrWhitespace(capabilitySet?.description) && (
                  <p>{capabilitySet?.description}</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <Nav className="tab-nav nav-underline">
            <NavItem>
              <NavLink
                tag={ReplaceLink}
                active={matchedTab === TABS.HIERARCHY}
                data-view="active"
                to={`/w/${workspaceId}/pm/capability-sets/${capabilitySetId}`}
              >
                Hierarchy
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                tag={ReplaceLink}
                data-view="results"
                to={`/w/${workspaceId}/pm/capability-sets/${capabilitySetId}/user-groups`}
                active={matchedTab === TABS.USER_GROUPS}
              >
                User Groups
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink
                tag={ReplaceLink}
                data-view="results"
                to={`/w/${workspaceId}/pm/capability-sets/${capabilitySetId}/settings`}
                active={matchedTab === TABS.SETTINGS}
              >
                Settings
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </PanelHeader>

      {capabilitySet && (
        <div className="content">
          <Route
            exact
            path="/w/:workspaceId/pm/capability-sets/:capabilitySetId"
          >
            <TreeContext.Provider value={context}>
              <CapabilitySetHierarchy
                capabilitySet={capabilitySet}
                onAddCapability={handleAddCapabilityModal}
                onEditCapability={handleEditCapabilityModal}
                onReparentCapability={onReparentCapability}
              />
              {addCapabilityModalContext && (
                <NewCapabilityModal
                  context={addCapabilityModalContext}
                  isOpen={true}
                  onSubmit={handleAddCapability}
                  onCancel={handleCancelAddCapability}
                />
              )}
              {editCapabilityModalContext && (
                <EditCapabilityModal
                  context={editCapabilityModalContext}
                  isOpen={true}
                  onSubmit={handleEditCapability}
                  onCancel={handleCancelEditCapability}
                />
              )}
            </TreeContext.Provider>
          </Route>
          <Route
            exact
            path="/w/:workspaceId/pm/capability-sets/:capabilitySetId/settings"
          >
            <CapabilitySetSettings
              capabilitySet={capabilitySet}
              onUpdate={onUpdate}
            />
          </Route>
          <Route
            exact
            path="/w/:workspaceId/pm/capability-sets/:capabilitySetId/user-groups"
          >
            <CapabilitySetUserGroups />
          </Route>
        </div>
      )}
    </div>
  );
}
