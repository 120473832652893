import { Map as YMap } from 'yjs';
import { randomBase32String } from 'js/utils/string';
import { buildPositionMap } from 'js/ydoc/docs/common/utils';
import {
  calcPositionBetween,
  generateCategoryOrdinalAfter,
  generateCategoryOrdinalBefore,
  nextCategoryColorIndex,
  nextCategoryOrdinal,
} from './utils';
import Big from 'big.js';

export function assignQuestionSetToNewCategory(
  doc,
  questionSetId,
  categoryName
) {
  doc.transact(() => {
    //add the new category
    const currentCategories = doc.getMap('config').get('categories');
    const nextOrdinal = nextCategoryOrdinal(currentCategories);
    const nextColorIndex = nextCategoryColorIndex(currentCategories);
    const key = `c-${randomBase32String(10)}`;
    const cat = {
      display_name: categoryName,
      ordinal: nextOrdinal,
      colorIndex: nextColorIndex,
    };
    doc
      .getMap('config')
      .set('categories', { ...currentCategories, [key]: cat });

    //assign it to the question set
    doc.getMap('pages').get(questionSetId)?.set('category', key);

    clearUnusedCategories(doc);
  });
}

export function assignQuestionSetToCategory(doc, questionSetId, categoryKey) {
  doc.getMap('pages').get(questionSetId)?.set('category', categoryKey);
  clearUnusedCategories(doc);
}

export function deletePage(doc, pageId) {
  doc.transact(() => {
    //Delete elements in the set
    for (const e of doc.getMap('elements').values()) {
      if (e.get('position').get('pageElements').get('t') === pageId) {
        deleteElement(doc, e.get('guid'));
      }
    }

    doc.getMap('pages').delete(pageId);
    clearUnusedCategories(doc);
  });
}

export function deleteElement(doc, elementId) {
  doc.getMap('elements')?.delete(elementId);
}

export function updateCategory(doc, guid, cat) {
  const currentCategories = doc.getMap('config').get('categories');
  doc.getMap('config').set('categories', { ...currentCategories, [guid]: cat });
}

export function addCategory(doc, name, explicitlyCreated = false) {
  //add the new category
  const currentCategories = doc.getMap('config').get('categories');
  const nextOrdinal = nextCategoryOrdinal(currentCategories);
  const nextColorIndex = nextCategoryColorIndex(currentCategories);
  const key = `c-${randomBase32String(10)}`;
  const cat = {
    display_name: name,
    ordinal: nextOrdinal,
    colorIndex: nextColorIndex,
    explicitlyCreated,
  };
  doc.getMap('config').set('categories', { ...currentCategories, [key]: cat });

  return key;
}

export function deleteCategory(doc, categoryId) {
  doc.transact(() => {
    const cats = doc.getMap('config').get('categories');
    const newCats = {};
    for (const [key, cat] of Object.entries(cats)) {
      if (key !== categoryId) {
        newCats[key] = cat;
      }
    }

    for (const [, qs] of doc.getMap('questionSets')) {
      if (qs.get('category') === categoryId) {
        qs.set('category', null);
      }
    }

    doc.getMap('config').set('categories', newCats);
  });
}

export function clearUnusedCategories(doc) {
  const catEntries = Object.entries(doc.getMap('config').get('categories'));
  const newCats = {};
  const questionSets = [...doc.getMap('pages').values()].filter(
    (p) => p.get('pageType') === 'question-set'
  );

  let isDiff = false;
  catEntries.forEach(([key, cat]) => {
    if (
      cat.explicitlyCreated ||
      questionSets.some((qs) => qs.get('category') === key)
    ) {
      newCats[key] = cat;
    } else {
      isDiff = true;
    }
  });

  if (isDiff) {
    doc.getMap('config').set('categories', newCats);
  }
}

export function addQuestionToSet(doc, questionSetId, type, initialText) {
  const guid = randomBase32String(10);
  const q = new YMap();
  q.set('elementType', 'question');
  q.set('text', initialText);
  q.set('guid', guid);
  q.set('key', `q-${guid}`);
  q.set('type', type);

  const position = buildPositionMap(
    doc.getMap('elements'),
    'pageElements',
    questionSetId
  );

  q.set('position', position);
  doc.getMap('elements').set(guid, q);

  return guid;
}

export function addQuestionSet(doc, initialName) {
  const guid = randomBase32String(10);
  const qs = new YMap();
  qs.set('pageType', 'question-set');
  qs.set('name', initialName);
  qs.set('guid', guid);
  qs.set('key', `qs-${guid}`);

  const position = buildPositionMap(
    doc.getMap('pages'),
    'assessmentPages',
    'assessmentTemplate'
  );

  qs.set('position', position);
  doc.getMap('pages').set(guid, qs);

  return guid;
}

export function copyQuestion(doc, questionIdToCopy) {
  const questionToCopy = doc.getMap('elements')?.get(questionIdToCopy);
  const questionToCopyPosition = questionToCopy
    .get('position')
    .get('pageElements');
  const followingPos = getFollowingPos(
    doc,
    questionToCopyPosition.get('p'),
    'elements',
    'pageElements',
    questionToCopyPosition.get('t')
  );

  const guid = randomBase32String(10);
  const q = new YMap();
  q.set('elementType', 'question');
  q.set('text', `Copy of: ${questionToCopy.get('text')}`);
  q.set('guid', guid);
  q.set('key', `q-${guid}`);
  q.set('type', questionToCopy.get('type'));

  const position = buildPositionMap(
    doc.getMap('elements'),
    'pageElements',
    questionToCopyPosition.get('t'),
    calcPositionBetween(
      Big(questionToCopyPosition.get('p')),
      Big(followingPos ?? 1)
    ).toString()
  );

  q.set('position', position);
  doc.getMap('elements').set(guid, q);
}

export function moveElementToPage(doc, elementId, pageId) {
  const element = doc.getMap('elements')?.get(elementId);
  if (element) {
    const position = element.get('position').get('pageElements');
    const lastPosition = getLastPosition(
      doc.getMap('elements'),
      'pageElements',
      pageId
    );
    const nextPosition = calcPositionBetween(Big(lastPosition), Big(1));

    position.set('t', pageId);
    position.set('p', nextPosition.toString());
  }
}

export function moveElementAfter(doc, sourceElementId, destinationElementId) {
  moveAfter(
    doc,
    'elements',
    'pageElements',
    sourceElementId,
    destinationElementId
  );
}

export function moveElementBefore(doc, sourceElementId, destinationElementId) {
  moveBefore(
    doc,
    'elements',
    'pageElements',
    sourceElementId,
    destinationElementId
  );
}

export function movePageAfter(doc, sourcePageId, destinationPageId) {
  moveAfter(doc, 'pages', 'assessmentPages', sourcePageId, destinationPageId);
}

export function movePageBefore(doc, sourcePageId, destinationPageId) {
  moveBefore(doc, 'pages', 'assessmentPages', sourcePageId, destinationPageId);
}

export function moveCategoryAfter(
  doc,
  sourceCategoryId,
  destinationCategoryId
) {
  const cats = doc.getMap('config').get('categories');
  const newOrdinal = generateCategoryOrdinalAfter(cats, destinationCategoryId);
  doc.getMap('config').set('categories', {
    ...cats,
    [sourceCategoryId]: { ...cats[sourceCategoryId], ordinal: newOrdinal },
  });
}

export function moveCategoryBefore(
  doc,
  sourceCategoryId,
  destinationCategoryId
) {
  const cats = doc.getMap('config').get('categories');
  const newOrdinal = generateCategoryOrdinalBefore(cats, destinationCategoryId);
  doc.getMap('config').set('categories', {
    ...cats,
    [sourceCategoryId]: { ...cats[sourceCategoryId], ordinal: newOrdinal },
  });
}

export function moveBefore(
  doc,
  collectionName,
  positionKey,
  sourceId,
  destinationId
) {
  const sourcePosition = doc
    .getMap(collectionName)
    ?.get(sourceId)
    ?.get('position')
    ?.get(positionKey);

  const destPosition = doc
    .getMap(collectionName)
    ?.get(destinationId)
    ?.get('position')
    ?.get(positionKey);

  if (
    !sourcePosition ||
    !destPosition ||
    !sourcePosition.get('p') ||
    !destPosition.get('p')
  ) {
    console.warn('invalid arguments to moveBefore');
    return;
  }

  //Move the source question to the same set as the destination question
  sourcePosition.set('t', destPosition.get('t'));

  let prevPos = getPreviousPos(
    doc,
    destPosition.get('p'),
    collectionName,
    positionKey,
    destPosition.get('t')
  );

  if (prevPos === null) {
    prevPos = '0';
  }

  //Set the source position before destination but before previous position
  const newPos = calcPositionBetween(Big(prevPos), Big(destPosition.get('p')));
  sourcePosition.set('p', newPos.toString());
}

function moveAfter(doc, collectionName, positionKey, sourceId, destinationId) {
  const sourcePosition = doc
    .getMap(collectionName)
    ?.get(sourceId)
    ?.get('position')
    ?.get(positionKey);

  const destPosition = doc
    .getMap(collectionName)
    ?.get(destinationId)
    ?.get('position')
    ?.get(positionKey);

  if (
    !sourcePosition ||
    !destPosition ||
    !sourcePosition.get('p') ||
    !destPosition.get('p')
  ) {
    console.warn(
      'invalid arguments to moveAfter',
      sourcePosition.get('p'),
      destPosition.get('p')
    );
    return;
  }

  //Move the source question to the same set as the destination question
  sourcePosition.set('t', destPosition.get('t'));

  let nextPosition = getFollowingPos(
    doc,
    destPosition.get('p'),
    collectionName,
    positionKey,
    destPosition.get('t')
  );

  if (nextPosition === null) {
    nextPosition = '1';
  }

  //Set the source position after destination but before following position
  const newPos = calcPositionBetween(
    Big(destPosition.get('p')),
    Big(nextPosition)
  );

  sourcePosition.set('p', newPos.toString());
}

function getFollowingPos(
  doc,
  positionNum,
  collectionName,
  positionKey,
  parentId
) {
  let followingPos = null;
  doc.getMap(collectionName).forEach((e) => {
    const qPosition = e.get('position')?.get(positionKey);
    if (
      qPosition.get('t') === parentId &&
      qPosition.get('p') > positionNum &&
      (followingPos === null || qPosition.get('p') < followingPos)
    ) {
      followingPos = qPosition.get('p');
    }
  });

  return followingPos;
}

function getPreviousPos(
  doc,
  positionNum,
  collectionName,
  positionKey,
  parentId
) {
  let previousPos = null;
  doc.getMap(collectionName).forEach((q) => {
    const qPosition = q.get('position')?.get(positionKey);
    if (
      qPosition.get('t') === parentId &&
      qPosition.get('p') < positionNum &&
      (previousPos === null || qPosition.get('p') > previousPos)
    ) {
      previousPos = qPosition.get('p');
    }
  });

  return previousPos;
}

function getLastPosition(currentEntityMap, parentType, parentId) {
  let lastPosition = '0';
  currentEntityMap.forEach((q) => {
    if (q.get('position').get(parentType).get('t') !== parentId) {
      return;
    }

    const position = q.get('position').get(parentType).get('p');
    if (lastPosition < position) {
      lastPosition = position;
    }
  });
  return lastPosition;
}
