import React from 'react';

export const ArrowMarker = ({
  id,
  markerWidth = 7,
  markerHeight = 7,
  refX = 5,
  refY = 3.5,
  ...props
}) => {
  return (
    <marker
      id={id}
      markerWidth={markerWidth}
      markerHeight={markerHeight}
      refX={refX}
      refY={refY}
      orient="auto-start-reverse"
    >
      <polygon
        points="0 0, 7 3.5, 0 7"
        className="arrow-marker"
        strokeLinecap="round"
      />
    </marker>
  );
};

export default ArrowMarker;
