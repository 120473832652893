import React from 'react';
import AnswerSet from './AnswerSet';

function AnswerSets({ answerSets }) {
  return (
    <>
      {Object.values(answerSets).map((answerSet) => (
        <AnswerSet key={answerSet.guid} answerSet={answerSet} />
      ))}
    </>
  );
}

export default AnswerSets;
