import Big from 'big.js';
import { Map as YMap } from 'yjs';
import { calcPositionBetween } from 'js/ydoc/docs/common/utils';
import { randomBase32String } from 'js/utils/string';
import { standardCollator } from 'js/utils/string';
import { getDefaultAnswerSetTypeForQuestionType } from '../answer-set-utils';
import { positionify } from 'js/ydoc/docs/common/utils';

const RANDOM_ID_STRING_LENGTH = 10;

// NOTE: If choices are supplied, they must include all properties, including position ('p') and guid properties
export function createAnswerSet(doc, answerSetProps) {
  answerSetProps = {
    type: 'scored',
    choices: [],
    includeNA: true,
    naText: 'N/A',
    ...answerSetProps,
  };

  const choices = positionify(answerSetProps.choices).reduce((acc, choice) => {
    const guid = choice.guid ?? `c-${randomBase32String(10)}`;
    acc[guid] = new YMap(Object.entries({ ...choice, guid }));
    return acc;
  }, {});

  const answerSet = new YMap();
  const guid = 'as-' + randomBase32String(10);
  answerSet.set('guid', guid);
  answerSet.set('type', answerSetProps.type);
  answerSet.set('choices', new YMap(Object.entries(choices)));
  answerSet.set('includeNA', answerSetProps.includeNA);
  answerSet.set('naText', answerSetProps.naText);

  doc.getMap('answerSets').set(guid, answerSet);

  return guid;
}

export function addAnswerSetChoice(doc, answerSetGuid, choice) {
  const answerSet = doc.getMap('answerSets').get(answerSetGuid);

  if (!answerSet) {
    throw new Error('Answer set not found');
  }

  const choiceMap = answerSet.get('choices');
  const choiceArray = Object.values(choiceMap.toJSON());

  const maxPosition = choiceArray.reduce((max, { p }) => {
    return p > max ? p : max;
  }, '0');

  const newPosition = calcPositionBetween(Big(maxPosition), Big('1'));

  const choiceAttributes = {
    guid: 'C' + randomBase32String(10),
    value: '' + (choiceArray.length + 1),
    text: `Choice ${choiceArray.length + 1}`,
    p: newPosition.toString(),
    ...choice,
  };

  choiceMap.set(
    choiceAttributes.guid,
    new YMap(Object.entries(choiceAttributes))
  );
}

export function deleteAnswerSetChoice(doc, answerSetGuid, choiceGuid) {
  const answerSet = doc.getMap('answerSets').get(answerSetGuid);
  const choiceMap = answerSet.get('choices');
  choiceMap.delete(choiceGuid);
}

export function updateAnswerSetChoice(doc, answerSetGuid, choiceGuid, updates) {
  const answerSet = doc.getMap('answerSets').get(answerSetGuid);
  const choice = answerSet.get('choices').get(choiceGuid);

  doc.transact(() => {
    for (const [key, value] of Object.entries(updates)) {
      choice.set(key, value);
    }
  });
}

function findChoiceWithNeighbors(choices, targetChoiceId) {
  const orderedChoices = Object.values(choices).sort((a, b) =>
    standardCollator.compare(a.p, b.p)
  );
  const result = {
    before: null,
    target: null,
    after: null,
  };

  for (let i = 0; i < orderedChoices.length; i++) {
    const choice = orderedChoices[i];

    if (choice.id ?? choice.guid === targetChoiceId) {
      result.target = choice;
    } else if (result.target === null) {
      result.before = choice;
    } else {
      result.after = choice;
      break;
    }

    // never found the target
    if (i === orderedChoices.length - 1 && result.target === null) {
      result.before = null;
    }
  }

  return result;
}

export function moveAnswerSetChoiceBefore(
  yDoc,
  answerSetGuid,
  sourceChoiceGuid,
  targetChoiceGuid
) {
  const choices = yDoc.get('answerSets')?.get(answerSetGuid)?.get('choices');
  const choice = choices.get(sourceChoiceGuid);

  // didn't find the choice or trying to move it before itself
  if (
    choices == null ||
    choice == null ||
    sourceChoiceGuid === targetChoiceGuid
  ) {
    return;
  }

  const { before, target } = findChoiceWithNeighbors(
    choices.toJSON(),
    targetChoiceGuid
  );

  // target not found || it already is immediately before the target
  if (target === null || sourceChoiceGuid === (before?.id ?? before?.guid)) {
    return;
  }

  const newPosition = calcPositionBetween(Big(before?.p ?? 0), Big(target.p));
  choice.set('p', newPosition.toString());
}

export function moveAnswerSetChoiceAfter(
  yDoc,
  answerSetGuid,
  sourceChoiceGuid,
  targetChoiceGuid
) {
  const choices = yDoc.get('answerSets')?.get(answerSetGuid)?.get('choices');
  const choice = choices.get(sourceChoiceGuid);

  // didn't find the choice or trying to move it after itself
  if (
    choices == null ||
    choice == null ||
    sourceChoiceGuid === targetChoiceGuid
  ) {
    return;
  }

  const { target, after } = findChoiceWithNeighbors(
    choices.toJSON(),
    targetChoiceGuid
  );

  // target not found || it already is immediately after the target
  if (target === null || sourceChoiceGuid === (after?.id ?? after?.guid)) {
    return;
  }

  const newPosition = calcPositionBetween(Big(after?.p ?? 1), Big(target.p));
  choice.set('p', newPosition.toString());
}

// function constructNewAnswerSet(answerSet, options) {
//   options = {
//     retainStandardFields: false,
//     preserveChoiceIds: false,
//     name: null,
//     ...options,
//   };

//   const overrides = options.retainStandardFields
//     ? {}
//     : {
//         id: `AS-${randomBase32String(RANDOM_ID_STRING_LENGTH)}`,
//         isStandard: false,
//         name: options.name ?? answerSet.name + ' (Copy)',
//       };

//   const newAnswerSet = {
//     ...answerSet,
//     ...overrides,
//   };

//   const newYMapAnswerSet = new YMap(
//     Object.entries({
//       ...newAnswerSet,
//       choices: new YMap(
//         Object.entries(newAnswerSet.choices).map(([_key, choice]) => {
//           const newId = options.preserveChoiceIds
//             ? choice.id
//             : `AC-${randomBase32String(RANDOM_ID_STRING_LENGTH)}`;
//           return [
//             newId,
//             new YMap(
//               Object.entries({
//                 ...choice,
//                 id: newId,
//               })
//             ),
//           ];
//         })
//       ),
//     })
//   );

//   return { js: newAnswerSet, ymap: newYMapAnswerSet };
// }

export function createCustomAnswerSet(
  yDoc,
  questionId,
  answerSetProps,
  options = {}
) {
  options = {
    isCapabilityQuestion: false,
    ...options,
  };

  const defaultProps = {
    name: null,
    isStandard: false,
    isPublished: false,
    choices: {},
  };
  const answerSetId = `AS-${randomBase32String(RANDOM_ID_STRING_LENGTH)}`;
  const questionType = yDoc.getMap('elements').get(questionId)?.get('type');
  const type = getDefaultAnswerSetTypeForQuestionType(questionType);

  if (type == null) return;

  answerSetProps = {
    ...defaultProps,
    ...answerSetProps,
    id: answerSetId,
    type,
    choices: new YMap(
      Object.entries(answerSetProps.choices ?? defaultProps.choices).map(
        ([_key, choice], i, arr) => {
          const newId = `AC-${randomBase32String(RANDOM_ID_STRING_LENGTH)}`;
          const position = choice.p ?? '' + (i + 1) / (arr.length + 1);
          return [
            newId,
            new YMap(
              Object.entries({
                ...choice,
                id: newId,
                p: position,
              })
            ),
          ];
        }
      )
    ),
  };

  yDoc.transact(() => {
    let question = null;
    if (options.isCapabilityQuestion) {
      question = yDoc.getMap('elements').get(questionId);
    } else {
      question = yDoc.getMap('capabilityElements').get(questionId);
    }

    if (!(question instanceof YMap)) {
      console.warn('Question not found');
      return;
    }

    const answerSets = yDoc.getMap('answerSets');
    answerSets.set(answerSetId, new YMap(Object.entries(answerSetProps)));
    question.set('answerSetId', answerSetId);
  });

  return answerSetId;
}

export function setAnswerSetForQuestion(yDoc, questionId, answerSetProps) {
  yDoc.transact(() => {
    const question = yDoc.getMap('elements').get(questionId);
    question.set('answerSet', answerSetProps);
  });
}
