import React from 'react';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import PageReview from './PageReview';

import styles from './ParticipantResults.module.scss';

function ParticipantResponses({ participant, assessmentDocument, responses }) {
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h3" className={styles['participant-name']}>
          {participant.name}
        </CardTitle>
      </CardHeader>
      <CardBody>
        {assessmentDocument.pages.map((page) => {
          return (
            <PageReview key={page.guid} page={page} responses={responses} />
          );
        })}
      </CardBody>
    </Card>
  );
}

export default ParticipantResponses;
