import React, { useCallback, useMemo, useRef, useState } from 'react';
import Range from 'js/components/Forms/Range';
import Checkbox from 'js/components/Forms/Checkbox';
import { useResponseContext } from '../../../../../ResponseContext';
import styles from './CapabilitiesPage.module.scss';
function CapabilityQuestion({
  capability,
  question,
  max,
  choices,
  response,
  questionId,
}) {
  const { respond, participant } = useResponseContext();
  const [holdValue, setHoldValue] = useState(false);
  const [heldValue, setHeldValue] = useState(null);
  const timeoutRef = useRef(null);
  const clearResponseTimeout = useCallback(() => {
    setHoldValue(false);
    setHeldValue(null);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
  }, []);

  const selectedIndex = useMemo(() => {
    if (holdValue) {
      return heldValue;
    }

    if (response) {
      return choices.findIndex((c) => c.value === response.value);
    }

    return Math.floor(choices.length / 2);
  }, [response, choices, holdValue, heldValue]);

  const baseResponse = useMemo(() => {
    return response
      ? {
          id: response.id,
          participant_id: participant.id,
        }
      : {
          participant_id: participant.id,
        };
  }, [participant.id, response]);

  const handleChange = useCallback(
    async (e) => {
      setHoldValue(true);
      setHeldValue(e.target.value);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(async () => {
        await respond(questionId, {
          ...baseResponse,
          value: choices[e.target.value].value,
          is_na: false,
        });
        clearResponseTimeout();
      }, 1000);
    },
    [
      respond,
      questionId,
      clearResponseTimeout,
      timeoutRef,
      baseResponse,
      choices,
    ]
  );

  const handleNaChange = useCallback(() => {
    clearResponseTimeout();
    return respond(questionId, {
      ...baseResponse,
      value: null,
      is_na: true,
    });
  }, [respond, questionId, baseResponse, clearResponseTimeout]);

  const responded = useMemo(() => {
    return !!(response && response.is_na === false);
  }, [response]);

  const handleClick = useCallback(
    (e) => {
      if (!responded) {
        clearResponseTimeout();
        return respond(questionId, {
          ...baseResponse,
          value: choices[e.target.value].value,
          is_na: false,
        });
      }
    },
    [
      respond,
      responded,
      questionId,
      baseResponse,
      choices,
      clearResponseTimeout,
    ]
  );

  const handleMouseUp = useCallback(() => {
    if (timeoutRef.current) {
      clearResponseTimeout();
      return respond(questionId, {
        ...baseResponse,
        value: choices[heldValue].value,
        is_na: false,
      });
    }
  }, [
    choices,
    questionId,
    baseResponse,
    heldValue,
    clearResponseTimeout,
    respond,
  ]);

  const currentValue = useMemo(
    () => (response ? choices[selectedIndex]?.text : '') ?? '',
    [response, choices, selectedIndex]
  );

  return (
    <div className={styles['capabilities-set-question']}>
      <div>
        <div>{capability.name}</div>
        <div
          className={styles['capabilities-set-capability-description']}
          title={capability.description}
        >
          {capability.description}
        </div>
      </div>
      <div className={styles['capabilities-set-question-range']}>
        <div className={styles['capabilities-set-question-range-label']}>
          <span className={styles['clamp-2']}>{currentValue}</span>
        </div>
        <div className={styles['capabilities-set-question-range-input']}>
          <Range
            value={selectedIndex}
            responded={responded}
            type="range"
            list={question.answerSetId + '-datalist'}
            min="0"
            max={max}
            step="1"
            onChange={handleChange}
            onClick={handleClick}
            onMouseUp={handleMouseUp}
          />
        </div>
        <div className={styles['capabilities-set-question-range-na']}>
          <Checkbox
            label="NA"
            checked={response && response.is_na}
            onChange={handleNaChange}
          >
            N/A
          </Checkbox>
        </div>
      </div>
    </div>
  );
}

export default CapabilityQuestion;
