import React from 'react';
import { useResponseContext } from 'js/engines/CapabilitiesAssessment/ResponseContext';
import PageIndicator from './PageIndicator';
import classNames from 'classnames';

function getPageTitle(page, assessment) {
  if (page.type === 'question-set') {
    return page.name;
  }

  if (page.type === 'capability-questions') {
    return (
      page.title ??
      assessment.document.capabilitySetCache[page.capabilitySetId]?.name
    );
  }

  return page.type;
}

const ProgressSlider = ({ assessment, currentPageIndex }) => {
  const pages = assessment.document.pages;
  const currentPage = pages[currentPageIndex];
  const { responses } = useResponseContext();

  return (
    <div className="progress-slider">
      <div className="progress-text">
        Step {currentPageIndex + 1} of {pages.length}:{' '}
        {getPageTitle(currentPage, assessment)}
      </div>
      <div className="pages">
        {pages.map((page, index) => {
          return (
            <PageIndicator
              className={classNames({
                past: currentPageIndex > index,
                current: currentPageIndex === index,
                future: currentPageIndex < index,
              })}
              key={page.guid}
              page={page}
              assessment={assessment}
              responses={responses}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ProgressSlider;
