import {
  SchemaEntity,
  SchemaSingletonEntity,
  SchemaExclusiveArray,
  SchemaArray,
  SchemaSet,
} from 'js/ydoc/docs/common/schema/schema';

const answerSetsEntity = new SchemaEntity('answerSets', {});
const answerSetsSet = new SchemaSet(answerSetsEntity);

const elements = new SchemaEntity('elements', {}, { idAttribute: 'guid' });
const elementsArray = new SchemaExclusiveArray('pageElements', elements);
const capabilityElementsEntity = new SchemaEntity(
  'capabilityElements',
  {},
  { idAttribute: 'guid' }
);
const capabilityElementsArray = new SchemaArray(capabilityElementsEntity, {
  positionProperty: 'capabilityElementPositions',
});
const capabilityElementsSet = new SchemaSet(capabilityElementsEntity);

const pagesEntity = new SchemaEntity(
  'pages',
  { elements: elementsArray, capabilityElements: capabilityElementsArray },
  { idAttribute: 'guid' }
);
const pagesArray = new SchemaExclusiveArray('assessmentPages', pagesEntity);

const capabilityEntity = new SchemaEntity(
  'capabilityCache',
  {},
  { idAttribute: 'guid' }
);
const capabilityCache = new SchemaSet(capabilityEntity);
const capabilitySetEntity = new SchemaEntity(
  'capabilitySetCache',
  {},
  { idAttribute: 'guid' }
);
const capabilitySetCache = new SchemaSet(capabilitySetEntity);

const metaSingleton = new SchemaSingletonEntity('meta');

const assessmentDocumentSingleton = new SchemaSingletonEntity(
  'assessmentDocument',
  {
    meta: metaSingleton,
    pages: pagesArray,
    answerSets: answerSetsSet,
    capabilityElements: capabilityElementsSet,
    capabilityCache,
    capabilitySetCache,
  }
);

export const schemaStructure = assessmentDocumentSingleton;
