import React from 'react';
import { useHasValue } from '../../useHasValue';

// import { useCollaboratorForField } from 'js/components/Assessment/Editor/collaborator-hooks';
import useEditorInput from '../../useEditorInput';
import { FormGroup, FormFeedback, Input } from 'reactstrap';
import ErrorMessage from 'js/components/Assessment/Editors/common/components/EditorErrors/ErrorMessage';

import MarkdownInput from './MarkdownInput';

import toolStyles from '../EditorTool.module.scss';

export function Metadata({ assessment, onChange = () => {} }) {
  // const collaboratorForName = useCollaboratorForField('meta', '', 'name');
  // const collaboratorForDescription = useCollaboratorForField(
  //   'meta',
  //   '',
  //   'description'
  // );

  const meta = assessment?.meta;

  const handleNameChange = (name) => onChange({ name });
  const handleDescriptionChange = (description) => onChange({ description });
  const handleKeyChange = (key) => onChange({ key });

  const {
    reset: resetName,
    ref: nameRef,
    ...nameInputProps
  } = useEditorInput({
    value: meta?.name ?? '',
    onCommit: handleNameChange,
  });

  const {
    reset: resetKey,
    ref: keyRef,
    ...keyInputProps
  } = useEditorInput({
    value: meta?.key ?? '',
    onCommit: handleKeyChange,
  });

  const keyInvalid = !useHasValue(meta?.key);
  const nameInvalid = !useHasValue(meta?.name);
  const descriptionInvalid = !useHasValue(meta?.description);

  if (!assessment) {
    return null;
  }

  return (
    <div className={toolStyles['tool-body']}>
      <FormGroup>
        <label>Assessment Key</label>
        <Input
          type="text"
          innerRef={keyRef}
          invalid={keyInvalid}
          {...keyInputProps}
        />
        <FormFeedback valid={false}>
          <ErrorMessage>Key is required</ErrorMessage>
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <label>Name</label>
        <div>
          <Input
            type="text"
            innerRef={nameRef}
            invalid={nameInvalid}
            {...nameInputProps}
          />
          <FormFeedback valid={false}>
            <ErrorMessage>Name is required</ErrorMessage>
          </FormFeedback>
        </div>
      </FormGroup>
      <FormGroup>
        <label>Description</label>
        <MarkdownInput
          value={meta?.description}
          placeholder="Add a description"
          invalid={descriptionInvalid}
          onCommit={handleDescriptionChange}
          feedback={
            <FormFeedback valid={false}>
              <ErrorMessage>Description is required</ErrorMessage>
            </FormFeedback>
          }
        />
      </FormGroup>
    </div>
  );
}

export default Metadata;
