import keymirror from 'keymirror';
import { request } from 'js/api/axios';
import { callApi } from '../api';
import { SCHEMAS } from 'js/api/schemas';
import { spreadApiKeys, extractApiKeys } from '../utils';

export const types = keymirror({
  ...spreadApiKeys('LOAD_USER_GROUP_ASSESSMENT'),
  ...spreadApiKeys('LOAD_USER_GROUP_ASSESSMENTS'),
  ...spreadApiKeys('CREATE_USER_GROUP_ASSESSMENT'),
  ...spreadApiKeys('UPDATE_USER_GROUP_ASSESSMENT'),
  ...spreadApiKeys('GO_LIVE'),
});

export function list(userGroupId, queryId) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.LOAD_USER_GROUP_ASSESSMENTS, types),
        request: (_data, token, currentWorkspaceId) =>
          request.get(
            `/api/workspaces/${currentWorkspaceId}/user-groups/${userGroupId}/assessments`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        schema: SCHEMAS.USER_GROUP_ASSESSMENT_ARRAY,
        meta: { queryId },
      },
      dispatch
    );
  };
}

export function load(userGroupId, id) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.LOAD_USER_GROUP_ASSESSMENT, types),
        request: (_data, token, currentWorkspaceId) =>
          request.get(
            `/api/workspaces/${currentWorkspaceId}/user-groups/${userGroupId}/assessments/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        schema: SCHEMAS.USER_GROUP_ASSESSMENT,
      },
      dispatch
    );
  };
}

export function create(userGroupId, userGroupAssessment) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.CREATE_USER_GROUP_ASSESSMENT, types),
        request: (data, token, currentWorkspaceId) =>
          request.post(
            `/api/workspaces/${currentWorkspaceId}/user-groups/${userGroupId}/assessments`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        data: { user_group_assessment: userGroupAssessment },
        schema: SCHEMAS.USER_GROUP_ASSESSMENT,
      },
      dispatch
    );
  };
}

export function goLive(userGroupId, userGroupAssessmentId, assessment) {
  return (dispatch, getState) => {
    return callApi(
      {
        types: extractApiKeys(types.GO_LIVE, types),
        request: (data, token, currentWorkspaceId) =>
          request.post(
            `/api/workspaces/${currentWorkspaceId}/user-groups/${userGroupId}/assessments/${userGroupAssessmentId}/go-live`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        data: { assessment },
        schema: SCHEMAS.ASSESSMENT,
      },
      dispatch,
      getState
    );
  };
}

// export function xcreate(props, initialState) {
//   const doc = new YDoc();

//   const initialStateWithId = {
//     ...initialState,
//     meta: { ...initialState?.meta, key: `assessment-${doc.guid}` },
//   };

//   initializeYDoc(doc, initialStateWithId, this);
//   const qsId = addQuestionSet(doc, 'New question set');
//   addQuestionToSet(doc, qsId, 'likert', 'Enter the question text');

//   const update = encodeStateAsUpdate(doc);

//   return (dispatch) => {
//     return callApi(
//       {
//         types: extractApiKeys(types.CREATE_ASSESSMENT_TEMPLATE, types),
//         request: (data, token, currentWorkspaceId) =>
//           request.post(
//             `/api/workspaces/${currentWorkspaceId}/assessment-doc-templates`,
//             data,
//             {
//               headers: {
//                 Authorization: `Bearer ${token}`,
//               },
//             }
//           ),
//         data: {
//           assessment_doc_template: {
//             ...props,
//             initial_update: [...update.values()],
//           },
//         },
//         schema: SCHEMAS.ASSESSMENT_DOC_TEMPLATE,
//       },
//       dispatch
//     );
//   };

//   // const res = await request.post(
//   //   `/api/workspaces/${currentWorkspaceId}/assessment-doc-templates`,
//   //   {
//   //     assessment_doc_template: {
//   //       initial_update: [...update.values()],
//   //     },
//   //   },
//   //   {
//   //     headers: {
//   //       Authorization: `Bearer ${token}`,
//   //     },
//   //   }
//   // );
// }

export function update(userGroupId, id, updates) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.UPDATE_USER_GROUP_ASSESSMENT, types),
        request: (data, token, currentWorkspaceId) =>
          request.patch(
            `/api/workspaces/${currentWorkspaceId}/user-groups/${userGroupId}/assessments/${id}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        data: { user_group_assessment: updates },
        meta: { patch: { userGroupAssessments: { [id]: updates } } },
        schema: SCHEMAS.USER_GROUP_ASSESSMENT,
      },
      dispatch
    );
  };
}
