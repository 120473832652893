import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import * as buffer from 'lib0/buffer';

// Actions
import {
  createCustomAnswerSet,
  assignAnswerSetToQuestion,
  updateAnswerSet,
} from './Binding/answer-set-actions';
import { list as listTeams } from 'js/actions/team-actions';
import { createBinding } from './Binding';
import {
  addCategory,
  addQuestionSet,
  addQuestionToSet,
  assignQuestionSetToCategory,
  assignQuestionSetToNewCategory,
  copyQuestion,
  deleteCategory,
  deleteElement,
  deletePage,
  moveCategoryAfter,
  moveCategoryBefore,
  moveElementAfter,
  moveElementBefore,
  moveElementToPage,
  movePageAfter,
  movePageBefore,
  updateCategory,
} from './Binding/doc-utils';
import { notify } from 'js/actions/alert-actions';

// Routing
import {
  Route,
  Switch,
  // useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom/cjs/react-router-dom.min';

// JS functionality
import classNames from 'classnames';
import { randomBase32String } from 'js/utils/string';

// Engines
// import { getEngine } from 'js/engines';

// Drag & drop
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';

// Icons
import {
  faRotateLeft,
  faRotateRight,
  faSliders,
  faArrowRightFromLine,
} from 'js/components/Icons/imports/solid';

// Data hooks & js
import { SCHEMAS } from 'js/api';
import { denormalize } from 'normalizr';
import { useStore, useDispatch, useSelector } from 'react-redux';
import { AssessmentValidator } from './validation/validators';

// Context & Hooks
import { AssessmentContext } from '../AssessmentContext';
import { DocumentProvider, useDoc, useProviders } from 'js/ydoc';
import { useAssessment } from './useAssessment';
import useWindowDimensions from 'js/utils/window-dimensions-hook';
import { useCollaborators } from 'js/components/Assessment/Editor/collaborator-hooks';
import useAnswerSetStats from './useAnswerSetStats';
import { useAnswerSetGuard } from './useAnswerSetGuard';

// Sidebar tools
import { CategoryTool } from './EditorTools/CategoryTool';
import { AnswerSetTool } from './EditorTools/AnswerSetTool';
import { MetadataAndHistoryTool } from './EditorTools/MetadataAndHistoryTool';

// Library components
import {
  Alert,
  // Dropdown,
  // DropdownItem,
  // DropdownMenu,
  // DropdownToggle,
} from 'reactstrap';
import { Icon, ReplaceLink } from 'js/components';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import ConfirmModal from 'js/components/modal/ConfirmModal';

// Custom Editor Components
import { QuestionSet } from './QuestionSets/QuestionSet';
import CollaboratorList from 'js/components/CollaboratorList/CollaboratorList';
import EditorPreview from './Preview/EditorPreview';
import EditorErrors from 'js/components/Assessment/Editors/common/components/EditorErrors';

// Styles
import styles from './Editor.module.scss';
import AssessmentType from './EditorTools/MetadataAndHistoryTool/AssessmentType';
import { snapshot, encodeSnapshot } from 'yjs';
import {
  listRevisions,
  publishRevision,
} from 'js/actions/assessment-template-actions';
import AsyncButton from 'js/components/Forms/AsyncButton';
// import { validateAssessment } from './validation/validateAssessment';

const UNDO_ALERT_TIMEOUT = 10000;

const validator = new AssessmentValidator();

export function Editor({ assessmentDocTemplate }) {
  const token = useSelector((state) => state.identity.token);
  if (token) {
    return (
      <DocumentProvider
        channelProviderToken={token}
        channelProviderTopic={`crdt:assessment-doc-template:${assessmentDocTemplate.crdt_doc_id}`}
      >
        <EditorInterface docTemplateId={assessmentDocTemplate.id} />
      </DocumentProvider>
    );
  }
}

export function EditorInterface({ docTemplateId }) {
  const ydoc = useDoc();
  const providers = useProviders();
  const { assessment, binding } = useAssessment(createBinding);

  // const validationErrors = useMemo(
  //   // () => validateAssessment(assessment),
  //   () => validator.validateAssessment(assessment),
  //   [assessment]
  // );
  const validationErrors = validator.validateAssessment(assessment);

  const collaborators = useCollaborators();
  const [alertWithUndo, setAlertWithUndo] = useState(null);
  const mainPaneRef = useRef(null);
  const windowDims = useWindowDimensions();
  const [showModalSettings, setShowModalSettings] = useState(false);
  const match = useRouteMatch();
  const location = useLocation();
  const assessmentType = 'survey'; //TODO: support other survey types (matters for the preivew)
  const dispatch = useDispatch();
  const store = useStore();
  // const [isLaunchMenuOpen, setIsLaunchMenuOpen] = useState(false);
  // const teamsQuery = useSelector(
  //   (state) => state.queries['active-teams-for-editor']
  // );
  // const teams = useMemo(() => {
  //   return denormalize(
  //     teamsQuery?.result || [],
  //     SCHEMAS.TEAM_ARRAY,
  //     store.getState().entities
  //   );
  // }, [store, teamsQuery]);

  const revisionsQuery = useSelector(
    (state) => state.queries[`${docTemplateId}-revisions`]
  );
  const [revisions, setRevisions] = useState([]);
  useEffect(() => {
    if (revisionsQuery?.isLoading === false) {
      setRevisions(
        denormalize(
          revisionsQuery?.result || [],
          SCHEMAS.ASSESSMENT_DOC_TEMPLATE_REVISION_ARRAY,
          store.getState().entities
        )
      );
    }
  }, [
    store,
    revisionsQuery,
    revisionsQuery?.isLoading,
    revisionsQuery?.result,
  ]);

  const undo = useCallback(
    () => binding.undoManager.undo(),
    [binding.undoManager]
  );
  const redo = useCallback(
    () => binding.undoManager.redo(),
    [binding.undoManager]
  );

  // const history = useHistory();
  const currentWorkspaceId = useSelector((state) => state.currentWorkspaceId);
  const [showingErrorModal, setShowingErrorModal] = useState(false);
  const [errorModalMessage] = useState('');
  const answerSetStats = useAnswerSetStats(assessment);

  const {
    moveAnswerSetChoiceAfter,
    moveAnswerSetChoiceBefore,
    // createCustomAnswerSet,
    createAnswerSetChoice,
    // assignAnswerSetToQuestion,
    // updateAnswerSet,
    updateAnswerSetChoice,
    deleteAnswerSetChoice,
    Modal: AnswerSetModal,
  } = useAnswerSetGuard({
    yDoc: ydoc,
    answerSets: assessment?.answerSets,
    answerSetStats: answerSetStats,
  });

  useEffect(() => {
    ydoc.transact(() => {
      Object.entries(ydoc.getMap('answerSets').toJSON()).forEach(
        ([key, as]) => {
          const answerSet = ydoc.getMap('answerSets').get(key);
          const choices = answerSet.get('choices');
          Object.entries(as.choices).forEach(([choiceKey, choice]) => {
            if (typeof choice.value !== 'string') {
              choices
                .get(choiceKey)
                .set('value', '' + (choice.value ?? choice.score));
            }
          });
        }
      );
    });
  }, [ydoc]);

  // useEffect(() => {
  //   console.log(ydoc.toJSON());
  //   Object.entries(ydoc.getMap('questions').toJSON()).forEach(([key, q]) => {
  //     console.log(key);
  //     if (q.answerSetId !== undefined) {
  //       console.log(q.answerSetId);
  //     }
  //     if (q.answerSet !== undefined) {
  //       console.log('answerSet:', q.answerSet);
  //       ydoc.transact(() => {
  //         const question = ydoc.getMap('questions').get(key);
  //         question.set('answerSetId', q.answerSet);
  //         question.delete('answerSet');
  //       });
  //     }
  //   });
  // }, [assessment]);

  const isShowPreview = useMemo(() => {
    return location.pathname.includes('/edit/preview');
  }, [location]);

  const useModalSettings = useMemo(() => {
    return windowDims.width <= 1050;
  }, [windowDims]);

  useEffect(() => {
    if (!useModalSettings) {
      setShowModalSettings(false);
    }
  }, [useModalSettings]);

  const awareness = useMemo(() => {
    return providers.channelProvider?.awareness;
  }, [providers]);

  // TODO: use this to handle element field changes
  const handleQuestionFieldChange = useCallback(
    (id, field, value) => {
      ydoc.getMap('elements')?.get(id)?.set(field, value);
    },
    [ydoc]
  );

  const handleFieldFocusChange = useCallback(
    (type, parentId, field, focused) => {
      if (focused) {
        awareness.setLocalStateField('focusedField', {
          parentType: type,
          parentId: parentId,
          field,
        });
      } else {
        awareness.setLocalStateField('focusedField', null);
      }
    },
    [awareness]
  );

  const handlePageChange = useCallback(
    (id, field, value) => {
      ydoc.getMap('pages')?.get(id)?.set(field, value);
    },
    [ydoc]
  );

  const handleAddQuestion = useCallback(
    (questionSetId) => {
      addQuestionToSet(
        ydoc,
        questionSetId,
        'likert',
        'Enter the question text'
      );
    },
    [ydoc]
  );

  const initAlertWithUndo = useCallback(
    (msg) => {
      dispatch(
        notify({
          type: 'info',
          message: (
            <div className={styles.alertContent}>
              {msg}
              <button onClick={undo}>Undo</button>
            </div>
          ),
        })
      );
    },
    [dispatch, undo]
  );

  const clearAlert = useCallback(() => {
    clearTimeout(alertWithUndo?.timeout);

    setAlertWithUndo({
      ...alertWithUndo,
      show: false,
    });
  }, [alertWithUndo]);

  const clearAlertTimeout = useCallback(() => {
    clearTimeout(alertWithUndo?.timeout);
  }, [alertWithUndo]);

  const startAlertTimeout = useCallback(() => {
    setAlertWithUndo({
      ...alertWithUndo,
      timeout: setTimeout(() => {
        setAlertWithUndo({ msg: alertWithUndo.msg, show: false });
      }, UNDO_ALERT_TIMEOUT),
    });
  }, [alertWithUndo]);

  const handleCopyQuestion = useCallback(
    (questionToCopy) => {
      copyQuestion(ydoc, questionToCopy.guid);
    },
    [ydoc]
  );

  const handleAddQuestionSet = useCallback(() => {
    addQuestionSet(ydoc, 'New question set');
  }, [ydoc]);

  // TODO: rename this and use it to delete info pages as well
  const handleDeleteQuestionSet = useCallback(
    (set) => {
      deletePage(ydoc, set.guid);
      initAlertWithUndo('Question set deleted!');
    },
    [ydoc, initAlertWithUndo]
  );

  // TODO: rename this and use it to delete elements in general
  const handleDeleteQuestion = useCallback(
    (question) => {
      deleteElement(ydoc, question.guid);
      initAlertWithUndo('Question deleted!');
    },
    [ydoc, initAlertWithUndo]
  );

  const handleAssignSetToNewCategory = useCallback(
    (setId, categoryName) => {
      assignQuestionSetToNewCategory(ydoc, setId, categoryName);
    },
    [ydoc]
  );

  const handleAssignSetToCategory = useCallback(
    (setId, categoryId) => {
      assignQuestionSetToCategory(ydoc, setId, categoryId);
    },
    [ydoc]
  );

  const handleAnswerSetChoiceAdd = useCallback(
    (questionId, answerSetId, choiceProps) => {
      if (answerSetId == null) {
        // create a new answer set if the question doesn't currently have one
        createCustomAnswerSet(ydoc, questionId, {
          choices: { 1: choiceProps },
        });
      } else {
        // just add the choice
        createAnswerSetChoice(ydoc, answerSetId, choiceProps);
      }
    },
    [ydoc, createAnswerSetChoice]
  );

  const handleAnswerSetChoiceUpdate = useCallback(
    (answerSetId, choiceId, updates, questionId) => {
      updateAnswerSetChoice(ydoc, answerSetId, choiceId, updates, questionId);
    },
    [ydoc, updateAnswerSetChoice]
  );

  const handleAnswerSetChoiceDelete = useCallback(
    (answerSetId, choiceId) => {
      deleteAnswerSetChoice(ydoc, answerSetId, choiceId);
    },
    [ydoc, deleteAnswerSetChoice]
  );

  const handleAnswerSetChange = useCallback(
    (questionId, answerSet) => {
      assignAnswerSetToQuestion(ydoc, questionId, answerSet);
    },
    [ydoc]
  );

  const handleAnswerSetFieldChange = useCallback(
    (answerSetId, fields) => {
      updateAnswerSet(ydoc, answerSetId, fields);
    },
    [ydoc]
  );

  useEffect(() => {
    if (binding.undoManager) {
      binding.undoManager.on('stack-item-added', () => {
        setAlertWithUndo(null);
      });

      return () => {
        binding.undoManager.off('stack-item-added');
      };
    }
  }, [binding]);

  useEffect(() => {
    return monitorForElements({
      onDrop({ source, location }) {
        if (location.current.dropTargets.length <= 0) {
          return;
        }

        if (source.data.question) {
          if (location.current.dropTargets[0].data.questionSet) {
            moveElementToPage(
              ydoc,
              source.data.question.guid,
              location.current.dropTargets[0].data.questionSet.guid
            );
          } else {
            const closestEdge = extractClosestEdge(
              location.current.dropTargets[0].data
            );
            if (closestEdge === 'top') {
              moveElementBefore(
                ydoc,
                source.data.question.guid,
                location.current.dropTargets[0].data.question.guid
              );
            } else {
              moveElementAfter(
                ydoc,
                source.data.question.guid,
                location.current.dropTargets[0].data.question.guid
              );
            }
          }
        } else if (source.data.questionSet) {
          const closestEdge = extractClosestEdge(
            location.current.dropTargets[0].data
          );
          if (closestEdge === 'top') {
            movePageBefore(
              ydoc,
              source.data.questionSet.guid,
              location.current.dropTargets[0].data.questionSet.guid
            );
          } else {
            movePageAfter(
              ydoc,
              source.data.questionSet.guid,
              location.current.dropTargets[0].data.questionSet.guid
            );
          }
        } else if (source.data.category) {
          const closestEdge = extractClosestEdge(
            location.current.dropTargets[0].data
          );
          if (closestEdge === 'top') {
            moveCategoryBefore(
              ydoc,
              source.data.category.guid,
              location.current.dropTargets[0].data.category.guid
            );
          } else {
            moveCategoryAfter(
              ydoc,
              source.data.category.guid,
              location.current.dropTargets[0].data.category.guid
            );
          }
        } else if (source.data.choice) {
          const {
            choice: sourceChoice,
            index: sourceIndex,
            answerSetId,
            questionId,
          } = source.data;
          const { choice: dropTargetChoice, index: dropTargetIndex } =
            location.current.dropTargets[0].data;
          const closestEdge = extractClosestEdge(
            location.current.dropTargets[0].data
          );

          const moveBefore = closestEdge === 'top' || closestEdge === 'left';

          if (moveBefore && sourceIndex !== dropTargetIndex - 1) {
            moveAnswerSetChoiceBefore(
              ydoc,
              answerSetId,
              sourceChoice.id,
              dropTargetChoice.id,
              questionId
            );
          } else if (!moveBefore && sourceIndex !== dropTargetIndex + 1) {
            moveAnswerSetChoiceAfter(
              ydoc,
              answerSetId,
              sourceChoice.id,
              dropTargetChoice.id,
              questionId
            );
          }
        }
      },
    });
  }, [ydoc, moveAnswerSetChoiceBefore, moveAnswerSetChoiceAfter]);

  useEffect(() => {
    const mainPaneEl = mainPaneRef.current;
    if (!mainPaneEl) throw new Error('No element found');
    return autoScrollForElements({
      element: mainPaneEl,
    });
  }, []);

  // useEffect(() => {
  //   if (!assessment) return;

  //   ydoc.transact(() => {
  //     Object.entries(assessment.answerSets).forEach(([asId, as]) => {
  //       if (!asId || asId === 'undefined') {
  //         ydoc.getMap('answerSets').delete(asId);
  //         return;
  //       }
  //       if (as.type == null) {
  //         ydoc.getMap('answerSets').get(asId).set('type', 'likert');
  //       }
  //     });
  //   });
  // }, [ydoc, assessment]);

  useEffect(() => {
    listTeams({ active: true }, 'active-teams-for-editor')(dispatch);
  }, [dispatch]);

  const handleCategoryChange = useCallback(
    (guid, cat) => {
      updateCategory(ydoc, guid, cat);
    },
    [ydoc]
  );

  const handleAddCategory = useCallback(
    (name) => {
      addCategory(ydoc, name, true);
    },
    [ydoc]
  );

  const handleDeleteCategory = useCallback(
    (guid) => {
      deleteCategory(ydoc, guid);
      initAlertWithUndo('Category deleted!');
    },
    [ydoc, initAlertWithUndo]
  );

  // const handleLaunch = useCallback(
  //   async (team) => {
  //     try {
  //       await providers.channelProvider?.forcePersist();
  //       const engine = getEngine({ id: 999, type: assessmentType });
  //       const response = await engine.strategies.createFromDocTemplate(
  //         team.id,
  //         docTemplateId
  //       )(dispatch, store.getState);
  //       history.push(
  //         `/w/${currentWorkspaceId}/teams/${team.id}/assessments/${response.id}/launch`
  //       );
  //     } catch (e) {
  //       console.error('Failed to launch assessment', e);
  //       setErrorModalMessage(
  //         'Sorry, there was a problem trying to launch the assessment. Please try again.'
  //       );
  //       setShowingErrorModal(true);
  //     }
  //   },
  //   [
  //     docTemplateId,
  //     dispatch,
  //     store,
  //     providers.channelProvider,
  //     currentWorkspaceId,
  //     history,
  //   ]
  // );

  const [revisionPublishedAt, setRevisionPublishedAt] = useState(null);

  const handlePublish = useCallback(() => {
    const revision = {
      version_id: randomBase32String(10),
      name: assessment.meta.name,
      description: assessment.meta.description,
      is_configurable: true,
      configuration: assessment.config,
      views: {
        orgDetail: ['recommendations'],
      },
      crdt_snapshot: buffer.toBase64(encodeSnapshot(snapshot(ydoc))),
      snapshot_version: 'v1',
    };

    return publishRevision(docTemplateId, revision)(
      dispatch,
      store.getState
    ).then(() => {
      setRevisionPublishedAt(Date.now());
      dispatch(
        notify({
          type: 'success',
          message: `Revision ${revision.version_id} published!`,
        })
      );
    });
  }, [docTemplateId, ydoc, assessment, dispatch, store]);

  // TODO: refresh when someone else publishes a revision (may need to modify the document)
  useEffect(() => {
    listRevisions(docTemplateId, `${docTemplateId}-revisions`)(
      dispatch,
      store.getState
    );
  }, [docTemplateId, revisionPublishedAt, dispatch, store]);

  useEffect(() => {
    const revisionQuery =
      store.getState().queries[`${docTemplateId}-revisions`];

    if (revisionQuery && revisionQuery.isLoading === false) {
      denormalize();
    }
  }, [docTemplateId, store]);

  return (
    <AssessmentContext.Provider value={assessment}>
      <div
        className={classNames(styles.editor, {
          [styles.preview]: isShowPreview,
        })}
      >
        <div className={styles.mainHeader}>
          <div className={styles.nav}>
            <ReplaceLink to={`/w/${currentWorkspaceId}/assessments`}>
              <Icon icon={faChevronLeft} />
            </ReplaceLink>
            <ReplaceLink
              to={match.url.replace('/preview', '')}
              className={classNames({ [styles.active]: !isShowPreview })}
            >
              Assessment Editor
            </ReplaceLink>
            <ReplaceLink
              to={`${match.url}/preview`}
              className={classNames({ [styles.active]: isShowPreview })}
            >
              Preview
            </ReplaceLink>
          </div>
          <div className={styles.headerActions}>
            <div className={classNames(styles.grouping, styles.collaborators)}>
              <CollaboratorList collaborators={collaborators} />
            </div>
            {!isShowPreview && (
              <>
                <div className={styles.grouping}>
                  <button
                    className={styles.iconButton}
                    onClick={undo}
                    disabled={!binding.undoManager.canUndo()}
                  >
                    <i>
                      <Icon icon={faRotateLeft} />
                    </i>
                  </button>
                  <button
                    className={styles.iconButton}
                    onClick={redo}
                    disabled={!binding.undoManager.canRedo()}
                  >
                    <i>
                      <Icon icon={faRotateRight} />
                    </i>
                  </button>
                </div>
                <div className={styles.grouping}>
                  <AssessmentType type={assessment?.meta?.type} />
                </div>
                <div className={styles.grouping}>
                  <EditorErrors errors={validationErrors} />
                </div>
                <div className={styles.grouping}>
                  {/* <Dropdown
                    isOpen={isLaunchMenuOpen}
                    toggle={() => setIsLaunchMenuOpen(!isLaunchMenuOpen)}
                  >
                    <DropdownToggle color="primary">Launch</DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem header>Select team</DropdownItem>
                      <DropdownItem divider />
                      {teamsQuery?.isLoading && (
                        <DropdownItem disabled>Loading teams...</DropdownItem>
                      )}
                      {teamsQuery?.isLoading === false && (
                        <React.Fragment>
                          {teams.map((team) => (
                            <DropdownItem
                              key={team.name}
                              onClick={() => handleLaunch(team)}
                            >
                              {team.name}
                            </DropdownItem>
                          ))}
                        </React.Fragment>
                      )}
                    </DropdownMenu>
                  </Dropdown> */}
                  <AsyncButton
                    color="primary"
                    onClick={handlePublish}
                    disabled={validationErrors.length > 0}
                  >
                    Publish
                  </AsyncButton>
                </div>
              </>
            )}
            {useModalSettings &&
              !isShowPreview(
                <div className={styles.grouping}>
                  <button
                    class={styles.settingsButton + ' ' + styles.iconButton}
                    onClick={() => setShowModalSettings(!showModalSettings)}
                  >
                    {showModalSettings ? (
                      <i>
                        <Icon icon={faArrowRightFromLine} />
                      </i>
                    ) : (
                      <i>
                        <Icon icon={faSliders} />
                      </i>
                    )}
                  </button>
                </div>
              )}
          </div>
          <div className={styles.Alert}>
            <Alert
              onMouseOver={clearAlertTimeout}
              onMouseOut={startAlertTimeout}
              color="info"
              toggle={clearAlert}
              isOpen={alertWithUndo?.show ?? false}
            >
              <div className={styles.alertContent}>
                {alertWithUndo?.msg}
                <button
                  onClick={() => {
                    undo();
                  }}
                >
                  Undo
                </button>
              </div>
            </Alert>
          </div>
          <div className={styles.Alert}>
            <Alert
              onMouseOver={clearAlertTimeout}
              onMouseOut={startAlertTimeout}
              color="error"
              toggle={clearAlert}
              isOpen={alertWithUndo?.show ?? false}
            >
              <div className={styles.alertContent}>
                {alertWithUndo?.msg}
                <button
                  onClick={() => {
                    undo();
                  }}
                >
                  Undo
                </button>
              </div>
            </Alert>
          </div>
        </div>
        <div
          className={classNames(styles.mainPane, {
            [styles.noscroll]: useModalSettings,
          })}
          ref={mainPaneRef}
        >
          <Switch>
            <Route path={`${match.path}/preview`}>
              {assessment && (
                <EditorPreview
                  assessmentType={assessmentType}
                  assessment={assessment}
                />
              )}
            </Route>
            <Route>
              {useModalSettings && showModalSettings && (
                <div
                  className={styles.mainPaneOverlay}
                  onClick={() => setShowModalSettings(false)}
                ></div>
              )}
              <div className={styles.assessment}>
                {assessment?.pages?.map((qs, i) => (
                  // TODO: use a generic Page component for this
                  <QuestionSet
                    index={i}
                    questionSet={qs}
                    categories={assessment.config.categories}
                    key={qs.guid}
                    onFieldChange={handlePageChange}
                    onDelete={handleDeleteQuestionSet}
                    onQuestionFieldChange={handleQuestionFieldChange}
                    onFieldFocus={handleFieldFocusChange}
                    onDeleteQuestion={handleDeleteQuestion}
                    onAddQuestion={handleAddQuestion}
                    onAssignToNewCategory={handleAssignSetToNewCategory}
                    onAssignToCategory={handleAssignSetToCategory}
                    onCopyQuestion={handleCopyQuestion}
                    onAnswerSetChoiceAdd={handleAnswerSetChoiceAdd}
                    onAnswerSetChoiceDelete={handleAnswerSetChoiceDelete}
                    onAnswerSetChoiceUpdate={handleAnswerSetChoiceUpdate}
                    onAnswerSetChange={handleAnswerSetChange}
                    onAnswerSetFieldChange={handleAnswerSetFieldChange}
                    collaborators={collaborators}
                  />
                ))}
                <div className={styles.addButtonContainer}>
                  <button
                    className={styles.addButton}
                    onClick={handleAddQuestionSet}
                  >
                    <i>
                      <Icon icon="plus" className="btn-icon-left" />
                    </i>
                    Add a question set
                  </button>
                  {/* <div className={styles.grouping}>
                    <Dropdown
                      className={styles.fullWidth}
                      isOpen={isAddMenuOpen}
                      toggle={() => setIsAddMenuOpen(!isAddMenuOpen)}
                    >
                      <DropdownToggle tag="button" className={styles.addButton}>
                        <i>
                          <Icon icon="plus" className="btn-icon-left" />
                        </i>
                        Add
                      </DropdownToggle>
                      <DropdownMenu className={styles.fullWidth}>
                        <DropdownItem
                          onClick={handleAddQuestionSet}
                          className={styles.addButtonOption}
                        >
                          Question set
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => alert('not implemented yet')}
                          className={styles.addButtonOption}
                        >
                          Information page
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div> */}
                </div>
              </div>
            </Route>
          </Switch>
        </div>
        {!isShowPreview && (!useModalSettings || showModalSettings) && (
          <div className={styles.settingsPane}>
            <div className={styles.toolPane}>
              <MetadataAndHistoryTool
                docTemplateId={docTemplateId}
                assessment={assessment}
                revisionsQueryIsLoading={revisionsQuery?.isLoading === true}
                revisions={revisions}
              />
              <AnswerSetTool assessment={assessment} />
              <CategoryTool
                categories={assessment?.config?.categories}
                onCategoryChange={handleCategoryChange}
                onAddCategory={handleAddCategory}
                onDeleteCategory={handleDeleteCategory}
              />
            </div>
          </div>
        )}
        <AnswerSetModal />

        <ConfirmModal
          isOpen={showingErrorModal}
          title="Something went wrong"
          onConfirm={() => setShowingErrorModal(false)}
          confirmButtonLabel="Ok"
          confirnButtonColor="danger"
          headerStyle="danger"
        >
          <p>{errorModalMessage}</p>
        </ConfirmModal>
      </div>
    </AssessmentContext.Provider>
  );
}
