import React from 'react';
import { PanelHeader } from 'js/components';
import AllAnsweredMessage from './AllAnsweredMessage';
import CapabilityResponses from './CapabilityResponses';
import { Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Icon } from 'js/components';
import { useAssessmentContext } from '../../../AssessmentContext';

function AssessmentReview({ backNavigation }) {
  const { assessment } = useAssessmentContext();
  const lastPageId =
    assessment.document.pages[assessment.document.pages.length - 1].guid;

  return (
    <div>
      <PanelHeader size="flex">
        <div className="survey-assessment-review-chart"></div>
      </PanelHeader>
      <div className="content">
        <div className="assessment survey-assessment-review">
          {backNavigation && (
            <div className="survey-assessment-back-nav">{backNavigation}</div>
          )}
          <Card>
            <CardBody>
              <AllAnsweredMessage />
              <CapabilityResponses />
            </CardBody>
          </Card>
          <div className="survey-run-navigation">
            <Link className="btn btn-secondary" to={'' + lastPageId}>
              <span>
                <Icon className="btn-icon-left" icon="arrow-left" size="lg" />
                Back
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssessmentReview;
