import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Input } from 'reactstrap';
function Range({ wrapperClassName, responded = true, ...props }) {
  const { min = 0, max = 100, value = 50 } = props;

  const wrapperClass = useMemo(() => {
    return classNames(
      'stylized-range',
      'stylized-range--progr',
      wrapperClassName,
      { 'stylized-range--not-responded': !responded }
    );
  }, [wrapperClassName, responded]);

  return (
    <div
      className={wrapperClass}
      style={{
        '--js': 1,
        '--val': ((max - min) * value) / max,
        '--min': min,
        '--max': max,
      }}
    >
      <Input type="range" {...props} />
    </div>
  );
}

export default Range;
