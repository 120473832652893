import React from 'react';
import classNames from 'classnames';
import { Icon } from 'js/components';

import { faRight } from '@fortawesome/pro-solid-svg-icons';

import styles from './AssessUserGroup.module.scss';

function SourceCard({ className, title, children, onClick = () => {} }) {
  const cardClass = classNames(
    'btn btn-primary',
    styles['source-card'],
    className
  );

  return (
    <button className={cardClass} onClick={onClick}>
      <span className={styles['source-content']}>
        <span className={styles['source-title']}>{title}</span>
        <span className={styles['source-body']}>{children}</span>
      </span>
      <span className={styles['source-button']}>
        Start from this
        <Icon icon={faRight} className="icon-right" />
      </span>
    </button>
  );
}

export default SourceCard;
