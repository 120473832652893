import { standardCollator } from 'js/utils/string';

function denormalizeCapability(capability, capabilityCache) {
  return {
    ...capability,
    children: Object.keys(capability.children)
      .map((childId) =>
        denormalizeCapability(capabilityCache[childId], capabilityCache)
      )
      .sort(
        (a, b) =>
          standardCollator.compare(a.name, b.name) ||
          standardCollator.compare(a.id, b.id)
      ),
  };
}

export function buildCapabilitySetsFromCache(
  capabilitySetCache,
  capabilityCache
) {
  return Object.fromEntries(
    Object.entries(capabilitySetCache).map(([id, cs]) => [
      id,
      {
        ...cs,
        capabilities: Object.keys(cs.capabilities)
          .map((cKey) =>
            denormalizeCapability(capabilityCache[cKey], capabilityCache)
          )
          .sort(
            (a, b) =>
              standardCollator.compare(a.name, b.name) ||
              standardCollator.compare(a.id, b.id)
          ),
      },
    ])
  );
}
