import React, { useContext } from 'react';
import { Icon } from 'js/components';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';

import styles from './AssessUserGroup.module.scss';

export function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);
  return (
    <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
      <Icon icon={faChevronLeft} />
    </Arrow>
  );
}

export function RightArrow() {
  const { isLastItemVisible, scrollNext } = useContext(VisibilityContext);

  return (
    <Arrow disabled={isLastItemVisible} onClick={() => scrollNext()}>
      <Icon icon={faChevronRight} />
    </Arrow>
  );
}

function Arrow({ disabled, onClick, children }) {
  return (
    <div>
      <div className="background-gradient"></div>
      <button
        onClick={onClick}
        className={styles['arrow-button']}
        disabled={disabled}
      >
        {children}
      </button>
    </div>
  );
}
