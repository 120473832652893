import React, { useCallback, useEffect } from 'react';
import { default as CapabilitySetComponent } from '../components/CapabilitySet';

import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { denormalize } from 'normalizr';
import {
  loadCapabilitySet,
  updateCapabilitySet,
  createCapability,
  updateCapability,
} from 'js/actions/capability-actions';
import { useNotify } from 'js/hooks/useNotify';
import { SCHEMAS } from 'js/api/schemas';
import { useDispatch } from 'react-redux';
function CapabilitySet({
  capabilitySet,
  loadCapabilitySet,
  updateCapabilitySet,
  createCapability,
  updateCapability,
}) {
  const { capabilitySetId } = useParams();
  const notify = useNotify();
  const dispatch = useDispatch();

  const refreshCapabilitySet = useCallback(
    () => loadCapabilitySet(capabilitySetId),
    [loadCapabilitySet, capabilitySetId]
  );

  useEffect(() => {
    refreshCapabilitySet();
  }, [refreshCapabilitySet]);

  const handleUpdate = useCallback(
    async (values) => {
      return updateCapabilitySet(capabilitySetId, values)
        .then(() => {
          notify({
            type: 'success',
            message: 'Capability set updated successfully',
          });
          return refreshCapabilitySet();
        })
        .catch((error) => {
          notify({
            type: 'error',
            message: 'Failed to update capability set',
          });
          throw error;
        });
    },
    [updateCapabilitySet, capabilitySetId, notify, refreshCapabilitySet]
  );

  const handleAddCapability = useCallback(
    async (props) => {
      // console.log('parent', parent, props);
      // return;

      return await createCapability(capabilitySetId, props).then(() =>
        refreshCapabilitySet()
      );
    },
    [capabilitySetId, createCapability, refreshCapabilitySet]
  );

  const handleEditCapability = useCallback(
    async (values) =>
      updateCapability(capabilitySetId, values.id, values).then(() =>
        refreshCapabilitySet()
      ),
    [capabilitySetId, refreshCapabilitySet, updateCapability]
  );

  const reparentCapability = useCallback(
    (values) => {
      dispatch({
        type: 'REPARENT_CAPABILITY',
        capability_set_id: capabilitySetId,
        ...values,
      });
      return updateCapability(capabilitySetId, values.id, {
        id: values.id,
        parent_id: values.new_parent_id,
      }).then(() => refreshCapabilitySet());
    },
    [capabilitySetId, refreshCapabilitySet, updateCapability, dispatch]
  );

  return (
    <CapabilitySetComponent
      capabilitySet={capabilitySet}
      onUpdate={handleUpdate}
      onAddCapability={handleAddCapability}
      onEditCapability={handleEditCapability}
      onReparentCapability={reparentCapability}
    />
  );
}

export default connect(
  (state, { match }) => ({
    capabilitySet: denormalize(
      state.entities.capabilitySets[match.params.capabilitySetId],
      SCHEMAS.CAPABILITY_SET,
      state.entities
    ),
  }),
  {
    loadCapabilitySet,
    updateCapabilitySet,
    createCapability,
    updateCapability,
  }
)(CapabilitySet);
