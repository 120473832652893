import React, { useMemo } from 'react';
import CapabilitySubset from './CapabilitySubset';
import { useEditorContext } from 'js/ydoc/docs/capabilities-assessment/EditorContext';
import { getCategoryPaletteColor } from 'js/utils/color';
import SimpleCapability from './SimpleCapability';
import ErrorMessage from '../../../common/components/EditorErrors/ErrorMessage';

function prune(tree, selectedCapabilities) {
  return tree.reduce((acc, node) => {
    // Check if current node has checked children
    const sparseChildren = node.children?.length
      ? prune(node.children, selectedCapabilities)
      : [];

    // Include node if it's checked or has checked children
    if (node.guid in selectedCapabilities || sparseChildren.length > 0) {
      acc.push({
        ...node,
        children: sparseChildren,
      });
    }

    return acc;
  }, []);
}

function SelectedCapabilityList({ page }) {
  const { cachedCapabilitySets } = useEditorContext();
  const capabilitySet = cachedCapabilitySets?.[page.capabilitySetId];
  const { background } = getCategoryPaletteColor(0);

  const prunedCapabilities = useMemo(() => {
    if (!capabilitySet) return null;
    return prune(capabilitySet.capabilities, page.selectedCapabilities);
  }, [capabilitySet, page.selectedCapabilities]);

  const { deep, shallow } =
    prunedCapabilities?.reduce(
      (acc, node) => {
        if (node.children.length > 0) {
          acc.deep.push(node);
        } else {
          acc.shallow.push(node);
        }
        return acc;
      },
      { deep: [], shallow: [] }
    ) ?? {};

  // It isn't loaded yet
  if (!capabilitySet && page.capabilitySetId) {
    return null;
  }

  return !prunedCapabilities?.length ? (
    <>
      <div>None selected</div>
      <div className="invalid-feedback force-show">
        <ErrorMessage>At least one capability must be selected</ErrorMessage>
      </div>
    </>
  ) : (
    <div>
      {deep.map((node) => (
        <CapabilitySubset
          key={node.id}
          depth={0}
          capability={node}
          selectedCapabilities={page.selectedCapabilities}
        />
      ))}
      {shallow.map((node) => (
        <SimpleCapability key={node.id} name={node.name} color={background} />
      ))}
    </div>
  );
}

export default SelectedCapabilityList;
