import React from 'react';
import { Doc as YDoc, encodeStateAsUpdate } from 'yjs';
import AssessUserGroup from '../components/AssessUserGroup';
import { create as createUserGroupAssessment } from 'js/actions/user-group-assessment-actions';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { initializeFromScratch } from 'js/ydoc/docs/capabilities-assessment';

function AssessUserGroupContainer({ createUserGroupAssessment }) {
  const { workspaceId, userGroupId } = useParams();
  const history = useHistory();

  const handleCreateFromScratch = async () => {
    const doc = new YDoc();
    // initialize a new assessment template

    // const binding = createBinding(doc);

    initializeFromScratch(doc, null, this);

    const initial_update = [...encodeStateAsUpdate(doc).values()];

    const result = await createUserGroupAssessment(userGroupId, {
      user_group_id: userGroupId,
      initial_update,
      source: 'new',
    });

    history.push(
      `/w/${workspaceId}/pm/user-groups/${userGroupId}/assessments/${result.id}/customize`
    );
  };

  const handleCreateFromTemplate = async () => {
    console.log('create from template');
  };

  const handleCreateFromRecent = async () => {
    console.log('create from recent');
  };

  return (
    <AssessUserGroup
      onCreateFromScratch={handleCreateFromScratch}
      onCreateFromTemplate={handleCreateFromTemplate}
      onCreateFromRecent={handleCreateFromRecent}
    />
  );
}

export default connect(null, { createUserGroupAssessment })(
  AssessUserGroupContainer
);
