import { createContext } from 'react';

export const TreeContext = createContext({
  dispatch: () => {},
  uniqueContextId: Symbol('capability-set'),
  getPathToItem: () => [],
  getMoveTargets: () => [],
  getChildrenOfItem: () => [],
  registerTreeItem: () => {},
});
