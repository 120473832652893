import { FIELD_DELIMITER, standardCollator } from 'js/utils/string';

export function convertToPreviewDoc(assessment) {
  // const assessment = standardizeAssessment(assessment);
}

// NOTE: This logic is duplicated in the API
export function convertToAssessment(assessment) {
  const answerSets = convertAnswerSets(assessment.answerSets);

  return {
    // ...assessment,
    ...assessment.meta,
    id: assessment.meta.key,
    key: assessment.meta.key ?? 'capability-assessment',
    type: 'capabilities',
    name: 'Capabilities Assessment',
    team_id: null,
    config: { version: assessment.meta.version },
    document: assessment,
    question_sets: assessment.pages
      .flatMap((page) =>
        standardizePage(
          page,
          assessment.capabilitySetCache,
          assessment.capabilityCache,
          answerSets
        )
      )
      .filter((x) => !!x),
  };
}

function convertAnswerSets(answerSets) {
  return Object.fromEntries(
    Object.entries(answerSets).map(([k, v]) => {
      const choices = Object.values(v.choices)
        .sort(
          (a, b) =>
            standardCollator.compare(a.p, b.p) ||
            standardCollator.compare(a.guid, b.guid)
        )
        .map(({ value, text, guid }, i) => ({
          id: guid,
          value,
          text,
          ordinal: i,
        }));
      return [k, choices];
    })
  );
}

function standardizePage(
  page,
  capabilitySetCache,
  capabilityCache,
  answerSets
) {
  if (page.type === 'capability-questions') {
    return [
      {
        id: page.guid,
        type: page.type,
        name: capabilitySetCache[page.capabilitySetId]?.name ?? 'Unknown',
        key: page.guid,
        questions: [
          ...expandElements(
            page.capabilityElements,
            page.selectedCapabilities,
            page.capabilitySetId,
            capabilityCache,
            page.elements,
            answerSets
          ),
        ],
      },
    ];
  }

  return null;
}

function expandElements(
  capabilityElements,
  selectedCapabilities,
  capabilitySetId,
  capabilityCache,
  elements,
  answerSets
) {
  return capabilityElements
    .flatMap((e) => {
      const questionKey = e.key;

      const questions = [
        // Capability questions
        ...Object.keys(selectedCapabilities).reduce((acc, cId) => {
          const capability = capabilityCache[cId];

          if (!capability || capability.capability_set_id !== capabilitySetId) {
            return acc;
          }

          acc.push({
            id: [questionKey, cId].join(FIELD_DELIMITER),
            key: [questionKey, cId].join(FIELD_DELIMITER),
            type: 'capability-question',
            text: `${e.name}: ${capability.name}`,
            answers: answerSets[e.answerSetId] ?? [],
          });

          return acc;
        }, []),
        // Additional questions
        // ...elements.map((e) => ({
        //   ...e,
        //   id: [questionKey, e.key].join(FIELD_DELIMITER),
        //   key: [questionKey, e.key].join(FIELD_DELIMITER),
        // })),
      ];

      return questions;
    })
    .filter((x) => !!x);
}

// function standardizeAssessment(assessment) {
//   return {
//     ...assessment,
//     id: assessment.meta.key,
//     question_sets: assessment.pages
//       .filter((p) => p.pageType === 'question-set')
//       .map((qs) => ({
//         ...qs,
//         id: qs.guid,
//         questions: qs.elements
//           .filter((e) => e.elementType === 'question')
//           .map((q) => ({
//             ...q,
//             id: q.guid,
//             type: q.type,
//             answers: Object.values(q.answerSet?.choices ?? {})
//               .sort((a, b) => standardCollator.compare(a.p, b.p))
//               .map(({ value, text, id }, i) => ({
//                 id,
//                 value,
//                 text,
//                 ordinal: i,
//               })),
//           })),
//       })),
//   };
// }
