import React from 'react';
import { useSelectorContext } from './SelectorContext';
import SelectedCapabilityTag from './SelectedCapabilityTag';

import styles from './CapabilitySelector.module.scss';

function flattenSelected(capability, selectedCapabilities, depth = 0) {
  const selectedChildren = capability.children.flatMap((c) =>
    flattenSelected(c, selectedCapabilities, depth + 1)
  );

  return capability.guid in selectedCapabilities
    ? [{ capability, depth }, ...selectedChildren]
    : selectedChildren;
}

function SelectedCapabilities() {
  const { selectedCapabilities, capabilitySet } = useSelectorContext();

  const onlySelectedCapabilitiesWithinSet =
    capabilitySet?.capabilities?.flatMap((c) =>
      flattenSelected(c, selectedCapabilities, 0)
    ) ?? [];

  return (
    <div className={styles.tags}>
      {onlySelectedCapabilitiesWithinSet.map(({ capability, depth }) => (
        <SelectedCapabilityTag
          key={capability.guid}
          capability={capability}
          depth={depth}
        />
      ))}
    </div>
  );
}

export default SelectedCapabilities;
