import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useEditorContext } from 'js/ydoc/docs/capabilities-assessment/EditorContext';
import EditorTool from 'js/components/Assessment/Editors/common/Tools/EditorTool';
import PageSummary from './PageSummary/PageSummary';
import styles from './SummaryTool.module.scss';

function SummaryTool() {
  const { assessment, userGroupAssessment } = useEditorContext();
  const { workspaceId, userGroupId, assessmentId } = useParams();

  if (!assessment) {
    return null;
  }

  return (
    <EditorTool title="Summary">
      {!userGroupAssessment?.went_live_at && (
        <div className={styles['tool-actions']}>
          <Link
            to={`/w/${workspaceId}/pm/user-groups/${userGroupId}/assessments/${assessmentId}/customize`}
          >
            Edit
          </Link>
        </div>
      )}

      {assessment.pages.map((p) => (
        <PageSummary key={p.guid} page={p} />
      ))}
    </EditorTool>
  );
}

export default SummaryTool;
