import React, { useMemo } from 'react';
import { useAssessmentContext } from '../../../../../AssessmentContext';
import CapabilityQuestionSet from './CapabilityQuestionSet';
import { getPageCapabilities } from 'js/engines/CapabilitiesAssessment/utils';

export function CapabilitiesPage({ page }) {
  const selectedCapabilities = page.selectedCapabilities;
  const { capabilitySets } = useAssessmentContext();

  const capabilitySet = useMemo(
    () =>
      capabilitySets[page.capabilitySetId] ?? {
        capabilities: [],
      },
    [capabilitySets, page.capabilitySetId]
  );

  const pageCapabilities = useMemo(() => {
    return getPageCapabilities(capabilitySet, selectedCapabilities);
  }, [capabilitySet, selectedCapabilities]);

  return (
    <>
      {page.capabilityElements.map((capabilityElement) => (
        <CapabilityQuestionSet
          key={capabilityElement.guid}
          capabilitySet={capabilitySet}
          capabilityQuestion={capabilityElement}
          capabilities={pageCapabilities}
        />
      ))}
    </>
  );
}

export default CapabilitiesPage;
