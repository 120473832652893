import React, { useEffect, useRef } from 'react';

import Page from './Page';
import { useEditorContext } from 'js/ydoc/docs/capabilities-assessment/EditorContext';
import { autoScrollWindowForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';

// import { convertToAssessment } from 'js/ydoc/docs/capabilities-assessment/convert';

function Editor() {
  const mainPaneRef = useRef(null);
  const { assessment } = useEditorContext();

  useEffect(() => {
    return autoScrollWindowForElements();
  }, []);

  return (
    <div ref={mainPaneRef}>
      {assessment &&
        assessment.pages.map((page) => <Page key={page.guid} page={page} />)}
    </div>
  );
}

export default Editor;
