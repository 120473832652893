import React, {
  useCallback,
  useMemo,
  useRef,
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import SelectedCapabilityList from './SelectedCapabilityList';
import { Button } from 'reactstrap';
import { Icon } from 'js/components';
import { faListTree } from '@fortawesome/pro-regular-svg-icons';
import { faGripVertical } from '@fortawesome/pro-solid-svg-icons';
import { useEditorContext } from 'js/ydoc/docs/capabilities-assessment/EditorContext';
import PageBadge from '../PageBadge';

import { EditorInput } from 'js/components/Assessment/Editors/common/components/EditorInput';
import { useCollaboratorForField } from 'js/ydoc/doc/hooks';
import CapabilityQuestion from '../../Questions/CapabilityQuestion';
import CapabilitySelector from './CapabilitySelector/CapabilitySelector';
import ErrorMessage from '../../../common/components/EditorErrors/ErrorMessage';
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';

import styles from '../../CapabilitiesAssessmentEditor.module.scss';

function CapabilitiesPage({ page }) {
  const {
    updatePageFields,
    deleteCapabilityElement,
    cachedCapabilitySets,
    addCapabilityElementToPage,
  } = useEditorContext();

  const collaboratorForTitle = useCollaboratorForField(
    'page',
    page.guid,
    'title'
  );

  const collaboratorForDescription = useCollaboratorForField(
    'page',
    page.guid,
    'description'
  );

  const handleFieldChange = useCallback(
    ({ target: { name, value } }) => {
      // console.log('handleFieldChange', e);
      updatePageFields(page.guid, { [name]: value });
    },
    [page.guid, updatePageFields]
  );

  const handleDeleteCapabilityQuestion = useCallback(
    (questionGuid) => {
      deleteCapabilityElement(page.guid, questionGuid);
    },
    [page.guid, deleteCapabilityElement]
  );

  const [showCapabilitySelector, setShowCapabilitySelector] = useState(false);
  const handleShowCapabilitySelector = useCallback(() => {
    setShowCapabilitySelector(true);
  }, []);
  const handleHideCapabilitySelector = useCallback(() => {
    setShowCapabilitySelector(false);
  }, []);

  const capabilitySet = useMemo(() => {
    return cachedCapabilitySets[page.capabilitySetId];
  }, [page.capabilitySetId, cachedCapabilitySets]);

  const [isDraggedOver, setIsDraggedOver] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    const el = ref.current;
    if (!el) return;

    return dropTargetForElements({
      element: ref.current,
      getData: () => ({ page }),
      canDrop: ({ source }) => !!source.data.capabilityElement,
      onDragEnter: () => {
        setIsDraggedOver(true);
      },
      onDragLeave: () => {
        setIsDraggedOver(false);
      },
      onDrop: ({ source, location }) => {
        setIsDraggedOver(false);
        if (location.current.dropTargets.length > 1) {
          // A child element is the target and will handle this
          return;
        }

        if (source.data.capabilityElement != null) {
          const elementGuid = source.data.capabilityElement.guid;
          if (!page.capabilityElements.some((e) => e.guid === elementGuid)) {
            addCapabilityElementToPage(elementGuid, page.guid);
          }
        }
      },
    });
  }, [addCapabilityElementToPage, page]);

  const rootClassName = useMemo(() => {
    return classNames(styles.page, styles['page-capabilities'], {
      'dragged-over': isDraggedOver,
    });
  }, [isDraggedOver]);

  return (
    <div className={rootClassName} ref={ref}>
      <div className={styles['page-header']}>
        <div className={styles['drag-handle']}>
          <button className="drag-handle">
            <Icon icon={faGripVertical} className="icon" />
          </button>
        </div>
        <div className={styles['capability-set-badge']}>
          <PageBadge
            lookupValue={page.capabilitySetId}
            onClick={handleShowCapabilitySelector}
          >
            {capabilitySet?.name ?? 'No capability set selected'}
          </PageBadge>
        </div>
        <div className={styles['page-guid']}>{page.guid}</div>
      </div>
      <div className={styles['page-title']}>
        <EditorInput
          name="title"
          value={page.title}
          onChange={handleFieldChange}
          collaborator={collaboratorForTitle}
          placeholder="Add a page title"
        />
      </div>
      <div className={styles['page-description']}>
        <EditorInput
          multiLine
          name="description"
          value={page.description || ''}
          onChange={handleFieldChange}
          collaborator={collaboratorForDescription}
          placeholder="Add a description"
        />
      </div>
      <section className={styles['capabilities-list']}>
        <div className={styles['capabilities-list-header']}>
          <div>
            <label>Capabilities</label>
          </div>
          <div className={styles['capabilities-list-header-actions']}>
            <Button
              color="primary"
              className="btn-action-alt"
              onClick={handleShowCapabilitySelector}
            >
              <Icon className="icon" icon={faListTree} />
            </Button>
          </div>
        </div>
        <div>
          <SelectedCapabilityList page={page} />
          <CapabilitySelector
            page={page}
            isOpen={showCapabilitySelector}
            onClose={handleHideCapabilitySelector}
          />
        </div>
      </section>
      <section className={styles['page-elements']}>
        <label>Capability questions</label>
        <div>
          {page.capabilityElements?.length === 0 && (
            <div className="invalid-feedback force-show">
              <ErrorMessage>
                At least one capability question must be included
              </ErrorMessage>
            </div>
          )}
          {page.capabilityElements?.map((e) => (
            <CapabilityQuestion
              pageGuid={page.guid}
              key={e.guid}
              question={e}
              onDelete={handleDeleteCapabilityQuestion}
            />
          ))}
        </div>
      </section>
      {/* <section className={styles['page-elements']}>
        <label>Additional questions</label>
      </section> */}
    </div>
  );
}

export default CapabilitiesPage;
