import React, { useCallback } from 'react';

import { faX } from '@fortawesome/pro-regular-svg-icons';

import { Icon } from 'js/components';
import { useEditorContext } from 'js/ydoc/docs/capabilities-assessment/EditorContext';
import { useSelectorContext } from './SelectorContext';

import styles from './CapabilitySelector.module.scss';

function SelectedCapabilityTag({ depth = 0, capability }) {
  const { page } = useSelectorContext();
  const { togglePageCapabilitySelection } = useEditorContext();

  const handleRemove = useCallback(() => {
    togglePageCapabilitySelection(page.guid, capability.guid, false);
  }, [togglePageCapabilitySelection, page.guid, capability.guid]);

  return (
    <div className={`${styles.tag} palette-cat-${depth + 1}`}>
      <span className={styles['tag-name']}>{capability.name}</span>
      <button onClick={handleRemove}>
        <Icon icon={faX} />
      </button>
    </div>
  );
}

export default SelectedCapabilityTag;
