import React from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
} from 'reactstrap';
import TextareaAutosize from 'react-textarea-autosize';
import { Link, useParams } from 'react-router-dom';

function CapabilitySetForm({
  submitLabel = 'Create',
  cancelLabel = 'Cancel',
  showCancel = true,
  cancelUrl,
  autoFocus,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
}) {
  const { workspaceId } = useParams();

  const formikAttributes = (fieldName) => ({
    id: fieldName,
    name: fieldName,
    value: values[fieldName],
    onChange: handleChange,
    onBlur: handleBlur,
    invalid: errors[fieldName] && touched[fieldName],
  });

  const formikFeedback = (fieldName) => {
    return errors[fieldName] && touched[fieldName] ? (
      <FormFeedback>{errors[fieldName]}</FormFeedback>
    ) : null;
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-4">
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              type="text"
              {...formikAttributes('name')}
              autoFocus={autoFocus}
            />
            {formikFeedback('name')}
          </FormGroup>
        </div>
      </div>
      <FormGroup>
        <Label for="description">Description</Label>
        <TextareaAutosize
          className="form-control"
          minRows={3}
          type="textarea"
          {...formikAttributes('description')}
        />
        {formikFeedback('description')}
      </FormGroup>
      <div>
        <Button color="primary" type="submit">
          {submitLabel}
        </Button>
        {showCancel && (
          <Link
            color="secondary"
            to={cancelUrl ?? `/w/${workspaceId}/pm/capability-sets`}
            className="btn btn-secondary"
          >
            {cancelLabel}
          </Link>
        )}
      </div>
    </Form>
  );
}

const FormikCapabilitySetForm = withFormik({
  mapPropsToValues: ({ capabilitySet }) => {
    return {
      name: capabilitySet?.name || '',
      description: capabilitySet?.description || '',
    };
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().required('What should we call set of capabilities?'),
  }),
  handleSubmit: async (values, bag) => {
    const { setSubmitting, props } = bag;
    const { onSubmit = () => {} } = props;
    setSubmitting(true);
    await onSubmit(values);
    setSubmitting(false);
  },
  displayName: 'CapabilitySetForm',
})(CapabilitySetForm);

export default FormikCapabilitySetForm;
