import React from 'react';
import { Card, CardBody } from 'reactstrap';

function CapabilitySetUserGroups() {
  return (
    <Card>
      <CardBody>
        <p>Your capability set user groups</p>
      </CardBody>
    </Card>
  );
}

export default CapabilitySetUserGroups;
