import React, { useMemo } from 'react';
import { PanelHeader } from 'js/components';
import { Nav, NavItem, NavLink } from 'reactstrap';
import {
  Switch,
  Route,
  Link,
  useRouteMatch,
  matchPath,
} from 'react-router-dom';
import { trimEnd } from 'lodash';
import Results from './Results';
import ReviewHeader from './ReviewHeader';
import { AssessmentProvider } from '../../AssessmentContext';
import { AssessmentReviewContextProvider } from './AssessmentReviewContext';
import ParticipantResults from './ParticipantResults';

import styles from './Review.module.scss';

const VIEWS = {
  // OVERVIEW: 'overview',
  RESULTS: 'results',
  // INVITATIONS: 'invitations',
  PARTICIPANTS: 'participants',
};

function getCurrentView(matchedPath, pathName) {
  if (
    null !=
    matchPath(pathName, {
      path: `${matchedPath}/participants`,
    })
  ) {
    return VIEWS.PARTICIPANTS;
    // } else if (
    //   null !=
    //   matchPath(window.location.pathname, {
    //     path: '/w/:workspaceId/pm/capability-sets/:capabilitySetId/settings',
    //   })
    // ) {
    //   return VIEWS.RESULTS;
  } else {
    return VIEWS.RESULTS;
  }
}

function AssessmentReviewDetail({
  userGroup,
  userGroupAssessment,
  assessment,
}) {
  const match = useRouteMatch();

  // const currentView = useMemo(() => {
  //   return VIEWS.RESULTS;
  // }, []);

  const browserPath = window.location.pathname;
  const currentView = useMemo(
    () => getCurrentView(match.path, browserPath),
    [match.path, browserPath]
  );

  if (!userGroup || !userGroupAssessment || !assessment) {
    return null;
  }

  return (
    <AssessmentProvider assessment={assessment}>
      <AssessmentReviewContextProvider assessment={assessment}>
        <div className="survey-assessment review">
          <PanelHeader size="flex">
            <div className="container-fluid">
              <ReviewHeader
                userGroup={userGroup}
                userGroupAssessment={userGroupAssessment}
                assessment={assessment}
              >
                <div className={styles['chart-placeholder']}>
                  Chart placeholder
                </div>
              </ReviewHeader>
              <Nav className="tab-nav nav-underline">
                {/* <NavItem>
                  <NavLink
                    tag={Link}
                    active={currentView === 'overview'}
                    data-view="overview"
                    to={`${trimEnd(match.url, '/')}`}
                  >
                    Overview
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink
                    tag={Link}
                    active={currentView === 'results'}
                    data-view="results"
                    to={`${trimEnd(match.url, '/')}/results`}
                  >
                    Results
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    active={currentView === VIEWS.PARTICIPANTS}
                    data-view="participants"
                    to={`${trimEnd(match.url, '/')}/participants`}
                  >
                    Participants
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </PanelHeader>
          <div className="content">
            <Switch>
              <Route path={`${match.path}/participants`}>
                <ParticipantResults
                  userGroup={userGroup}
                  userGroupAssessment={userGroupAssessment}
                  assessment={assessment}
                />
              </Route>
              <Route path={`${match.path}`}>
                <Results
                  userGroup={userGroup}
                  userGroupAssessment={userGroupAssessment}
                  assessment={assessment}
                />
              </Route>
            </Switch>
          </div>
        </div>
      </AssessmentReviewContextProvider>
    </AssessmentProvider>
  );
}

export default AssessmentReviewDetail;
