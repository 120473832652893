import React from 'react';

// import GeneralSettingsTool from './GeneralSettingsTool';
import Actions from './Actions';
import CapabilityQuestionBank from './CapabilityQuestionBank';
function Tools() {
  return (
    <>
      <Actions />
      <CapabilityQuestionBank />
    </>
  );
}

export default Tools;
