import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from 'js/containers/Route/PrivateRoute';
import Workspace from 'js/containers/Route/Workspace';
import Guest from 'js/containers/Route/Guest';
import { LayoutRoute } from 'js/components';

import DashboardLayout from '../layouts/Dashboard';
import PagesLayout from '../layouts/Pages';

import {
  GuestLoginPageContainer as GuestLogin,
  LoginPageContainer as Login,
  RegistrationPageContainer as Registration,
  VerifyAccountPageContainer as VerifyAccount,
} from './Pages/Account';
import { HomePageContainer as HomePage } from './Pages/Home';

import { AccountRouteContainer as Account } from './Account';
import { AccountsRouter as Accounts } from './Accounts';
import {
  DashboardRedirectContainer as DashboardRedirect,
  HomeRouteContainer as Dashboard,
} from './Home';
import NotFound from './NotFound';
import Contact from './Contact';
import { OrgsContainer as Orgs, OrgContainer as Org } from './Organizations';
import {
  TeamDashboardContainer as TeamDashboard,
  TeamRouteContainer as Team,
  TeamsRouteContainer as Teams,
  CreateTeamContainer as CreateTeam,
} from './Teams';
import { AssessmentManagement } from './AssessmentAdmin';
import {
  AssessmentContainer as Assessment,
  AssessmentRouterContainer as AssessmentRouter,
  ConfigureAssessmentContainer as ConfigureAssessment,
  LaunchAssessmentContainer as LaunchAssessment,
  CreateAssessmentsContainer,
} from './Assessments';
import { UsersRouteContainer as Users } from './Users';
import { InviteUserContainer as InviteUser } from './Users';
import {
  GuestHomeRouteContainer as GuestHome,
  GuestTeamsRouteContainer as GuestTeams,
} from './Guest';
import { EmailVerificationRouterContainer as EmailVerificationRouter } from './Verification';
import { PasswordResetRouter } from './PasswordReset';
import AcceptInviteContainer from 'js/routes/Users/containers/AcceptInviteContainer';
import AcceptAccountInviteContainer from 'js/routes/Accounts/containers/AcceptInviteContainer';
import ManageOrgContainer from 'js/routes/Organizations/containers/ManageOrgContainer';
import PricingListContainer from './Pricing/containers/PricingListContainer';
import WorkspaceSettingsContainer from './WorkspaceSettings/containers/WorkspaceSettingsContainer';
import { PurchaseProductContainer, PurchaseSuccessContainer } from './Products';
import { Entitlements } from 'js/utils/entitlement-utils';
import { ProductManagement } from './ProductManagement';
import Demo from './Demo/Demo';
import CapabilitiesRoutes from './Capabilities';
import UserGroupsRoutes from './UserGroups/UserGroupsRoutes';
import { UserGroupAssessmentPreview } from './UserGroups';

const Routes = (props) => {
  const { history } = props;

  const dashboardProps = {
    layout: DashboardLayout,
    history,
  };

  const componentPageProps = {
    history,
  };

  const pageProps = {
    layout: PagesLayout,
    history,
  };

  // prettier-ignore
  return (
    <Switch>
      <LayoutRoute path="/" exact component={HomePage} {...pageProps} />
      <LayoutRoute path="/pages/login" exact component={Login} {...pageProps} />
      <LayoutRoute path="/pages/guest-login" exact component={GuestLogin} {...pageProps} />
      <LayoutRoute path="/pages/register" exact component={Registration} {...pageProps} />
      <LayoutRoute path="/pages/verify/:token" exact component={VerifyAccount} {...pageProps} />
      <LayoutRoute path="/verify" component={EmailVerificationRouter} {...pageProps} />
      <LayoutRoute path="/reset" component={PasswordResetRouter} {...pageProps} />
      <LayoutRoute path="/accept-invite/:token" component={AcceptInviteContainer} {...pageProps}/>
      <LayoutRoute path="/accept-account-invite/:token" component={AcceptAccountInviteContainer} {...pageProps}/>

      <Route path="/assessments/:assessmentId" component={AssessmentRouter} />

      {/* <PrivateRoute path="/assessments" exact component={Assessments} {...dashboardProps} />
      <PrivateRoute path="/assessments/new" exact component={CreateAssessmentsContainer} {...dashboardProps} />
      <PrivateRoute path="/assessments/:assessmentId/host" component={AssessmentHost} {...dashboardProps} />
      <PrivateRoute path="/assessments/:assessmentId/run/questions/:questionId" component={AssessmentRun} {...dashboardProps} />
      <PrivateRoute path="/assessments/:assessmentId/run" component={AssessmentRun} {...dashboardProps} /> */}

      <PrivateRoute path="/a/:accountId" component={Accounts} layout={DashboardLayout} />
			<PrivateRoute path="/w/:workspaceId/assessments" component={AssessmentManagement} requiredEntitlement={Entitlements.ALLOW_ASSESSMENT_ADMIN} {...componentPageProps} />
      <PrivateRoute path="/w/:workspaceId" component={Workspace} layout={DashboardLayout}>
        <Switch>
        <PrivateRoute path="/w/:workspaceId/pm/user-groups/:userGroupId/assessments/:assessmentId/preview" component={UserGroupAssessmentPreview} {...componentPageProps} />
        <PrivateRoute path="/w/:workspaceId/dashboard" exact component={Dashboard} {...componentPageProps} />
          <PrivateRoute path="/w/:workspaceId/pm/user-groups" component={UserGroupsRoutes} {...componentPageProps} />
          <PrivateRoute path="/w/:workspaceId/teams/:teamId/assessments/new" exact component={CreateAssessmentsContainer} {...componentPageProps} />
          <PrivateRoute path="/w/:workspaceId/teams/:teamId/assessments/:assessmentKey/new" exact component={ConfigureAssessment} {...componentPageProps} />
          <PrivateRoute path="/w/:workspaceId/teams/:teamId/assessments/:assessmentId/launch" exact component={LaunchAssessment} {...componentPageProps} />
          <PrivateRoute path="/w/:workspaceId/teams/:teamId/assessments/:assessmentKey" component={Assessment} {...componentPageProps} />
          <PrivateRoute path="/w/:workspaceId/teams/:teamId/dashboard" exact component={TeamDashboard} {...componentPageProps} />
          <PrivateRoute path="/w/:workspaceId/teams/add" exact component={CreateTeam} {...componentPageProps} />
          <PrivateRoute path="/w/:workspaceId/teams/:teamId" exact component={Team} {...componentPageProps} />
          <PrivateRoute path="/w/:workspaceId/teams" exact component={Teams} {...componentPageProps} />
          <PrivateRoute path="/w/:workspaceId/orgs/" exact component={Orgs} {...componentPageProps} />
          <PrivateRoute path="/w/:workspaceId/settings" exact component={WorkspaceSettingsContainer} {...componentPageProps} />
          <PrivateRoute path="/w/:workspaceId/orgs/:orgId/manage" exact requiredEntitlement={Entitlements.ALLOW_ORGS} component={ManageOrgContainer} {...componentPageProps} />
          <PrivateRoute path="/w/:workspaceId/orgs/:orgId" requiredEntitlement={Entitlements.ALLOW_ORGS} component={Org} {...componentPageProps} />
          <PrivateRoute path="/w/:workspaceId/users" exact component={Users} {...componentPageProps} />
          <PrivateRoute path="/w/:workspaceId/users/invite" exact component={InviteUser} {...componentPageProps} />
          <PrivateRoute path="/w/:workspaceId/demo/:name" component={Demo} />
          <PrivateRoute path="/w/:workspaceId/pm/capability-sets" component={CapabilitiesRoutes} {...componentPageProps} />
          <PrivateRoute path="/w/:workspaceId/pm" component={ProductManagement} />
          <PrivateRoute component={NotFound} {...componentPageProps} />
        </Switch>
      </PrivateRoute>
      <PrivateRoute path="/account" exact component={Account} {...dashboardProps} />
      <PrivateRoute path="/dashboard" exact component={DashboardRedirect} {...dashboardProps} />

      <PrivateRoute path="/guest" component={Guest} {...dashboardProps}>
        <PrivateRoute path="/guest" exact component={GuestHome} {...componentPageProps} />
        <PrivateRoute path="/guest/teams" exact component={GuestTeams} {...componentPageProps} />
      </PrivateRoute>

      <LayoutRoute path="/pricing" component={PricingListContainer} {...pageProps}/>
      <LayoutRoute path="/contact" exact component={Contact} {...pageProps} />

      <PrivateRoute path="/products/:id/purchase/:priceId" exact unverified component={PurchaseProductContainer} layout={PagesLayout} />
      <PrivateRoute
        path="/products/:id/purchase-success/:checkoutSessionId"
        exact
        unverified
        layout={PagesLayout}
        component={PurchaseSuccessContainer}
        />

      <PrivateRoute component={NotFound} {...dashboardProps} />
    </Switch>
  );
};

export default Routes;
