import React from 'react';
import { Route, Switch } from 'react-router-dom';
// import CapabilitiesDashboard from './components/CapabilitiesDashboard/CapabilitiesDashboard';
import CapabilitySetsDashboard from './containers/CapabilitySetsDashboard';
import CapabilitySet from './containers/CapabilitySet';
import CreateCapabilitySet from './containers/CreateCapabilitySet';
export default function CapabilitiesRoutes() {
  return (
    <Switch>
      <Route
        path="/w/:workspaceId/pm/capability-sets/new"
        component={CreateCapabilitySet}
      />
      <Route
        path="/w/:workspaceId/pm/capability-sets/:capabilitySetId"
        component={CapabilitySet}
      />
      <Route
        exact
        path="/w/:workspaceId/pm/capability-sets"
        component={CapabilitySetsDashboard}
      />
    </Switch>
  );
}
