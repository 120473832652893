import { createContext, useContext } from 'react';

export const SelectorContext = createContext({
  page: null,
  dispatch: () => {},
  setCapabilitySet: (capabilitySet) => {},
  capabilitySet: null,
  selectedCapabilities: null,
  navigateToItem: () => {},
  activeItem: null,
});

export function useSelectorContext() {
  return useContext(SelectorContext);
}
