import PreviewEngine from './containers/CapabilitiesAssessmentPreviewEngine';
import RunEngine from './containers/CapabilitiesAssessmentRunEngine';
import ReviewEngine from './containers/CapabilitiesAssessmentReviewEngine';
import { load } from 'js/actions/assessment-actions';

const CapabilitiesAssessment = {
  Preview: PreviewEngine,
  Run: RunEngine,
  Review: ReviewEngine,
  checks: {
    requireTeam: false,
  },
  strategies: {
    create: () => {},
    createFromDocTemplate: () => {},
    load: (assessmentId, participantToken) =>
      load(assessmentId, participantToken),
    buildPostCreateRedirectPath: ({ id, team_id, workspace_id }) => null,
    // `/w/${workspace_id}/teams/${team_id}/assessments/${id}/launch`,
  },
};

// const BaseSurveyAssessment = {
//   Run: Engine,
//   Check: Engine,
//   Review,
//   Configure,
//   Launch,
//   ListItem,
//   strategies: {
//     create,
//     createFromDocTemplate,
//     load: (assessmentId, participantToken) =>
//       load(assessmentId, participantToken),
//     buildPostCreateRedirectPath: ({ id, team_id, workspace_id }) =>
//       `/w/${workspace_id}/teams/${team_id}/assessments/${id}/launch`,
//   },
// };

export default CapabilitiesAssessment;
