import React, { useState, useMemo, useEffect } from 'react';
import { Card, CardBody, Button } from 'reactstrap';
import AssessmentSummary from './AssessmentSummary';
import { Loading, Icon } from 'js/components';
function UserGroupDashboard({
  userGroupAssessments,
  userGroupAssessmentsQuery,
  onUpdateAssessment,
}) {
  userGroupAssessments = userGroupAssessments ?? [];

  const [showArchived, setShowArchived] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if (!hasLoaded && userGroupAssessmentsQuery?.finishedAt) {
      setHasLoaded(true);
    }
  }, [hasLoaded, userGroupAssessmentsQuery?.finishedAt]);

  const handleShowArchived = () => {
    setShowArchived(!showArchived);
  };

  const filteredAssessments = useMemo(() => {
    return showArchived
      ? userGroupAssessments
      : userGroupAssessments?.filter((assessment) => {
          return assessment.archived_state === null;
        });
  }, [userGroupAssessments, showArchived]);

  return (
    <div>
      <Card>
        <CardBody style={{ position: 'relative' }}>
          <div className="d-flex justify-content-end">
            <Button onClick={handleShowArchived}>
              {showArchived ? 'Hide Archived' : 'Show Archived'}
            </Button>
          </div>
          <div>
            {!hasLoaded ? (
              <Loading />
            ) : filteredAssessments.length > 0 ? (
              filteredAssessments.map((assessment) => (
                <AssessmentSummary
                  key={assessment.id}
                  assessment={assessment}
                  onUpdateAssessment={onUpdateAssessment}
                />
              ))
            ) : (
              <div className="media text-muted mt-4 mb-4">
                <Icon
                  icon={['fal', 'spider-web']}
                  size="4x"
                  className="mr-3"
                  fixedWidth
                />
                <div className="media-body">
                  <p>
                    You don't have any assessments to show.{' '}
                    {userGroupAssessments.length > 0 &&
                      '(You have some archived ones though.)'}
                  </p>
                  <p>
                    Create a new assessment for this user group to get in on the
                    fun.
                  </p>
                </div>
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default UserGroupDashboard;
