import { SchemaItem } from './SchemaItem';

export class SchemaArray extends SchemaItem {
  constructor(entity, optionOverrides = {}) {
    super();

    const options = {
      positionProperty: entity.key + 'Positions',
      ...optionOverrides,
    };

    this.entity = entity;
    this.positionProperty = options.positionProperty;
  }

  getPositionKey(state) {
    throw new Error('Not implemented');
  }
}
