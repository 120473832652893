import { useMemo } from 'react';
import { useCollaborators } from './useCollaborators';

export function useCollaboratorForField(parentType, parentId, field) {
  const collaborators = useCollaborators();
  return useMemo(() => {
    return collaborators.find(
      (c) =>
        c.focusedField?.parentType === parentType &&
        c.focusedField?.parentId === parentId &&
        c.focusedField?.field === field
    );
  }, [collaborators, parentType, parentId, field]);
}
