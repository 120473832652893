import { useState, useLayoutEffect, useMemo } from 'react';
import { useId } from 'js/hooks/useId';
import { useParentSize } from '@visx/responsive';
import styles from '../components/AnswerSets/AnswerSet.module.scss';

export default function useNthChildOffsetStyle(choices) {
  const { parentRef, width } = useParentSize({ debounceTime: 0 });
  const id = useId();

  const [nthChildOffset, setNthChildOffset] = useState(null);

  useLayoutEffect(() => {
    const parentElement = parentRef.current;
    let previousOffset = null;

    if (parentElement) {
      const flexChildren = Array.from(parentElement.children);
      const newRowIndex = flexChildren.findIndex((flexChild) => {
        previousOffset = previousOffset ?? flexChild.offsetTop;
        return flexChild.offsetTop > previousOffset;
      });

      if (newRowIndex > 0) {
        setNthChildOffset(newRowIndex);
      } else {
        setNthChildOffset(null);
      }
    } else {
      setNthChildOffset(null);
    }
  }, [parentRef, width, choices.length]);

  const style = useMemo(
    () =>
      `#${id} .${styles.choice}:nth-child(${nthChildOffset}n+1)::before { display: none !important; }`,
    [id, nthChildOffset]
  );

  return { id, style, containerRef: parentRef };
}
