import { useMemo, useState, useCallback } from 'react';
import { ResponseContext } from './ResponseContext';

export function PreviewResponseContextProvider({
  children,
  assessment,
  participant,
}) {
  const questionMap = useMemo(() => {
    return assessment.question_sets.reduce(
      (qsAcc, qs) =>
        qs.questions.reduce((qAcc, q) => {
          qAcc[q.key] = q;
          return qAcc;
        }, qsAcc),
      {}
    );
  }, [assessment]);

  const [responses, setResponses] = useState({});

  const respond = useCallback(
    (questionId, response) => {
      setResponses({
        ...responses,
        [questionId]: response,
      });
    },
    [responses]
  );

  const unrespond = useCallback(
    (questionId) => {
      setResponses({
        ...responses,
        [questionId]: null,
      });
    },
    [responses]
  );

  // const responses = useMemo(() => {
  //   return Object.fromEntries(
  //     Object.entries(questionMap).map(([key, question]) => [
  //       key,
  //       question.responses.find((r) => r.participant_id === participant.id),
  //     ])
  //   );
  // }, [questionMap, participant]);

  return (
    <ResponseContext.Provider
      value={{ questionMap, responses, respond, unrespond, participant }}
    >
      {children}
    </ResponseContext.Provider>
  );
}
