import React from 'react';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { Icon } from 'js/components';
import AsyncButton from 'js/components/Forms/AsyncButton';

import { faRight } from '@fortawesome/pro-solid-svg-icons';

import RecentList from './RecentList';
import TemplateList from './TemplateList';
import styles from './AssessUserGroup.module.scss';

function ChooseSourceAssessment({
  onCreateFromScratch,
  onCreateFromTemplate,
  onCreateFromRecent,
}) {
  return (
    <div className={styles['sources']}>
      <div id="start-from-scratch" className={styles['assessment-source']}>
        <Card>
          <CardHeader>
            <CardTitle tag="h5">Start from scratch</CardTitle>
            <p>
              Build a new assessment by choosing the capabilities and questions
              to ask
            </p>
          </CardHeader>
          <CardBody>
            <AsyncButton
              color="primary"
              className={styles['scratch-button']}
              onClick={onCreateFromScratch}
            >
              <span>
                Create a new
                <br />
                assessment
                <Icon icon={faRight} className="icon-right" />
              </span>
            </AsyncButton>
          </CardBody>
        </Card>
      </div>
      <div id="use-recent-assessment" className={styles['assessment-source']}>
        <Card>
          <CardHeader>
            <CardTitle tag="h5">Recent assessments</CardTitle>
            <p>
              Re-run a previous assessment or use it as the basis for additional
              customization
            </p>
          </CardHeader>
          <CardBody>
            <RecentList onSelect={onCreateFromRecent} />
          </CardBody>
        </Card>
      </div>
      <div id="use-template" className={styles['assessment-source']}>
        <Card>
          <CardHeader>
            <CardTitle tag="h5">Use a template</CardTitle>
            <p>
              Customize a template that starts with a standard question bank and
              structure. Choose the capabilities you want to assess
            </p>
          </CardHeader>
          <CardBody>
            <TemplateList onSelect={onCreateFromTemplate} />
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default ChooseSourceAssessment;
