import React from 'react';
import ListItem from './ListItem';
import { PanelHeader } from 'js/components';
import { Card, CardBody } from 'reactstrap';

const NullAssessmentEngine = () => {
  return <div />;
};

const NullAssessmentEngineWithHeader = () => {
  return (
    <div className="review">
      <PanelHeader size="flex"></PanelHeader>
      <div className="content">
        <Card>
          <CardBody>
            <br />
            <br />
            <br />
            <br />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

const NullAssessment = {
  Run: NullAssessmentEngine,
  Check: NullAssessmentEngine,
  Review: NullAssessmentEngineWithHeader,
  Configure: NullAssessmentEngine,
  Launch: NullAssessmentEngine,
  ListItem,
  checks: {
    requireTeam: false,
  },
  strategies: {
    create: () => ({ type: 'NOOP' }),
    createFromDocTemplate: () => ({ type: 'NOOP' }),
    load: () => ({ type: 'NOOP' }),
    buildPostCreateRedirectPath: () => `/`,
  },
};

export default NullAssessment;
