import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { SCHEMAS } from 'js/api/schemas';
import { loadUserGroup } from 'js/actions/user-group-actions';

import CapabilitiesAssessmentReviewDetailEngine from './CapabilitiesAssessmentReviewDetailEngine';

const CapabilitiesAssessmentReviewEngine = ({
  userGroupAssessment,
  loadUserGroup,
  ...props
}) => {
  useEffect(() => {
    if (userGroupAssessment?.user_group_id) {
      loadUserGroup(userGroupAssessment?.user_group_id);
    }
  }, [loadUserGroup, userGroupAssessment?.user_group_id]);

  // const { assessmentId } = useParams();

  // const { assessment } = useSelector(
  //   (state) => state.entities.assessments[assessmentId]
  // );

  // const { teamId, loadTeam, notify } = props;

  // useEffect(() => {
  //   loadTeam(teamId);
  // }, [teamId, loadTeam]);

  // const handleCopyLink = () => {
  //   notify({ type: 'success', message: 'Link copied to clipboard' });
  // };

  return (
    <CapabilitiesAssessmentReviewDetailEngine
      {...props}
      userGroupAssessment={userGroupAssessment}
      // onCopyLink={handleCopyLink}
    />
  );
};

export default connect(
  (state, { userGroupAssessment }) => {
    return {
      userGroup: denormalize(
        state.entities.userGroups[userGroupAssessment?.user_group_id],
        SCHEMAS.USER_GROUP,
        state.entities
      ),
    };
  },
  { loadUserGroup }
)(CapabilitiesAssessmentReviewEngine);
