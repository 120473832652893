import { createContext, useContext, useMemo } from 'react';
import standardCohortKeys from 'js/utils/cohort-keys';
import { getPageCapabilities } from 'js/engines/CapabilitiesAssessment/utils';
import { buildCapabilitySetsFromCache } from 'js/ydoc/docs/capabilities-assessment/capability-utils';

function getPageData(page, _assessment, capabilitySets) {
  switch (page.type) {
    case 'capability-questions':
    default:
      return {
        pageCapabilities: getPageCapabilities(
          capabilitySets[page.capabilitySetId],
          page.selectedCapabilities
        ),
      };
  }
}

export const AssessmentReviewContext = createContext({
  questionMap: {},
  cohorts: [],
  participantCohortMap: {},
  pageData: {},
  capabilitySets: {},
});

export function AssessmentReviewContextProvider({ children, assessment }) {
  const questionMap = useMemo(() => {
    return assessment.question_sets.reduce(
      (qsAcc, qs) =>
        qs.questions.reduce((qAcc, q) => {
          qAcc[q.key] = q;
          return qAcc;
        }, qsAcc),
      {}
    );
  }, [assessment]);

  const capabilitySets = useMemo(() => {
    if (assessment?.document) {
      return buildCapabilitySetsFromCache(
        assessment.document.capabilitySetCache,
        assessment.document.capabilityCache
      );
    }
    return {};
  }, [assessment]);

  const pageData = useMemo(() => {
    return assessment.document.pages.reduce((pAcc, p) => {
      pAcc[p.guid] = getPageData(p, assessment, capabilitySets);
      return pAcc;
    }, {});
  }, [assessment, capabilitySets]);

  const { cohorts, participantCohortMap } = useMemo(() => {
    const { cohortMap, participantMap: participantCohortMap } = (
      assessment?.participants ?? []
    ).reduce(
      (acc, p) => {
        const cohort =
          p.cohort === null
            ? { value: standardCohortKeys.NULL, text: '(None)' }
            : { value: p.cohort, text: p.cohort };
        acc.cohortMap[cohort.value] = cohort;
        acc.participantMap[p.id] = cohort.value;
        return acc;
      },
      { cohortMap: {}, participantMap: {} }
    );

    return {
      cohorts: Object.values(cohortMap),
      participantCohortMap,
    };
  }, [assessment]);

  const context = useMemo(() => {
    return {
      questionMap,
      cohorts,
      participantCohortMap,
      pageData,
      capabilitySets,
    };
  }, [questionMap, cohorts, participantCohortMap, pageData, capabilitySets]);

  return (
    <AssessmentReviewContext.Provider value={context}>
      {children}
    </AssessmentReviewContext.Provider>
  );
}

export function useAssessmentReviewContext() {
  return useContext(AssessmentReviewContext);
}
