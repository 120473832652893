import React, { useMemo } from 'react';
import { standardCollator } from 'js/utils/string';

function AnswerSet({ answerSet }) {
  const options = useMemo(() => {
    return Object.values(answerSet.choices).sort((a, b) =>
      standardCollator.compare(a.p, b.p)
    );
  }, [answerSet.choices]);

  return (
    <datalist key={answerSet.id} id={answerSet.guid + '-datalist'}>
      {options.map((o, i) => (
        <option key={i} value={i} data-value={o.value}>
          {o.text}
        </option>
      ))}
    </datalist>
  );
}

export default AnswerSet;
