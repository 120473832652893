import React, { useMemo, useEffect, useState } from 'react';
import { trimEnd } from 'lodash';

import { Link } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { useRouteMatch } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import NoCapabilitySetsCard from './NoCapabilitySetsCard';
// import { makeStyles } from '@material-ui/core/styles';
import {
  Loading as LoadingIndicator,
  PanelHeader,
  Icon,
  ReplaceLink,
} from 'js/components';
import CapabilitySetCard from './CapabilitySetCard';
// import EmptyState from 'js/components/EmptyState';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     padding: theme.spacing(3),
//   },
//   header: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     marginBottom: theme.spacing(3),
//   },
//   card: {
//     height: '100%',
//   },
//   cardContent: {
//     height: '100%',
//     display: 'flex',
//     flexDirection: 'column',
//   },
//   description: {
//     marginTop: theme.spacing(1),
//     marginBottom: theme.spacing(2),
//     flexGrow: 1,
//   },
// }));

function CapabilitySetsDashboardComponent({
  capabilitySets,
  setsQuery,
  workspace,
  onArchiveCapabilitySet,
  onRestoreCapabilitySet,
}) {
  const searchParams = new URLSearchParams(window.location.search);
  const match = useRouteMatch();
  const showArchived = searchParams.has('archived');
  const [hasLoaded, setHasLoaded] = useState(setsQuery?.finishedAt > 0);

  useEffect(() => {
    if (setsQuery?.finishedAt) {
      setHasLoaded(true);
    }
  }, [setsQuery?.finishedAt]);

  const filteredCapabilitySets = useMemo(() => {
    if (!capabilitySets?.length) return [];

    const filter = showArchived
      ? (capabilitySet) => capabilitySet.archived_state === 'archived'
      : (capabilitySet) => capabilitySet.archived_state !== 'archived';
    return capabilitySets.filter(filter);
  }, [capabilitySets, showArchived]);

  return (
    <div>
      <PanelHeader size="flex">
        <div className="panel-body-no-label px-4">
          <div className="content-fluid">
            <div className="row">
              <div className="col-12 col-md-9">
                <h1>Capability Sets</h1>
                <p>
                  Organize the capabilities of your products or organizations to
                  assess their health and desire for additional investment
                </p>
              </div>
              <div className="panel-actions col-12 col-md-3">
                <Link
                  className="btn btn-primary create-link"
                  to={`capability-sets/new`}
                >
                  <Icon icon="plus" className="btn-icon-left" /> Create
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <Nav className="tab-nav nav-underline">
            <NavItem>
              <NavLink
                tag={ReplaceLink}
                active={!searchParams.has('archived')}
                data-view="active"
                to={`${trimEnd(match.url, '/')}`}
              >
                Active
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={ReplaceLink}
                active={searchParams.has('archived')}
                data-view="archived"
                to={`${trimEnd(match.url, '/')}?archived`}
              >
                Archived
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </PanelHeader>
      <div className="content" style={{ position: 'relative' }}>
        {!hasLoaded && !filteredCapabilitySets?.length && (
          <Card>
            <CardBody>
              <p></p>
            </CardBody>
          </Card>
        )}

        {hasLoaded && !filteredCapabilitySets?.length && (
          <NoCapabilitySetsCard type={showArchived ? 'archived' : 'default'} />
        )}

        {filteredCapabilitySets.map((capabilitySet) => (
          <CapabilitySetCard
            key={capabilitySet.id}
            capabilitySet={capabilitySet}
            workspace={workspace}
            onArchive={onArchiveCapabilitySet}
            onRestore={onRestoreCapabilitySet}
          />
        ))}

        {setsQuery?.isLoading && (
          <LoadingIndicator
            style={{ position: 'absolute', right: '25px', top: '10px' }}
          />
        )}
      </div>
    </div>
  );
}

// CapabilitySetsDashboardComponent.propTypes = {
//   capabilitySets: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.string.isRequired,
//       name: PropTypes.string.isRequired,
//       description: PropTypes.string,
//     })
//   ),
//   setsQuery: PropTypes.shape({
//     isLoading: PropTypes.bool,
//   }),
//   workspace: PropTypes.shape({
//     id: PropTypes.string.isRequired,
//   }).isRequired,
// };

export default CapabilitySetsDashboardComponent;
