import React, { useMemo } from 'react';
import CapabilityQuestion from './CapabilityQuestion';
import { useAssessmentContext } from '../../../../../AssessmentContext';
import { useResponseContext } from '../../../../../ResponseContext';
import { Card, CardBody } from 'reactstrap';
import { getQuestionId } from 'js/engines/CapabilitiesAssessment/utils';

import styles from './CapabilitiesPage.module.scss';

function CapabilityQuestionSet({
  capabilitySet,
  capabilityQuestion,
  capabilities,
}) {
  const { answerSets } = useAssessmentContext();
  const { questionMap, responses } = useResponseContext();

  // const { assessment, answerSets } = useAssessmentContext();

  // const max = useMemo(
  //   () => (answerSets?.[question.answerSetId]?.choices?.length ?? 1) - 1,
  //   [answerSets, question.answerSetId]
  // );

  const choices = useMemo(() => {
    const answerSet = answerSets[capabilityQuestion.answerSetId];

    return answerSet?.choices ?? [];
  }, [answerSets, capabilityQuestion]);

  const max = Math.max(choices.length - 1, 0);

  return (
    <Card>
      <CardBody>
        <div className={styles['capabilities-set-question-set']}>
          <h3>
            {capabilitySet.name}: {capabilityQuestion.name}
          </h3>
          <div className={styles['capabilities-set-question-set-text']}>
            {capabilityQuestion.text}
          </div>
          <div className={styles['capabilities-set-questions']}>
            <div className={styles['capabilities-set-header']}>
              <div></div>
              <div className={styles['capabilities-set-header-minmax']}>
                {choices.length > 0 && (
                  <>
                    <div className={styles['minmax-label']}>
                      {choices[0].text}
                    </div>
                    <div className={styles['minmax-label']}>
                      {choices[max].text}
                    </div>
                  </>
                )}
              </div>
            </div>
            {capabilities.map((capability) => {
              const questionId = getQuestionId(
                capabilityQuestion,
                capability,
                questionMap
              );
              const response = responses[questionId];

              return (
                <CapabilityQuestion
                  key={capability.id}
                  questionId={questionId}
                  capability={capability}
                  question={capabilityQuestion}
                  choices={choices}
                  max={max}
                  response={response}
                />
              );
            })}
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default CapabilityQuestionSet;
