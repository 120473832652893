import React from 'react';
import { Icon } from 'js/components';

const Loading = ({ ...props }) => {
  return (
    <div className="loading" {...props}>
      <Icon icon={['fal', 'circle-notch']} spin />
    </div>
  );
};

export default Loading;
