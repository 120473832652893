import React, { useMemo, useEffect, useCallback } from 'react';
import EditorTool from 'js/components/Assessment/Editors/common/Tools/EditorTool';
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { useEditorContext } from 'js/ydoc/docs/capabilities-assessment/EditorContext';
import { useStandardCollator } from 'js/utils/string';
import CapabilityElement from './CapabilityElement';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import styles from './CapabilityQuestionBank.module.scss';
import { Icon } from 'js/components';

function CapabilityQuestionBank() {
  const {
    assessment,
    moveAnswerSetChoiceBefore,
    moveAnswerSetChoiceAfter,
    addCapabilityElement,
  } = useEditorContext();
  const { capabilityElements = {} } = assessment ?? {};
  const collator = useStandardCollator();

  const sortedCapabilityElements = useMemo(
    () =>
      Object.values(capabilityElements).sort((a, b) =>
        collator.compare(a.name, b.name)
      ),
    [capabilityElements, collator]
  );

  const handleAddQuestion = useCallback(() => {
    addCapabilityElement({
      text: '',
      name: 'New Question',
    });
  }, [addCapabilityElement]);

  useEffect(() => {
    return monitorForElements({
      onDrop({ source, location }) {
        if (location.current.dropTargets.length <= 0) {
          return;
        }

        if (source.data.choice) {
          const {
            choice: sourceChoice,
            index: sourceIndex,
            answerSetId,
          } = source.data;
          const { choice: dropTargetChoice, index: dropTargetIndex } =
            location.current.dropTargets[0].data;
          const closestEdge = extractClosestEdge(
            location.current.dropTargets[0].data
          );

          const moveBefore = closestEdge === 'top' || closestEdge === 'left';

          console.log({
            moveBefore,
            sourceIndex,
            dropTargetIndex,
            answerSetId,
            source,
            location,
          });

          if (moveBefore && sourceIndex !== dropTargetIndex - 1) {
            moveAnswerSetChoiceBefore(
              answerSetId,
              sourceChoice.guid,
              dropTargetChoice.guid
            );
          } else if (!moveBefore && sourceIndex !== dropTargetIndex + 1) {
            moveAnswerSetChoiceAfter(
              answerSetId,
              sourceChoice.guid,
              dropTargetChoice.guid
            );
          }
        }
      },
    });
  }, [moveAnswerSetChoiceBefore, moveAnswerSetChoiceAfter]);

  return (
    <EditorTool title="Capability Questions" collapsible>
      <div className={styles['capability-elements-actions']}>
        <button
          color="primary"
          onClick={handleAddQuestion}
          className="btn-inline-action btn-primary"
        >
          <Icon icon={faCirclePlus} />
        </button>
      </div>
      <div className={styles['capability-elements']}>
        {sortedCapabilityElements.map((element) => (
          <CapabilityElement key={element.guid} element={element} />
        ))}
      </div>
    </EditorTool>
  );
}

export default CapabilityQuestionBank;
