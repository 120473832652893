export const Entitlements = Object.freeze({
  TEAMS_PER_ACCOUNT: 'teams_per_account',
  WORKSPACES_PER_ACCOUNT: 'workspaces_per_account',
  ALLOW_ORGS: 'allow_orgs',
  ALLOW_ASSESSMENT_ADMIN: 'allow_assessment_admin',
  ALLOW_WORKSPACE_INVITES: 'allow_workspace_invites',
  ALLOW_AUTOSCALING_LICENSES: 'allow_autoscaling_licenses',
  ALLOW_PRODUCT: 'allow_product',
});

export function maxTeamsPerAccount(account) {
  return limitForKey(account?.entitlements, Entitlements.TEAMS_PER_ACCOUNT);
}

export function maxWorkspacesPerAccount(account) {
  return limitForKey(
    account?.entitlements,
    Entitlements.WORKSPACES_PER_ACCOUNT
  );
}

export function allowProduct(account) {
  return hasKey(account?.entitlements, Entitlements.ALLOW_PRODUCT);
}

export function allowOrgs(account) {
  return hasKey(account?.entitlements, Entitlements.ALLOW_ORGS);
}

export function allowWorkspaceInvites(account) {
  return hasKey(account?.entitlements, Entitlements.ALLOW_WORKSPACE_INVITES);
}

export function allowAutoscalingLicenses({ entitlements }) {
  return hasKey(entitlements, Entitlements.ALLOW_AUTOSCALING_LICENSES);
}

export function allows(account, entitlement_key) {
  return hasKey(account.entitlements, entitlement_key);
}

// Private functions

function hasKey(entitlements, key) {
  entitlements = entitlements ?? [];
  return entitlements.some((e) => e.entitlement_key === key);
}

function limitForKey(entitlements, key) {
  entitlements = entitlements ?? [];
  const e = entitlements.find((e) => e.entitlement_key === key);
  return e ? e.limit ?? Number.POSITIVE_INFINITY : 0;
}
