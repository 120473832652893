import React from 'react';
import { RunComponent } from '../components/Run';
import { ResponseContextProvider } from '../ResponseContext';

function CapabilitiesAssessmentRunEngine({ assessment, participant }) {
  return (
    <ResponseContextProvider assessment={assessment} participant={participant}>
      <RunComponent assessment={assessment} />
    </ResponseContextProvider>
  );
}

export default CapabilitiesAssessmentRunEngine;
