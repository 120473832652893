import React from 'react';

import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { LeftArrow, RightArrow } from './Arrows';
import RecentCard from './RecentCard';

function RecentList() {
  return (
    <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
      <RecentCard />
      <RecentCard />
      <RecentCard />
      <RecentCard />
      <RecentCard />
      <RecentCard />
    </ScrollMenu>
  );
}

export default RecentList;
