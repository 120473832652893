import React from 'react';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import styles from './CapabilityTree.module.scss';

function BaseCapabilityForm({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  submitText = 'Save Changes',
  cancelText = 'Cancel',
  onCancel = () => {},
  isOpen = false,
}) {
  const formikAttributes = (fieldName) => ({
    id: fieldName,
    name: fieldName,
    value: values[fieldName],
    onChange: handleChange,
    onBlur: handleBlur,
    invalid: errors[fieldName] && touched[fieldName],
  });

  const formikFeedback = (fieldName) => {
    return errors[fieldName] && touched[fieldName] ? (
      <FormFeedback>{errors[fieldName]}</FormFeedback>
    ) : null;
  };

  return (
    <Modal isOpen={isOpen} className={styles.modal}>
      <ModalHeader>
        <div className="primary">Add a capability</div>
      </ModalHeader>
      <ModalBody>
        <div className={styles['capability-form']}>
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <FormGroup>
                  <Label for="name" className="label-sm">
                    Name
                  </Label>
                  <Input type="text" {...formikAttributes('name')} />
                  {formikFeedback('name')}
                </FormGroup>
              </div>
            </div>

            <FormGroup>
              <Label for="description" className="label-sm">
                Description
              </Label>
              <Input type="textarea" {...formikAttributes('description')} />
              {formikFeedback('description')}
            </FormGroup>

            <Button type="submit" color="primary" disabled={isSubmitting}>
              {isSubmitting ? 'Saving...' : submitText}
            </Button>
            <Button
              type="cancel"
              color="secondary"
              disabled={isSubmitting}
              onClick={onCancel}
            >
              {cancelText}
            </Button>
          </Form>
        </div>
      </ModalBody>
    </Modal>
  );
}

const CapabilityForm = withFormik({
  mapPropsToValues: ({ capability }) => ({
    name: capability?.name || '',
    description: capability?.description || '',
  }),

  validationSchema: Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string(),
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    const { onUpdate = () => {} } = props;
    Promise.resolve(onUpdate(values)).then(() => {
      setSubmitting(false);
    });
  },
})(BaseCapabilityForm);

export default CapabilityForm;
