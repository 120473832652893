import React, { useState, useMemo, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { faGripVertical } from '@fortawesome/pro-solid-svg-icons';
import {
  faChevronRight,
  faCirclePlus,
} from '@fortawesome/pro-regular-svg-icons';
import { Icon } from 'js/components';
import useEditorInput from 'js/components/Assessment/Editors/common/hooks/useEditorInput';
import { FormGroup, Input } from 'reactstrap';
import TextareaAutosize from 'react-textarea-autosize';
import { useEditorContext } from 'js/ydoc/docs/capabilities-assessment/EditorContext';
import LikertChoiceList from 'js/components/Assessment/Editor/EditorTools/AnswerSetTool/LikertAnswerSet/LikertChoiceList';
import { useStandardCollator, isNullOrWhitespace } from 'js/utils/string';
import classNames from 'classnames';

import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';

import { scaleColorTraffic } from 'js/utils/color';
import { getAnswerDomain } from 'js/utils/scoring';
import styles from './CapabilityQuestionBank.module.scss';
import answerSetStyles from 'js/components/Assessment/Editor/EditorTools/AnswerSetTool/AnswerSet.module.scss';

function getColor(choices) {
  return scaleColorTraffic.copy().domain(getAnswerDomain(choices, 'value', 3));
}

function DragPreview({ question }) {
  return (
    <div className={styles['capability-element-preview']}>
      <div className={styles['capability-element-header']}>
        <div className="drag-handle">
          <Icon icon={faGripVertical} />
        </div>
        <div className={styles['capability-element-name']}>{question.name}</div>
        <div></div>
      </div>
      <div className={styles['capability-element-text']}>
        {isNullOrWhitespace(question.text) ? (
          <span className="font-italic">[No text]</span>
        ) : (
          question.text
        )}
      </div>
    </div>
  );
}

function CapabilityQuestion({ question }) {
  const [showSettings, setShowSettings] = useState(false);
  const [dragging, setDragging] = useState(false);

  const ref = useRef(null);
  const dragHandleRef = useRef(null);

  const {
    updateCapabilityElement,
    updateAnswerSetChoice,
    deleteAnswerSetChoice,
    addAnswerSetChoice,
    assessment,
  } = useEditorContext();
  const answerSet = assessment.answerSets[question.answerSetId];
  const collator = useStandardCollator();

  const answerSetChoices = answerSet.choices;
  const choiceArray = useMemo(() => {
    return Object.values(answerSetChoices).sort((a, b) =>
      collator.compare(a.p, b.p)
    );
  }, [answerSetChoices, collator]);

  const color = getColor(choiceArray);

  useEffect(() => {
    const el = ref.current;
    const dragHandle = dragHandleRef.current;
    if (!el || !dragHandle) {
      throw new Error('Required elements not found');
    }

    return draggable({
      element: el,
      dragHandle,
      getInitialData: () => ({ capabilityElement: question }),
      onDragStart: ({ source }) => {
        source.element.classList.remove('drag-preview');
        setDragging(true);
      },
      onGenerateDragPreview: ({ nativeSetDragImage }) => {
        setCustomNativeDragPreview({
          render({ container }) {
            ReactDOM.render(<DragPreview question={question} />, container);
            return function cleanup() {
              ReactDOM.unmountComponentAtNode(container);
            };
          },
          getOffset: () => ({ x: 10, y: 16 }),
          nativeSetDragImage,
        });
      },
      onDrop: () => {
        setDragging(false);
      },
    });
  }, [question]);

  const handleTextChange = (text) => {
    updateCapabilityElement(question.guid, { text });
  };

  const handleNameChange = (name) => {
    updateCapabilityElement(question.guid, { name });
  };

  const handleKeyChange = (key) => {
    updateCapabilityElement(question.guid, { key });
  };

  const {
    reset: resetName,
    ref: nameRef,
    ...nameInputProps
  } = useEditorInput({
    value: question.name,
    onCommit: handleNameChange,
  });

  const {
    reset: resetKey,
    ref: keyRef,
    ...keyInputProps
  } = useEditorInput({
    value: question.key,
    onCommit: handleKeyChange,
  });

  const {
    reset: resetText,
    ref: resetTextRef,
    ...textInputProps
  } = useEditorInput({
    value: question.text,
    onCommit: handleTextChange,
  });

  const handleChoiceUpdate = (choice, updates) => {
    updateAnswerSetChoice(answerSet.guid, choice.guid, updates);
  };

  const handleChoiceDelete = (choiceGuid) => {
    deleteAnswerSetChoice(answerSet.guid, choiceGuid);
  };

  const handleAddChoice = () => {
    addAnswerSetChoice(answerSet.guid, {});
  };

  const className = useMemo(() => {
    return classNames(styles['capability-element'], {
      [styles.dragging]: dragging,
    });
  }, [dragging]);

  return (
    <div className={className} ref={ref}>
      <div className={styles['capability-element-header']}>
        <div>
          <button className="drag-handle" ref={dragHandleRef}>
            <Icon icon={faGripVertical} />
          </button>
        </div>
        <div className={styles['capability-element-name']}>{question.name}</div>
        <div
          className={`${styles['capability-element-actions']} ${
            showSettings ? styles['is-open'] : null
          }`}
        >
          <button
            aria-pressed={showSettings}
            onClick={() => setShowSettings(!showSettings)}
          >
            <Icon icon={faChevronRight} rotation={showSettings ? 90 : 0} />
          </button>
        </div>
      </div>
      {!showSettings ? (
        <div
          className={styles['capability-element-text']}
          title={question.text}
        >
          {isNullOrWhitespace(question.text) ? (
            <span className="font-italic">[No text]</span>
          ) : (
            question.text
          )}
        </div>
      ) : (
        <div
          className={styles['capability-element-settings']}
          title={question.text}
        >
          <FormGroup>
            <label>Name</label>
            <Input
              className="form-control"
              type="text"
              {...nameInputProps}
              placeholder="e.g., Satisfaction"
            />
          </FormGroup>

          <FormGroup>
            <label>Key</label>
            <Input
              className="form-control"
              type="text"
              {...keyInputProps}
              placeholder="e.g., satisfaction"
            />
          </FormGroup>

          <FormGroup>
            <label>Text</label>
            <TextareaAutosize
              className="form-control"
              minRows={3}
              type="textarea"
              {...textInputProps}
              placeholder="e.g. Rate your current level of satisfaction with the following capabilities:"
            />
          </FormGroup>
          <FormGroup>
            <div className={answerSetStyles['answer-set-choices']}>
              <div className={answerSetStyles['choices-controls']}>
                <div>
                  <label>Choices</label>
                </div>
                <div>
                  <button
                    className="btn-inline-action btn-primary"
                    onClick={handleAddChoice}
                  >
                    <Icon icon={faCirclePlus} />
                  </button>
                </div>
              </div>
              <LikertChoiceList
                answerSetId={answerSet.guid}
                isStandard={false}
                choices={choiceArray}
                color={color}
                includeNA={false}
                naText={''}
                onChoiceUpdate={handleChoiceUpdate}
                onChoiceDelete={handleChoiceDelete}
              />
            </div>
          </FormGroup>
        </div>
      )}
    </div>
  );
}

export default CapabilityQuestion;
