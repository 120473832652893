import React, { useMemo } from 'react';
import { PanelHeader } from 'js/components';
import { Breadcrumbs } from 'js/components/Breadcrumbs';
import { useParams, useRouteMatch, Switch, Route } from 'react-router-dom';
import { LaunchInterface as CapabilitiesAssessmentEditor } from 'js/components/Assessment/Editors/CapabilitiesAssessmentEditor';
import LaunchConfiguration from './LaunchConfiguration';
import StatusBadge from './StatusBadge';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

function CustomizeAssessment({
  userGroupAssessment,
  userGroup,
  capabilitySets,
  onFieldChanges = () => {},
  onAssessmentCreate = () => {},
}) {
  const { workspaceId, userGroupId } = useParams();
  const { path, url } = useRouteMatch();

  const breadcrumbs = [
    {
      label: userGroup?.name,
      to: `/w/${workspaceId}/pm/user-groups/${userGroupId}`,
    },
  ];

  const statusMessage = useMemo(() => {
    switch (
      userGroupAssessment?.archived_state ??
      userGroupAssessment?.status
    ) {
      case 'draft':
        return 'Get ready to go live';
      case 'published':
        return "You're almost live!";
      case 'live':
        return 'Your assessment is live!';
      case 'archived':
        return 'This assessment has been archived';
      case 'closed':
        return userGroupAssessment.close_at < new Date()
          ? 'The assessment period has ended'
          : 'Your assessment will be reopened shortly';
      default:
        return null;
    }
  }, [
    userGroupAssessment?.status,
    userGroupAssessment?.close_at,
    userGroupAssessment?.archived_state,
  ]);

  if (!userGroupAssessment) {
    return null;
  }

  return (
    <Switch>
      <Route path={`${path}/launch`}>
        <div>
          <PanelHeader size="flex">
            <div className="d-flex justify-content-between align-items-end">
              <div className="panel-body-no-label px-4">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
                <h1>{statusMessage}</h1>
              </div>
              <div className="panel-body-no-label px-4">
                <StatusBadge status={userGroupAssessment.status} />
              </div>
            </div>
          </PanelHeader>
          <div className="content">
            <LaunchConfiguration
              userGroupAssessment={userGroupAssessment}
              onFieldChanges={onFieldChanges}
              onAssessmentCreate={onAssessmentCreate}
            />
            {/* {userGroupAssessment && (
              <CapabilitiesAssessmentEditor
                userGroupAssessment={userGroupAssessment}
                capabilitySets={capabilitySets}
              />
            )} */}
          </div>
        </div>
      </Route>
      <Route path={`${path}`}>
        {userGroupAssessment.went_live_at ? (
          <Redirect to={`${url}/launch`} />
        ) : (
          <div>
            <PanelHeader size="flex">
              <div className="panel-body-no-label px-4">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
                <h1>Customize your assessment</h1>
              </div>
            </PanelHeader>
            <div className="content">
              {userGroupAssessment && (
                <CapabilitiesAssessmentEditor
                  userGroupAssessment={userGroupAssessment}
                  capabilitySets={capabilitySets}
                />
              )}
            </div>
          </div>
        )}
      </Route>
    </Switch>
  );
}

export default CustomizeAssessment;
