import React from 'react';
// import { PanelHeader, LoadingMessage } from 'js/components';
// import { Card, CardBody } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { useParams } from 'react-router-dom';

function ProductManagementDashboard() {
  const { workspaceId } = useParams();

  return <Redirect to={`/w/${workspaceId}/pm/capability-sets`} />;
}

export default ProductManagementDashboard;
