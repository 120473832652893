import React from 'react';
import { groupBy } from 'lodash';

import {
  faExclamationTriangle,
  faCheckCircle,
} from '@fortawesome/pro-solid-svg-icons';

import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import { Icon } from 'js/components';
import ErrorGroup from './ErrorGroup';

import styles from './EditorErrors.module.scss';

function keyOrDefault(key, defaultKey = '[NO KEY]') {
  return key != null && key.trim().length > 0 ? key : defaultKey;
}

function ErrorGroupTitle({ group, identifier }) {
  return (
    <h5>
      {group != null && (
        <span className="text-uppercase">
          {group}
          {group != null && identifier != null && ': '}
        </span>
      )}
      {identifier != null && <span>{identifier}</span>}
    </h5>
  );
}

const groupTitle = {
  meta: () => <ErrorGroupTitle group="General" />,
  element: ({ groupElement }) => {
    switch (groupElement.elementType) {
      case 'question':
        return (
          <ErrorGroupTitle
            group="Question"
            identifier={keyOrDefault(groupElement.key)}
          />
        );
      default:
        return (
          <ErrorGroupTitle
            group="Element"
            identifier={keyOrDefault(
              groupElement.key ?? groupElement.guid ?? groupElement.id
            )}
          />
        );
    }
  },
  page: ({ groupElement, ...rest }) => {
    switch (groupElement.pageType ?? groupElement.type) {
      case 'capability-questions':
        console.log({ groupElement, rest });
        return (
          <ErrorGroupTitle
            group="Page"
            identifier={keyOrDefault(groupElement.guid)}
          />
        );
      case 'question-set':
        return (
          <ErrorGroupTitle
            group="Question Set"
            identifier={keyOrDefault(groupElement.key)}
          />
        );
      default:
        return (
          <ErrorGroupTitle
            group="Page"
            identifier={keyOrDefault(
              groupElement.key ?? groupElement.guid ?? groupElement.id
            )}
          />
        );
    }
  },
  default: () => <ErrorGroupTitle group="Other Errors" />,
};

function EditorErrors({ errors }) {
  const groupedErrors = groupBy(errors, (error) => {
    switch (error.group) {
      case 'element':
      case 'page':
        return error.groupElement.guid;
      default:
        return error.group;
    }
  });

  return (
    <div id="editor-errors">
      {errors.length > 0 ? (
        <>
          <span className="text-danger">
            <Icon icon={faExclamationTriangle} className="icon-left" />
          </span>
          {errors.length}
          <UncontrolledPopover
            placement="bottom"
            target="editor-errors"
            trigger="hover click"
            className={styles['editor-errors-popover']}
          >
            <PopoverBody>
              {Object.entries(groupedErrors).map(([groupKey, errors]) => {
                const group = errors[0].group;
                return (
                  <ErrorGroup
                    key={groupKey}
                    title={(groupTitle[group] ?? groupTitle.default)(errors[0])}
                    errors={errors}
                  />
                );
              })}
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ) : (
        <span className="text-success">
          <Icon icon={faCheckCircle} />
        </span>
      )}
    </div>
  );
}

export default EditorErrors;
