import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import CapabilityForm from './CapabilityForm';
import { Icon } from 'js/components';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import styles from './CapabilitySet.module.scss';

function NewCapabilityModal({
  context,
  onSubmit = () => {},
  onCancel = () => {},
  isOpen = false,
}) {
  const { path = [], capabilitySet = null } = context ?? {};
  const parent = path.length > 0 ? path[path.length - 1] : null;

  const handleSubmit = (values) =>
    onSubmit({ ...values, parent_id: parent?.id });

  return (
    <Modal isOpen={isOpen} className={styles.modal}>
      <ModalHeader>
        <div className={styles['modal-path-list']}>
          {path.reverse().map((p) => (
            <div className={styles['modal-path-list-item']} key={p.name}>
              <div className={styles['modal-path-list-item-name']}>
                {p.name}
              </div>
              <div className={styles['modal-path-list-item-chevron']}>&gt;</div>
            </div>
          ))}
          <div className={styles['modal-path-list-item']}>
            <div className={styles['modal-path-list-item-name']}>
              {capabilitySet?.name}
            </div>
            <div className={styles['modal-path-list-item-chevron']}>&gt;</div>
          </div>
        </div>
        <div className="primary">Add a capability</div>
      </ModalHeader>
      <ModalBody>
        <div className={styles['capability-form']}>
          <CapabilityForm
            onSubmit={handleSubmit}
            onCancel={onCancel}
            submitText={
              <>
                <Icon icon={faPlus} className="icon-left" />
                Add
              </>
            }
          />
        </div>
      </ModalBody>
    </Modal>
  );
}

export default NewCapabilityModal;
