import React from 'react';
import { useParams } from 'react-router-dom';
import { Nav } from 'reactstrap';
import { ListNavLink, Icon } from 'js/components';
import {
  faPeopleGroup,
  faFluxCapacitor,
} from '@fortawesome/pro-solid-svg-icons';

const ProductSubmenu = () => {
  const { workspaceId } = useParams();

  return (
    <Nav>
      <ListNavLink to={`/w/${workspaceId}/pm/user-groups`}>
        <i>
          <Icon icon={faPeopleGroup} fixedWidth />
        </i>
        <p>User Groups</p>
      </ListNavLink>
      <ListNavLink to={`/w/${workspaceId}/pm/capability-sets`}>
        <i>
          <Icon icon={faFluxCapacitor} fixedWidth />
        </i>
        <p>Capabilities</p>
      </ListNavLink>
    </Nav>
  );
};

export default ProductSubmenu;
