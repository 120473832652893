export function delay({ waitMs: timeMs, fn }) {
  let timeoutId = window.setTimeout(() => {
    timeoutId = null;
    fn();
  }, timeMs);

  return function cancel() {
    if (timeoutId) {
      window.clearTimeout(timeoutId);
      timeoutId = null;
    }
  };
}
