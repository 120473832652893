import React from 'react';
import { PanelHeader } from 'js/components';
import { Card, CardBody } from 'reactstrap';
import CapabilitySetForm from './CapabilitySetForm';

function CreateUserGroup({ onSubmit }) {
  return (
    <div>
      <PanelHeader size="flex">
        <div className="panel-body-no-label px-4">
          <div className="content-fluid">
            <div className="row">
              <div className="col-12 col-md-9">
                <h1>Create a new capability set</h1>
              </div>
            </div>
          </div>
        </div>
      </PanelHeader>
      <div className="content">
        <Card>
          <CardBody>
            <CapabilitySetForm onSubmit={onSubmit} />
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default CreateUserGroup;
