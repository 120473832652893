import React, { useContext, useEffect } from 'react';
import CapabilityNode from './CapabilityNode';
import CapabilitySetNode from './CapabilitySetNode';
import { useStandardCollator } from 'js/utils/string';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { autoScrollWindowForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';

import { TreeContext } from '../TreeContext';

function CapabilityTree({
  capabilitySet,
  capabilities,
  onAddCapability,
  onEditCapability,
  onReparentCapability,
}) {
  const path = [];
  const standardCollator = useStandardCollator();
  const context = useContext(TreeContext);
  const sortedCapabilities = capabilities.sort((a, b) => {
    return (
      standardCollator.compare(a.name, b.name) ||
      standardCollator.compare(a.id, b.id)
    );
  });

  useEffect(() => {
    return autoScrollWindowForElements();
  }, []);

  useEffect(() => {
    return combine(
      monitorForElements({
        canMonitor: ({ source }) =>
          source.data.uniqueContextId === context.uniqueContextId,
        onDrop: (args) => {
          const { location, source } = args;

          if (location.current.dropTargets.length) {
            const dropTarget = location.current.dropTargets[0];

            if (source.data.type === 'capability') {
              const { id, parent_id } = source.data.capability;

              if (dropTarget.data.type === 'capability') {
                onReparentCapability({
                  id,
                  old_parent_id: parent_id,
                  new_parent_id: dropTarget.data.capability.id,
                });
              } else if (dropTarget.data.type === 'capability-set') {
                onReparentCapability({
                  id,
                  old_parent_id: parent_id,
                  new_parent_id: null,
                });
              }
            }
          }
        },
      })
    );
  }, [context, onReparentCapability]);

  return (
    <div style={{ maxWidth: '600px' }}>
      <CapabilitySetNode
        key={capabilitySet.id}
        capabilitySet={capabilitySet}
        onAddChild={onAddCapability}
        onEdit={onEditCapability}
      />
      {sortedCapabilities.map((capability) => (
        <CapabilityNode
          key={capability.id}
          path={path}
          capability={capability}
          onAddChild={onAddCapability}
          onEdit={onEditCapability}
        />
      ))}
    </div>
  );
}

export default CapabilityTree;
