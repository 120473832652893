import React from 'react';
import { trimEnd } from 'lodash';
import { useRouteMatch, Route, Switch, Redirect } from 'react-router-dom';
import AssessmentRun from './AssessmentRun';
import { AssessmentProvider } from '../../AssessmentContext';
import AssessmentReview from './AssessmentReview';
import AssessmentComplete from './AssessmentComplete';
import { Overlay } from 'js/components';
import WelcomeForm from './WelcomeForm';
import { useResponseContext } from '../../ResponseContext';
function CapabilitiesAssessmentRunComponent({
  assessment,
  onRespond = () => {},
  onUnrespond = () => {},
  backNavigation,
}) {
  const match = useRouteMatch();
  const { completeWelcome, participant } = useResponseContext();

  if (!assessment) {
    return null;
  }
  const doc = assessment.document;
  const firstId = doc.pages[0].guid;

  return (
    <AssessmentProvider assessment={assessment}>
      <Switch>
        <Route exact path={match.path}>
          <Redirect to={`${trimEnd(match.url, '/')}/${firstId}`} />
        </Route>

        <Route path={`${match.path}/review`}>
          <AssessmentReview
            participant={participant}
            assessment={assessment}
            backNavigation={backNavigation}
          />
        </Route>
        <Route path={`${match.path}/complete`}>
          <AssessmentComplete backNavigation={backNavigation} />
        </Route>
        <Route
          path={`${match.path}/:pageId?`}
          render={(routeProps) => (
            <AssessmentRun
              {...routeProps}
              participant={participant}
              id={assessment.id}
              assessment={assessment}
              onRespond={onRespond}
              onUnrespond={onUnrespond}
              backNavigation={backNavigation}
            />
          )}
        />
      </Switch>
      {(!participant || !participant.completed_welcome) && (
        <Overlay>
          {participant && (
            <WelcomeForm
              assessment={assessment}
              team={{}}
              participant={participant}
              onComplete={completeWelcome}
            />
          )}
        </Overlay>
      )}
    </AssessmentProvider>
  );
}

export default CapabilitiesAssessmentRunComponent;
