import { Map as YMap } from 'yjs';
import Big from 'big.js';

export function positionify(array) {
  if (array.length === 0) {
    return array;
  }

  const decimalPlaces = Math.ceil(Math.log10(array.length)) + 1;
  const divisor = Math.pow(10, decimalPlaces);

  const spacer = Math.floor(divisor / (array.length + 1)) / divisor;

  return array.map((item, index) => ({
    ...item,
    p: '' + (index + 1) * spacer,
  }));
}

export function getOrInitializeMap(map, key) {
  let nestedMap = map.get(key);
  if (!nestedMap) {
    nestedMap = new YMap();
    map.set(key, nestedMap);
  }
  return nestedMap;
}

export function updateMap(ymap, newState, customPropHandlers = {}) {
  Object.entries(newState).forEach(([key, value]) => {
    const customHandler = customPropHandlers[key];
    if (customHandler) {
      customHandler(ymap, key, value);
    } else if (value != null && typeof value === 'object') {
      updateMap(getOrInitializeMap(ymap, key), value ?? '');
    } else {
      ymap.set(key, value);
    }
  });
}

export function buildPositionMap(
  currentEntityMap,
  parentType,
  parentId,
  positionOrdinal
) {
  const m = new YMap();
  m.set('t', parentId);

  if (!positionOrdinal) {
    const lastPosition = getLastPosition(
      currentEntityMap,
      parentType,
      parentId
    );
    m.set('p', calcPositionBetween(Big(lastPosition), Big(1)).toString());
  } else {
    m.set('p', positionOrdinal);
  }

  const position = new YMap();
  position.set(parentType, m);

  return position;
}

export function getLastPosition(currentEntityMap, parentType, parentId) {
  let lastPosition = '0';
  currentEntityMap.forEach((q) => {
    if (q.get('position').get(parentType).get('t') !== parentId) {
      return;
    }

    const position = q.get('position').get(parentType).get('p');
    if (lastPosition < position) {
      lastPosition = position;
    }
  });
  return lastPosition;
}

export function calcPositionBetween(bigStart, bigEnd) {
  const divisor = 2 + Math.random();
  return bigEnd.minus(bigStart).div(divisor).plus(bigStart);
}
