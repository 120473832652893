import { parseSemver } from 'js/utils/semver';
import Engine from './containers/SurveyAssessmentRunEngine';
import ListItem from './components/ListItem';
import Review from './containers/SurveyAssessmentReviewEngine';
import {
  create,
  createFromDocTemplate,
  load,
} from 'js/actions/assessment-actions';
import Configure from './components/ConfigureSurveyAssessment';
import ConfigureV1 from './components/ConfigureSurveyAssessment/v1/ConfigureSurveyAssessment';
import Launch from './containers/SurveyAssessmentLaunchContainer';
const BaseSurveyAssessment = {
  Run: Engine,
  Check: Engine,
  Review,
  Configure,
  Launch,
  ListItem,
  checks: {
    requireTeam: true,
  },
  strategies: {
    create,
    createFromDocTemplate,
    load: (assessmentId, participantToken) =>
      load(assessmentId, participantToken),
    buildPostCreateRedirectPath: ({ id, team_id, workspace_id }) =>
      `/w/${workspace_id}/teams/${team_id}/assessments/${id}/launch`,
  },
};

const SurveyAssessmentV0 = {
  ...BaseSurveyAssessment,
  strategies: {
    ...BaseSurveyAssessment.strategies,
  },
};

const SurveyAssessmentV1 = {
  ...BaseSurveyAssessment,
  Configure: ConfigureV1,
  strategies: {
    ...BaseSurveyAssessment.strategies,
  },
};

export function getSurveyEngineForVersion(version) {
  const { major } = parseSemver(version);

  if (major >= 1) {
    return SurveyAssessmentV1;
  }

  return SurveyAssessmentV0;
}

export default SurveyAssessmentV0;
