import React, { useMemo } from 'react';
import styles from '../../../Run/AssessmentReview/CapabilityResponses/PageReview/PageReview.module.scss';
// import { useAssessmentContext } from '../../../../../AssessmentContext';
// import { useResponseContext } from 'js/engines/CapabilitiesAssessment/ResponseContext';
// import {
//   getPageCapabilities,
//   getQuestionId,
// } from 'js/engines/CapabilitiesAssessment/utils';
// import CapabilityReview from './CapabilityReview';
import CapabilityReview from '../../../Run/AssessmentReview/CapabilityResponses/PageReview/CapabilityReview';
import { faSquare } from '@fortawesome/pro-solid-svg-icons';
import { Icon } from 'js/components';
import { useAssessmentReviewContext } from '../../AssessmentReviewContext';
import { useAssessmentContext } from 'js/engines/CapabilitiesAssessment/AssessmentContext';
import { getQuestionId } from 'js/engines/CapabilitiesAssessment/utils';

function CapabilitiesPageReview({ page, responses }) {
  const { pageData, questionMap } = useAssessmentReviewContext();
  const { capabilitySets, answerSets } = useAssessmentContext();
  const { pageCapabilities } = pageData[page.guid] ?? {};
  const capabilitySet = capabilitySets[page.capabilitySetId] ?? {};

  const capabilityResponses = useMemo(() => {
    return pageCapabilities.reduce((cAcc, capability) => {
      const capabilityResponses = page.capabilityElements.reduce(
        (elementAcc, element) => {
          const questionId = getQuestionId(element, capability, questionMap);
          const response = responses[questionId];
          const answerSet = answerSets[element.answerSetId];
          const score =
            response && !response.is_na
              ? answerSet.scale(+response.value)
              : undefined;
          elementAcc[element.guid] = {
            capability,
            answerSet: answerSets[element.answerSetId],
            response,
            score,
          };
          return elementAcc;
        },
        {}
      );
      cAcc[capability.id] = capabilityResponses;
      return cAcc;
    }, {});
  }, [
    pageCapabilities,
    answerSets,
    questionMap,
    responses,
    page.capabilityElements,
  ]);

  return (
    <div className={styles['page-review']}>
      <div className={styles['page-review-header']}>
        <h4 className={styles['page-review-title']}>{capabilitySet.name}</h4>
      </div>
      <div className={styles['legend']}>
        {page.capabilityElements.map((element, i) => (
          <div
            key={element.guid}
            className={styles['legend-item'] + ` palette-chart-${i + 2}`}
          >
            <Icon
              icon={faSquare}
              size="lg"
              className="palette-fill icon-left"
            />
            <span>{element.name}</span>
          </div>
        ))}
      </div>
      <div className={styles['page-review-body']}>
        {pageCapabilities.map((capability) => (
          <CapabilityReview
            key={capability.guid}
            pageId={page.guid}
            capability={capability}
            capabilityElements={page.capabilityElements}
            responses={capabilityResponses[capability.id]}
          />
        ))}
      </div>
    </div>
  );
}

export default CapabilitiesPageReview;
