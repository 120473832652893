import Big from 'big.js';
import { Map as YMap } from 'yjs';

import { randomBase32String } from 'js/utils/string';
import { calcPositionBetween } from 'js/ydoc/docs/common/utils';
import { createAnswerSet } from './answer-set-actions';
export function addCapabilityElement(yDoc, elementProps = {}, pageGuid) {
  console.log('🛎️ adding element', elementProps);
  const guid = randomBase32String(10);
  const q = new YMap();
  q.set('guid', guid);
  q.set('elementType', 'question');
  q.set('type', 'likert');
  q.set('text', elementProps.text);
  q.set('name', elementProps.name);
  q.set('key', `cq-${guid}`);

  yDoc.transact(() => {
    if (pageGuid != null) {
      const page = yDoc.getMap('pages').get(pageGuid);
      if (page && page.get('type') !== 'capability-questions') {
        const capabilityElementPositions = page.get(
          'capabilityElementPositions'
        );
        const maxPosition = Object.values(
          capabilityElementPositions.toJSON()
        ).reduce((acc, p) => (p > acc ? p : acc), null);

        const newPosition =
          maxPosition === null
            ? calcPositionBetween(Big(0), Big(1))
            : calcPositionBetween(Big(maxPosition), Big(1));

        capabilityElementPositions.set(guid, newPosition.toString());
      }
    }

    const answerSetGuid = createAnswerSet(yDoc, {});
    q.set('answerSetId', answerSetGuid);

    yDoc.getMap('capabilityElements').set(guid, q);
  });

  return guid;
}
