import HostEngine from './containers/LiveAssessmentHostEngine';
import RunEngine from './containers/LiveAssessmentRunEngine';
import ReviewEngine from './containers/LiveAssessmentReviewEngine';
import ListItem from './components/ListItem';
import { load } from 'js/actions/assessment-actions';
import { create } from './actions/live-assessment-engine-actions';

const LiveAssessment = {
  Host: HostEngine,
  Run: RunEngine,
  Review: ReviewEngine,
  Configure: () => null,
  ListItem,
  checks: {
    requireTeam: true,
  },
  strategies: {
    create,
    load: (assessmentId) => load(assessmentId),
    buildPostCreateRedirectPath: ({ id }) => `/assessments/${id}/host`,
  },
};

export default LiveAssessment;
