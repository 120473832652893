import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import CapabilityForm from './CapabilityForm';
import { Icon } from 'js/components';
import { faFloppyDisk } from '@fortawesome/pro-solid-svg-icons';

import styles from './CapabilitySet.module.scss';

function EditCapabilityModal({
  context,
  onSubmit = () => {},
  onCancel = () => {},
  isOpen = false,
}) {
  const { path = [], capabilitySet = null, capability = null } = context ?? {};

  const handleSubmit = (values) => onSubmit({ ...values, id: capability.id });

  return (
    <Modal isOpen={isOpen} className={styles.modal}>
      <ModalHeader>
        <div className={styles['modal-path-list']}>
          {path.reverse().map((p) => (
            <div className={styles['modal-path-list-item']} key={p.name}>
              <div className={styles['modal-path-list-item-name']}>
                {p.name}
              </div>
              <div className={styles['modal-path-list-item-chevron']}>&gt;</div>
            </div>
          ))}
          <div className={styles['modal-path-list-item']}>
            <div className={styles['modal-path-list-item-name']}>
              {capabilitySet?.name}
            </div>
            <div className={styles['modal-path-list-item-chevron']}>&gt;</div>
          </div>
        </div>
        <div className="primary">
          {(capability.name || '').trim() || '[Unnamed]'}
        </div>
      </ModalHeader>
      <ModalBody>
        <div className={styles['capability-form']}>
          <CapabilityForm
            onSubmit={handleSubmit}
            onCancel={onCancel}
            capability={capability}
            submitText={
              <>
                <Icon icon={faFloppyDisk} className="icon-left" />
                Save
              </>
            }
          />
        </div>
      </ModalBody>
    </Modal>
  );
}

export default EditCapabilityModal;
