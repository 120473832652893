import React, { useMemo } from 'react';
import { format } from 'date-fns';
// import copy from 'copy-to-clipboard';
// import { useNotify } from 'js/hooks/useNotify';
import { Link } from 'react-router-dom';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';
import { Icon } from 'js/components';
import ParticipationStats from 'js/engines/SurveyAssessment/components/Review/ParticipationStats';
import DropdownFilter from 'js/components/Filter/DropdownFilter';
import { useCallback } from 'react';
// import { isArchived } from 'js/utils/team-utils';
// import { faCopy, faPoll } from '@fortawesome/pro-regular-svg-icons';
import { faCircleSmall } from '@fortawesome/pro-solid-svg-icons';
import { getAllCapabilities } from '../../utils';
import { useAssessmentContext } from '../../AssessmentContext';

import styles from './Review.module.scss';

const ReviewHeader = ({
  name,
  date,
  userGroup,
  userGroupAssessment,
  assessmentKey,
  children,
  invites,
  assessment,
  cohorts = [],
  showParticipationStats = true,
  maxCapabilities = 8,
  onFilterChange = () => {},
}) => {
  // const userGroupName = (userGroup || {}).name;
  const handleFilterChange = useCallback(
    (cohortsFilter) =>
      onFilterChange({
        cohorts: cohortsFilter,
      }),
    [onFilterChange]
  );

  // const notify = useNotify();
  const { capabilitySets } = useAssessmentContext();
  // const handleCopyLink = () => {
  //   const url = `${window.location.origin}/assessments/${assessment.id}/run`;

  //   copy(url);

  //   notify({ type: 'success', message: 'Link copied to clipboard' });
  // };

  const capabilities = useMemo(
    () => getAllCapabilities(assessment.document, capabilitySets),
    [assessment?.document, capabilitySets]
  );

  const userGroupIsArchived = userGroup?.archived_status === 'archived';
  const dropdownProps = userGroupIsArchived
    ? { disabled: true, isOpen: false }
    : {};

  return (
    <div className="panel-content assessment-review-header">
      <section className="details flex">
        <div className="details-main">
          <label>UserGroup</label>
          <h1>{userGroup.name}</h1>
          <div className="timestamp">
            {userGroupAssessment.go_live_at
              ? format(userGroupAssessment.go_live_at, 'MMM d, yyyy')
              : null}{' '}
            -{' '}
            {userGroupAssessment.close_at
              ? format(userGroupAssessment.close_at, 'MMM d, yyyy')
              : null}
          </div>
          <div className={styles['capability-list']}>
            {capabilities.slice(0, maxCapabilities).map((c, i, arr) => (
              <React.Fragment key={c.id}>
                <span className="text-info">{c.name}</span>
                {i < capabilities.length - 1 && (
                  <Icon
                    icon={faCircleSmall}
                    className="icon-left icon-right text-muted"
                    transform="shrink-9"
                  />
                )}
              </React.Fragment>
            ))}
            {capabilities.length > maxCapabilities && (
              <span className="text-info font-italic">
                {' +'}
                {capabilities.length - maxCapabilities} more
              </span>
            )}
          </div>
        </div>
        <div className="details-sidebar">
          <div className="actions">
            {assessment ? (
              <UncontrolledDropdown
                {...dropdownProps}
                disabled={userGroupIsArchived}
              >
                <DropdownToggle
                  color="primary"
                  caret
                  disabled={userGroupIsArchived}
                >
                  Actions
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>Action One</DropdownItem>
                  <DropdownItem>Action Two</DropdownItem>
                  {/* <DropdownItem
                        onClick={handleCopyLink}
                        disabled={userGroupIsArchived}
                      >
                        <Icon
                          icon={faCopy}
                          size="lg"
                          className="btn-icon-left"
                          fixedWidth
                        />
                        Copy link for participants
                      </DropdownItem>
                      <DropdownItem
                        tag={Link}
                        to={`/w/${userGroup.workspace_id}/teams/${userGroup.id}/assessments/${assessmentKey}/new`}
                        disabled={userGroupIsArchived}
                      >
                        <IconStack
                          size="lg"
                          className="btn-icon-left"
                          fixedWidth
                        >
                          <Icon
                            icon={faCircle}
                            mask={faPoll}
                            transform="shrink-4 right-6 down-6"
                          />
                          <Icon
                            icon={faRedoAlt}
                            transform="shrink-7 right-6 down-6"
                          />
                        </IconStack>
                        Run this assessment again
                      </DropdownItem> */}
                </DropdownMenu>
              </UncontrolledDropdown>
            ) : (
              <Link
                className="btn btn-primary"
                to={`/w/${userGroup.workspace_id}/pm/user-groups/${userGroup.id}/assessments/${assessmentKey}/new`}
                disabled={userGroupIsArchived}
              >
                <Icon
                  icon={['far', 'poll']}
                  size="lg"
                  className="btn-icon-left"
                />
                Assess
              </Link>
            )}
          </div>
          {cohorts.length <= 1 ? null : (
            <div className="data-filter">
              <DropdownFilter
                name="Cohorts"
                filterOptions={cohorts}
                onChange={handleFilterChange}
              />
            </div>
          )}
          <div>
            <ParticipationStats assessment={assessment} invites={invites} />
          </div>
        </div>
      </section>
      <section className="panel-chart">{children}</section>
    </div>
  );
};

export default ReviewHeader;
