// import { Doc as YDoc } from 'yjs';
import { keyBy } from 'lodash';
import {
  getOrInitializeMap,
  updateMap,
  buildPositionMap,
} from '../common/utils';
import { randomBase32String } from 'js/utils/string';
import { deepFreeze } from 'js/utils/object';
import { positionify } from 'js/ydoc/docs/common/utils';

export const defaultInitialState = deepFreeze({
  meta: { version: '0.1.0' },
  pages: [
    {
      guid: 'capability-questions',
      type: 'capability-questions',
      title: 'Rate your business capabilities',
      capabilitySetId: null,
      selectedCapabilities: [],
      description: null,
      capabilityElementPositions: {
        satisfaction: '0.333',
        importance: '0.666',
      },
      // elements: [],
    },
  ],
  capabilityElements: keyBy(
    [
      {
        guid: 'satisfaction',
        type: 'likert',
        key: 'satisfaction',
        text: 'Rate your current level of satisfaction with the following capabilities. Select N/A for the capabilities for which you are unfamiliar.',
        name: 'Satisfaction',
        answerSetId: 'health',
      },
      {
        guid: 'importance',
        type: 'likert',
        key: 'importance',
        text: 'How important is it to invest additional effort in improving the following capabilities? Select N/A for the capabilities for which you are unfamiliar.',
        name: 'Importance',
        answerSetId: 'satisfaction',
      },
    ],
    'guid'
  ),
  answerSets: keyBy(
    positionify([
      {
        guid: 'health',
        type: 'scored',
        choices: keyBy(
          positionify([
            { guid: '0', value: '0', text: 'Extremely unhealthy' },
            { guid: '1', value: '1', text: 'Very unhealthy' },
            { guid: '2', value: '2', text: 'Somewhat unhealthy' },
            { guid: '3', value: '3', text: 'Neutral' },
            { guid: '4', value: '4', text: 'Somewhat healthy' },
            { guid: '5', value: '5', text: 'Very healthy' },
            { guid: '6', value: '6', text: 'Extremely healthy' },
          ]),
          'guid'
        ),
        includeNA: true,
        naText: 'N/A',
      },
      {
        guid: 'satisfaction',
        type: 'scored',
        choices: keyBy(
          positionify([
            { guid: '0', value: '0', text: 'Not at all important' },
            { guid: '1', value: '1', text: 'Low importance' },
            { guid: '2', value: '2', text: 'Slightly important' },
            { guid: '3', value: '3', text: 'Moderately important' },
            { guid: '4', value: '4', text: 'Considerably important' },
            { guid: '5', value: '5', text: 'Very important' },
            { guid: '6', value: '6', text: 'Extremely important' },
          ]),
          'guid'
        ),
        includeNA: true,
        naText: 'N/A',
      },
    ]),
    'guid'
  ),
  capabilitySetCache: {},
});

export function clear(doc) {
  const fields = Object.keys(doc.toJSON());
  console.log('🧼 fields', fields);

  doc.transact(() => {
    fields.forEach((field) => {
      doc.getMap(field).clear();
    });
  });
}

export function initializeFromScratch(doc, state, origin) {
  const initialState = state ?? defaultInitialState;

  console.log('⚰️ initialState', initialState);

  const meta = doc.getMap('meta');
  const pages = doc.getMap('pages');
  // const elements = doc.getMap('elements');
  doc.getMap('elements');
  const capabilityElements = doc.getMap('capabilityElements');
  const answerSets = doc.getMap('answerSets');
  const capabilitySetCache = doc.getMap('capabilitySetCache');

  doc.transact(() => {
    Object.entries(initialState).forEach(([key, value]) => {
      switch (key) {
        case 'pages':
          const pageSpacer = 1 / (value.length + 1);
          value.forEach((p, pIndex) => {
            const guid = p.guid ?? randomBase32String(10);
            const pMap = getOrInitializeMap(pages, guid);
            const position =
              '' + Math.round(pageSpacer * (pIndex + 1) * 1000) / 1000;

            pMap.set(
              'position',
              buildPositionMap(
                pMap,
                'assessmentPages',
                'assessmentDocument',
                position
              )
            );
            updateMap(pMap, p, {});
          });
          break;

        case 'capabilityElements':
          updateMap(capabilityElements, value);
          break;

        case 'answerSets':
          updateMap(answerSets, value);
          break;

        case 'meta':
          updateMap(meta, value);
          break;

        case 'capabilitySetCache':
          updateMap(capabilitySetCache, value);
          break;

        default:
          meta.set(key, value);
          break;
      }
    });
  }, origin);
}

// export function initializeYDoc(doc, initialState, origin) {
//   const meta = doc.getMap('meta');
//   const config = doc.getMap('config');
//   const assessment = doc.getMap('assessment');
//   const pages = doc.getMap('pages');
//   const elements = doc.getMap('elements');
//   const answerSets = doc.getMap('answerSets');
//   const answerChoices = doc.getMap('answerChoices');
//   const views = doc.getMap('views');

//   doc.transact(() => {
//     Object.entries(initialState).forEach(([key, value]) => {
//       switch (key) {
//         case 'pages':
//           throw new Error('Not implemented');
//         // break;

//         case 'answerSets':
//           throw new Error('Not implemented');
//         // updateMap(answerSets, value);
//         // break;

//         case 'answerChoices':
//           throw new Error('Not implemented');
//         // updateMap(answerChoices, value);
//         // break;

//         case 'assessment':
//           updateMap(assessment, value);
//           break;

//         case 'meta':
//           updateMap(meta, value);
//           break;

//         case 'config':
//           updateMap(config, value, {});
//           break;

//         case 'views':
//           updateMap(views, value);
//           break;

//         default:
//           meta.set(key, value);
//           break;
//       }
//     });
//   }, origin);
// }
