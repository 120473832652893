import React from 'react';
import { Card, CardBody } from 'reactstrap';
import CapabilitySetForm from '../CapabilitySetForm';

function CapabilitySetSettings({ capabilitySet, onUpdate }) {
  return (
    <Card>
      <CardBody>
        <CapabilitySetForm
          capabilitySet={capabilitySet}
          onSubmit={onUpdate}
          submitLabel="Update"
          showCancel={false}
        />
      </CardBody>
    </Card>
  );
}

export default CapabilitySetSettings;
