import React from 'react';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Icon } from 'js/components';

export function Checkbox({
  labelClass,
  checked,
  children,
  onChange = () => {},
  ...props
}) {
  return (
    <div className="custom-checkbox" role="presentation">
      <label className={labelClass}>
        <input
          type="checkbox"
          checked={checked ?? false}
          onChange={onChange}
          {...props}
        />
        <span className="decorator">
          <Icon icon={faCheck} className="icon" />
        </span>
        {children}
      </label>
    </div>
  );
}

export default Checkbox;
