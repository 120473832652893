import { useMemo, useRef, useState, useEffect } from 'react';
import { useProviders } from './useProviders';
import { useSelector } from 'react-redux';
import { nextColorIndex } from 'js/utils/color';

export function useCollaborators() {
  const providers = useProviders();
  const [collaborators, setCollaborators] = useState([]);
  const collaboratorColorMap = useRef({});
  const me = useSelector((state) => state.identity.me);

  const awareness = useMemo(() => {
    return providers.channelProvider?.awareness;
  }, [providers]);

  useEffect(() => {
    if (awareness) {
      const updateCollaborators = () => {
        setCollaborators(
          [...awareness.getStates().entries()].map(([key, val]) => {
            let colorIndex = collaboratorColorMap.current[val.username];
            if (colorIndex === undefined) {
              colorIndex = nextColorIndex(
                Object.values(collaboratorColorMap.current)
              );
              collaboratorColorMap.current[val.username] = colorIndex;
            }
            return {
              key,
              colorIndex,
              me: val.username === me.username,
              ...val,
            };
          })
        );
      };
      updateCollaborators();
      awareness.on('change', updateCollaborators);
      return () => {
        awareness.off('change', updateCollaborators);
      };
    }
  }, [awareness, me.username]);

  return collaborators;
}
