import React, { useCallback, useEffect, useState } from 'react';
import { default as UserGroupComponent } from '../components/UserGroup';
import { connect, useSelector, useStore } from 'react-redux';
import { useParams } from 'react-router-dom';
import { denormalize } from 'normalizr';
import {
  list as listUserGroupAssessments,
  update as updateUserGroupAssessment,
} from 'js/actions/user-group-assessment-actions';
import { loadUserGroup, updateUserGroup } from 'js/actions/user-group-actions';
import { useNotify } from 'js/hooks/useNotify';
import { SCHEMAS } from 'js/api/schemas';

function userGroupAssessmentsQueryId(userGroupId) {
  return `userGroupAssessments-${userGroupId}`;
}

function getRelevantAssessmentDate(assessment) {
  return (
    assessment.went_live_at ?? assessment.published_at ?? assessment.inserted_at
  );
}

function UserGroup({
  userGroup,
  loadUserGroup,
  updateUserGroup,
  listUserGroupAssessments,
  updateUserGroupAssessment,
}) {
  const { userGroupId } = useParams();
  const notify = useNotify();
  const store = useStore();
  const refreshUserGroup = useCallback(
    () => loadUserGroup(userGroupId),
    [loadUserGroup, userGroupId]
  );

  useEffect(() => {
    refreshUserGroup();
  }, [refreshUserGroup]);

  useEffect(() => {
    listUserGroupAssessments(userGroupId);
  }, [listUserGroupAssessments, userGroupId]);

  const assessmentsQueryId = userGroupAssessmentsQueryId(userGroupId);
  const assessmentsQuery = useSelector(
    (state) => state.queries[assessmentsQueryId]
  );

  const refreshAssessments = useCallback(
    () => listUserGroupAssessments(userGroupId, assessmentsQueryId),
    [listUserGroupAssessments, userGroupId, assessmentsQueryId]
  );

  const [userGroupAssessments, setUserGroupAssessments] = useState(null);
  const state = store.getState();

  useEffect(() => {
    if (assessmentsQuery?.isLoading === false) {
      setUserGroupAssessments(
        denormalize(
          assessmentsQuery?.result || [],
          SCHEMAS.USER_GROUP_ASSESSMENT_ARRAY,
          state.entities
        )
          .sort(
            (a, b) =>
              getRelevantAssessmentDate(b) - getRelevantAssessmentDate(a)
          )
          .map((a) => ({
            ...a,
            user_group: userGroup,
          }))
      );
    }
  }, [
    state,
    assessmentsQuery,
    assessmentsQuery?.isLoading,
    assessmentsQuery?.result,
    userGroup,
  ]);

  useEffect(() => {
    refreshAssessments();
  }, [refreshAssessments]);

  const handleUpdate = useCallback(
    async (values) => {
      return updateUserGroup(userGroupId, values)
        .then(() => {
          notify({
            type: 'success',
            message: 'User group updated successfully',
          });
          return refreshUserGroup();
        })
        .catch((error) => {
          notify({
            type: 'error',
            message: 'Failed to update user group',
          });
          throw error;
        });
    },
    [updateUserGroup, userGroupId, notify, refreshUserGroup]
  );

  const handleUpdateAssessment = useCallback(
    async (assessmentId, update) => {
      await updateUserGroupAssessment(userGroupId, assessmentId, update);
      return refreshAssessments();
    },
    [updateUserGroupAssessment, userGroupId, refreshAssessments]
  );

  return (
    <UserGroupComponent
      userGroup={userGroup}
      userGroupAssessments={userGroupAssessments}
      userGroupAssessmentsQuery={assessmentsQuery}
      onUpdate={handleUpdate}
      onUpdateAssessment={handleUpdateAssessment}
    />
  );
}

export default connect(
  (state, { match }) => ({
    userGroup: denormalize(
      state.entities.userGroups[match.params.userGroupId],
      SCHEMAS.USER_GROUP,
      state.entities
    ),
  }),
  {
    loadUserGroup,
    updateUserGroup,
    listUserGroupAssessments,
    updateUserGroupAssessment,
  }
)(UserGroup);
