import Big from 'big.js';
import { nextColorIndex } from 'js/utils/color';
import { calcPositionBetween } from 'js/utils/big';
export { nextColorIndex } from 'js/utils/color';
export { calcPositionBetween } from 'js/utils/big';

export function nextCategoryOrdinal(catMap) {
  const max = Object.values(catMap ?? {}).reduce((max, c) => {
    if (c.ordinal > max) {
      return c.ordinal;
    } else {
      return max;
    }
  }, 0);

  return calcPositionBetween(Big(max), Big(1)).toString();
}

export function sortedCategories(catMap) {
  return Object.entries(catMap || {})
    .map(([guid, category]) => {
      return { guid, ...category };
    })
    .sort((a, b) => a.ordinal - b.ordinal);
}

export function generateCategoryOrdinalAfter(catMap, guid) {
  const sortedCats = sortedCategories(catMap);
  const destIndex = sortedCats.findIndex((c) => c.guid === guid);
  const ordinalAfterDest =
    destIndex >= sortedCats.length - 1
      ? '1'
      : sortedCats[destIndex + 1].ordinal;
  return calcPositionBetween(
    Big(sortedCats[destIndex].ordinal),
    Big(ordinalAfterDest)
  ).toString();
}

export function generateCategoryOrdinalBefore(catMap, guid) {
  const sortedCats = sortedCategories(catMap);
  const destIndex = sortedCats.findIndex((c) => c.guid === guid);
  const ordinalBeforeDest =
    destIndex === 0 ? '0' : sortedCats[destIndex - 1].ordinal;
  return calcPositionBetween(
    Big(ordinalBeforeDest),
    Big(sortedCats[destIndex].ordinal)
  ).toString();
}

export function nextCategoryColorIndex(catMap) {
  const used = Object.values(catMap ?? {}).map((cat) => cat.colorIndex);
  return nextColorIndex(used);
}
