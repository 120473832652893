import React, { useMemo } from 'react';
import { useAssessmentReviewContext } from '../AssessmentReviewContext';
import LikertResults from 'js/engines/SurveyAssessment/components/Review/QuestionResults/LikertResults';
import { constructQuestionKey } from '../../../utils';

function CapabilityQuestionResults({ capability, element }) {
  const { questionMap } = useAssessmentReviewContext();

  const question = useMemo(() => {
    return {
      ...questionMap[constructQuestionKey(capability, element)],
      text: element.text,
    };
  }, [questionMap, element, capability]);

  return (
    <div>
      <div>
        <label>{element.name}</label>
      </div>
      <LikertResults question={question} />
    </div>
  );
}

export default CapabilityQuestionResults;
