import { createContext, useContext, useMemo } from 'react';
import { useDoc } from 'js/ydoc/doc/hooks';
import { useAssessment } from '../common/useAssesment';
import { createBinding } from './binding';
import { AssessmentValidator } from './validation/validators';
import { buildCapabilitySetsFromCache } from './capability-utils';
import {
  updatePageFields,
  deleteCapabilityElement,
  moveCapabilityElementAfter,
  moveCapabilityElementBefore,
  addCapabilityElementToPage,
  togglePageCapabilitySelection,
} from './actions/page-actions';

import {
  addAnswerSetChoice,
  deleteAnswerSetChoice,
  updateAnswerSetChoice,
  moveAnswerSetChoiceBefore,
  moveAnswerSetChoiceAfter,
} from './actions/answer-set-actions';
import { addCapabilityElement } from './actions/element-actions';

import { updateCacheForCapabilitySet } from './actions/capability-set-actions';

import { updateCapabilityElement } from './actions/capability-actions';

export const EditorContext = createContext({
  doc: null,
  assessment: null,
  userGroupAssessment: null,
  binding: null,
  capabilitySets: null,
  cachedCapabilitySets: null,
  pageBadgeIndices: null,
  errors: null,
  updatePageFields: () => {},
  togglePageCapabilitySelection: () => {},
  deleteCapabilityElement: () => {},
  moveCapabilityElementAfter: () => {},
  moveCapabilityElementBefore: () => {},
  updateCapabilityElement: () => {},
  addAnswerSetChoice: () => {},
  deleteAnswerSetChoice: () => {},
  updateAnswerSetChoice: () => {},
  moveAnswerSetChoiceBefore: () => {},
  moveAnswerSetChoiceAfter: () => {},
  updateCacheForCapabilitySet: () => {},
  addCapabilityElement: () => {},
  addCapabilityElementToPage: () => {},
});

export function EditorProvider({
  children,
  capabilitySets,
  userGroupAssessment,
}) {
  const doc = useDoc();
  if (doc.getMap('capabilityElements').has('XXNAVE7SVE')) {
    doc.getMap('capabilityElements').delete('XXNAVE7SVE');
  }
  console.log('🏴‍☠️ doc', doc.toJSON());
  const { assessment, binding } = useAssessment(createBinding);
  const validator = useMemo(() => new AssessmentValidator(), []);

  const cachedCapabilitySets = useMemo(() => {
    if (assessment?.capabilitySetCache) {
      return buildCapabilitySetsFromCache(
        assessment.capabilitySetCache,
        assessment.capabilityCache
      );
    }
    return {};
  }, [assessment?.capabilitySetCache, assessment?.capabilityCache]);

  const pageBadgeIndices = useMemo(() => {
    const { lookups = {} } =
      assessment?.pages?.reduce(
        ({ lookups, current }, page) => {
          if (page.capabilitySetId && !lookups[page.capabilitySetId]) {
            lookups[page.capabilitySetId] = ++current;
          }
          return { lookups, current };
        },
        { lookups: {}, current: 0 }
      ) ?? {};
    return lookups;
  }, [assessment?.pages]);

  const actionsContext = useMemo(() => {
    return wrapActions(doc, {
      updatePageFields,
      togglePageCapabilitySelection,
      deleteCapabilityElement,
      moveCapabilityElementAfter,
      moveCapabilityElementBefore,
      updateCapabilityElement,
      addAnswerSetChoice,
      deleteAnswerSetChoice,
      updateAnswerSetChoice,
      moveAnswerSetChoiceBefore,
      moveAnswerSetChoiceAfter,
      updateCacheForCapabilitySet,
      addCapabilityElement,
      addCapabilityElementToPage,
    });
  }, [doc]);

  const errors = useMemo(() => {
    return validator.validateAssessment(assessment);
  }, [assessment, validator]);

  const context = useMemo(() => {
    return {
      doc,
      assessment,
      userGroupAssessment,
      binding,
      capabilitySets,
      cachedCapabilitySets,
      pageBadgeIndices,
      errors,
      ...actionsContext,
    };
  }, [
    doc,
    assessment,
    userGroupAssessment,
    binding,
    capabilitySets,
    cachedCapabilitySets,
    actionsContext,
    pageBadgeIndices,
    errors,
  ]);

  return (
    <EditorContext.Provider value={context}>{children}</EditorContext.Provider>
  );
}

export function useEditorContext() {
  return useContext(EditorContext);
}

function wrapActions(doc, handlers) {
  return Object.fromEntries(
    Object.entries(handlers).map(([key, action]) => [
      key,
      (...args) => action(doc, ...args),
    ])
  );
}
