import React from 'react';
import { useEditorContext } from 'js/ydoc/docs/capabilities-assessment/EditorContext';
import { useStandardCollator } from 'js/utils/string';
import { scaleColorTraffic } from 'js/utils/color';
import { getAnswerDomain } from 'js/utils/scoring';
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { Icon } from 'js/components';
import useNthChildOffsetStyle from 'js/components/Assessment/Editors/common/hooks/useNthChildOffsetStyle';

import ScoredChoice from 'js/components/Assessment/Editors/common/components/AnswerSets/Choices/ScoredChoice';

import styles from './AnswerSets.module.scss';
import standardStyles from 'js/components/Assessment/Editors/common/components/AnswerSets/AnswerSet.module.scss';

function CapabilityAnswerSet({ answerSetId }) {
  const {
    assessment,
    addAnswerSetChoice,
    deleteAnswerSetChoice,
    updateAnswerSetChoice,
  } = useEditorContext();
  const collator = useStandardCollator();

  const answerSet = assessment?.answerSets[answerSetId];

  const { choices } = answerSet ?? {};

  const choiceElements = Object.values(choices ?? {}).sort((a, b) =>
    collator.compare(a.p, b.p)
  );

  const { id, style, containerRef } = useNthChildOffsetStyle(choiceElements);

  const handleAddChoice = () => {
    const nextNumber = choiceElements.length + 1;

    const newChoice = {
      value: '' + nextNumber,
      text: `Choice ${nextNumber}`,
    };
    addAnswerSetChoice(answerSetId, newChoice);
  };

  const handleDeleteChoice = (choiceId) => {
    console.log('👾 deleting choice', choiceId);
    deleteAnswerSetChoice(answerSetId, choiceId);
  };

  const handleUpdateChoice = (choiceId, updates) => {
    updateAnswerSetChoice(answerSetId, choiceId, updates);
  };

  // const handleAddChoice = () => {
  //   choiceElements.length + console.log('add choice');
  // };

  if (!answerSet) {
    return null;
  }

  const color = scaleColorTraffic
    .copy()
    .domain(getAnswerDomain(choiceElements, 'value', 3));

  return (
    <div className={styles['answer-set']} id={id}>
      <style>{style}</style>
      <div className={standardStyles['choice-list']} ref={containerRef}>
        {choiceElements.map((choice) => (
          <ScoredChoice
            key={choice.guid}
            choice={choice}
            answerSetId={answerSetId}
            onDelete={handleDeleteChoice}
            onUpdate={handleUpdateChoice}
            color={color}
          />
        ))}
        <div className={standardStyles['add-choice']}>
          <button className="btn btn-light" onClick={handleAddChoice}>
            <Icon icon={faCirclePlus} className="icon" size="lg" />
            <span className={standardStyles['add-choice-text']}>
              Add choice
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default CapabilityAnswerSet;
