import React from 'react';
import { Button } from 'reactstrap';
import { Icon } from 'js/components';
import { faSliders, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';

export function CapabilityQuestionActions({
  onDelete,
  onSettings,
  settingsActive = false,
}) {
  return (
    <div>
      <Button
        className="btn-action-alt"
        color="primary"
        onClick={onSettings}
        active={settingsActive}
      >
        <Icon icon={faSliders} className="icon btn-icon-left" /> Settings
      </Button>
      <Button className="btn-action-alt" color="primary" onClick={onDelete}>
        <Icon icon={faTrashAlt} className="icon btn-icon-left" /> Delete
      </Button>
    </div>
  );
}

export default CapabilityQuestionActions;
