import React from 'react';
import { connect } from 'react-redux';
import { createCapabilitySet } from 'js/actions/capability-actions';
import { useNotify } from 'js/hooks/useNotify';
import { useHistory, useParams } from 'react-router-dom';
import CreateCapabilitySetComponent from '../components/CreateCapabilitySet';

function CreateCapabilitySet({ createCapabilitySet }) {
  const notify = useNotify();
  const history = useHistory();
  const { workspaceId } = useParams();

  const handleSubmit = async (values) => {
    try {
      console.log('formData', values);
      const result = await createCapabilitySet(values);
      notify({
        type: 'success',
        message: 'User group created successfully',
      });
      history.push(`/w/${workspaceId}/pm/capability-sets/${result.id}`);
      return result;
    } catch (error) {
      notify({
        type: 'danger',
        message: 'Failed to create user group',
      });
    }
  };

  return <CreateCapabilitySetComponent onSubmit={handleSubmit} />;
}

export default connect(null, { createCapabilitySet })(CreateCapabilitySet);
