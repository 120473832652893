import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useEditorContext } from 'js/ydoc/docs/capabilities-assessment/EditorContext';

import styles from '../CapabilitiesAssessmentEditor.module.scss';

function PageBadge({ lookupValue, children, ...props }) {
  const { pageBadgeIndices } = useEditorContext();

  const hasValidIndex = pageBadgeIndices[lookupValue] !== undefined;

  const className = useMemo(
    () =>
      classNames(styles['page-badge'], {
        [styles['invalid-badge']]: !hasValidIndex,
        [`palette-cat-${pageBadgeIndices[lookupValue]}`]: hasValidIndex,
      }),
    [pageBadgeIndices, lookupValue, hasValidIndex]
  );

  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
}

export default PageBadge;
