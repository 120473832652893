import { createContext, useContext } from 'react';

export const TreeContext = createContext({
  dispatch: () => {},
  uniqueContextId: Symbol('capability-set'),
  getPathToItem: () => [],
  getChildrenOfItem: () => [],
  registerTreeItem: () => {},
});

export function useTreeContext() {
  return useContext(TreeContext);
}
